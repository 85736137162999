const routeTitles = {
    "/": "Inside Chassidus | Audio Classes | Chassidus for Everyone",
    "/sign-in": "Sign In | Inside Chassidus",
    "/sign-up": "Sign Up | Inside Chassidus",
    "/forget-password": "Forget Password | Inside Chassidus",
    "/contact-us": "Contact Us | Inside Chassidus",
    "/ask-question": "Ask a Question | Inside Chassidus",
    "/about-us": "About Us | Inside Chassidus",
    "/live": "Live Page | Inside Chassidus",
    "/donations": "Donations | Inside Chassidus",
    "/chai-club": "Chai Club | Inside Chassidus",
    "/donations-mbl": "Donations Mobile | Inside Chassidus",
    "/privacy-policy": "Privacy Policy | Inside Chassidus",
    "/daily-tanya": "Daily Tanya | Inside Chassidus",
    "/daily-hayom-yom": "Daily Hayom Yom | Inside Chassidus",
    "/weekly-parsha-classes": "Weekly Parsha Classes | Inside Chassidus",
    "/monthly-classes": "Monthly Classes | Inside Chassidus",
    "/latest-classes": "Latest Classes | Inside Chassidus",
    "/user-bookmarks": "User Bookmarks | Inside Chassidus",
    "/profile": "Profile | Inside Chassidus",
    "/search": "Search for classes, series or categories | Inside Chassidus",
  };
  

  const routeDescription = {
    "/": "Welcome to Inside Chassidus, where you'll discover a treasury of insightful teachings and audio classes by Rabbi Yossi Paltiel, guiding you on a journey through the depths of Chassidic wisdom.",
    "/about-us": "Explore the world of Rabbi Yossi Paltiel and Inside Chassidus. Engage with profound audio classes, delve into Chassidic thought, and stay updated with the latest enriching content.",
    "/contact-us": "Connect directly with Rabbi Paltiel or the dedicated team at Inside Chassidus. Reach out through our contact form to share your thoughts, queries, or feedback.",
    "/donations": "Support the growth of Inside Chassidus! Your contributions enable us to continue offering enriching content to our cherished community of listeners and visitors.",
    "/search": "Easily find classes, series, or categories on Inside Chassidus, empowering you to delve into specific topics and teachings that resonate with you.",
    "/profile": "Access your personalized profile on Inside Chassidus, enabling you to track your learning journey and saved content.",
    "/sign-in": "Sign in to access exclusive features and personalized content on Inside Chassidus.",
    "/sign-up": "Join the Inside Chassidus community! Sign up to unlock a world of transformative teachings and personalized learning experiences.",
    "/forget-password": "Retrieve your password for your Inside Chassidus account to continue your uninterrupted learning experience.",
    "/ask-question": "Engage directly with Rabbi Paltiel and the Inside Chassidus community by asking your pressing questions and receiving insightful answers.",
    "/live": "Experience live sessions and real-time teachings on Inside Chassidus, offering interactive learning opportunities with Rabbi Yossi Paltiel.",
    "/chai-club": "Become a part of the Chai Club at Inside Chassidus, contributing to sustaining and expanding this platform of spiritual growth.",
    "/donations-mbl": "Support Inside Chassidus through mobile donations, ensuring accessibility and ease in contributing to our shared mission.",
    "/privacy-policy": "Learn about Inside Chassidus' commitment to your privacy and data protection through our detailed privacy policy.",
    "/daily-tanya": "Engage with the Daily Tanya teachings, offering daily insights and understandings from the foundational Chassidic text.",
    "/daily-hayom-yom": "Explore the Daily Hayom Yom, providing daily inspirations and lessons for a meaningful and enriching life.",
    "/weekly-parsha-classes": "Delve into weekly Parsha classes, exploring the wisdom and teachings embedded within the weekly Torah portions.",
    "/monthly-classes": "Experience exclusive monthly classes on Inside Chassidus, offering in-depth studies and thought-provoking discussions.",
    "/latest-classes": "Discover the latest classes and teachings by Rabbi Yossi Paltiel, providing a continuous flow of transformative insights.",
    "/user-bookmarks": "Access and manage your bookmarked content on Inside Chassidus, curating your personalized collection of teachings and classes.",
  };
  
  
  export { routeTitles, routeDescription };
  