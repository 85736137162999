import { useEffect, useRef, useState } from "react";
import FloatingControls from './FloatingControls';
// import MediaGalleryModal from "../MediaGallary/MediaGalleryModal";

export default function BlockTextField({
  block,
  index,
  handleBlockChange,
  data,
  removeBlock,
  blocks,
  handleReorderBlocks
}) {
  const textareaRef = useRef(null);
  const [value, setValue] = useState("");
  const hiddenSpanRef = useRef(null); // Ref for the hidden span to measure the text height

  useEffect(() => {
    setValue(data ?? "");
  }, []);

  useEffect(() => {
    textAreaAutoResize()
  }, [value])

  const handleChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
    handleBlockChange(e, index);
  };

  const textAreaAutoResize = (originalHeight) => {
    const spanProps = hiddenSpanRef.current.getBoundingClientRect();
    const textHeight = spanProps.height
    const textarea = textareaRef.current;
    const scrollHeight = textarea.scrollHeight;
    if (originalHeight === '78px') {
      // Mouse leaving the textarea
      textarea.style.height = originalHeight;
    } else if (textHeight + 24 === scrollHeight && value.charAt(value.length - 1) !== '\n') {
      // Resize when deleting moves cursor from empty line to end of line
      textarea.style.height = textHeight > 78 ? `${textHeight}px` : "78px";
    } else if (textHeight + 24 < scrollHeight) {
      // Adding text before default textarea height is reached and deleting text when cursor is at the beginning of a line
      textarea.style.height = textHeight > 78 ? `${textHeight}px` : "78px";
    } else {
      // Resize when adding entering textarea or adding text that completes or expands textarea height 
      textarea.style.height = `${scrollHeight}px`;
    }
  };

  const replaceBlock = (event) => {
    event.stopPropagation();
    setValue("")
  }

  return (
    <>
      <div className="relative w-full h-5 mb-4 justify-between">
        <FloatingControls
          type={block} 
          blockType="text field" 
          blocks={blocks} 
          handleReorderBlocks={handleReorderBlocks} 
          removeBlock={removeBlock} 
          replaceBlock={replaceBlock} 
          index={index}
          value={value}
        />
        <div className="block text-xl font-extrabold leading-5 text-primaryDark">
          {block === "Paragraph" ? "Paragraph" : "Custom HTML"}
        </div>
      </div>
      <div className="relative">
        <textarea
          id={block === "Paragraph" ? "paragraphBlock" : "customCode"}
          name={block === "Paragraph" ? "paragraphBlock" : "customCode"}
          placeholder={block === "Paragraph" ? "write here..." : ""}
          type="text"
          autoComplete="off"
          // required
          value={value}
          ref={textareaRef}
          onMouseLeave={() => textAreaAutoResize('78px')}
          onMouseEnter={textAreaAutoResize}
          onChange={handleChange}
          className={`block h-[78px] whitespace-pre-wrap overflow-hidden resize-none w-full bg-transparent outline-none border-0 mb-1.5 text-primaryDark focus:border-transparent sm:text-base sm:leading-normal`}
        />
          {/* Span is an invisible copy of the text input to the textarea used to retrieve the text height needed for resizing the textarea. */}
          <span
            ref={hiddenSpanRef}
            className={`absolute invisible whitespace-pre-wrap overflow-hidden resize-none w-full my-1.5 sm:text-base sm:leading-normal`}
          >
          {value}
        </span>
      </div> 
    </>
  );
}

// export const CopyMessage = (textToCopy) => {
//   navigator.clipboard.writeText(textToCopy);

// const successMessage = document.createElement('div');
// successMessage.className = 'success-message';
// successMessage.textContent = 'Copied to clipboard!';

// // Append the element to the body or a specific container
// document.body.appendChild(successMessage);

// // Remove the element after 100 ms
// setTimeout(() => {
//     successMessage.remove();
// }, 100);
// };
