import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DragIcon } from "../../assets/icon-file";

export const DraggableMenu = ({ id, index, ...props }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <div className="drag-handle" {...provided.dragHandleProps}>
              <DragIcon className="h-[20px]" />
            </div>
            {props.children}
          </div>
        );
      }}
    </Draggable>
  );
};

export const DraggableFileBlock = ({ id, index, isFileUploaded, ...props }) => {
  return (
    <Draggable
      disableInteractiveElementBlocking={true}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...props}
            className={`w-full mb-6 bg-whiteGrey rounded-xl ${
              isFileUploaded ? "" : "p-4"
            }`}
          >
            <div className="flex" {...provided.dragHandleProps}>
              {!isFileUploaded && <DragIcon className="h-[20px] w-8 pr-4" />}
              {props.children}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export const DraggableTextBlock = ({ id, index, ...props }) => {
  const [disableIEB, setDisableIEB] = useState(false);

  return (
    <Draggable
      disableInteractiveElementBlocking={disableIEB ? true : false}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...props}
            className="w-full mb-6 bg-whiteGrey rounded-xl p-4"
          >
            <div
              className="flex"
              {...provided.dragHandleProps}
              onDoubleClick={() => {
                setDisableIEB(true);
              }}
              onClick={() => {
                setDisableIEB(false);
              }}
            >
              <DragIcon className="h-[20px] w-8 pr-4" />
              {props.children}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
