import React, { Fragment, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { useEffect } from 'react'
import { AdminListMedia } from '../../service/service'
// import { AddAdminMedia, DeleteAdminMedia, UpdateAdminMedia } from '../../service/service';
// import Swal from 'sweetalert2';
function MediaGalleryModal({ isOpenMedia, setIsOpenMedia, selectedMedia, mediatype }) {
    const [DataArray, setDataArray] = useState([]);
    const [videoKey, setVideoKey] = useState(0);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        name: '',
        type: '',
        description: '',
        url: ''
    })

    function closeModal() {
        setIsOpenMedia(false)
    }

    useEffect(() => {
        if (isOpenMedia) {

            AdminListMedia().then((result) => {
                // setIsLoading(false)
                if (result.status) {
                    // console.log(result.data.data)
                    if(mediatype ==='Images'){
                      const data=  result.data.data?.filter((item)=>item.type==='image');
                      setDataArray(data)
                      setSelectedItemId(data[0].id)
                      SelectItem(data[0])
                    }
                    else if(mediatype ==='Video'){
                      const data=  result.data.data?.filter((item)=>item.type==='video');
                      setDataArray(data)
                      setSelectedItemId(data[0].id)
                      SelectItem(data[0])
                    }
                    else if(mediatype ==='Audio'){
                      const data=  result.data.data?.filter((item)=>item.type==='audio');
                      setDataArray(data)
                      setSelectedItemId(data[0].id)
                      SelectItem(data[0])
                    }
                    else if(mediatype ==='Documents'){
                      const data=  result.data.data?.filter((item)=>item.type!=='image' && item.type!=='video' && item.type!=='audio');
                      setDataArray(data)
                      setSelectedItemId(data[0].id)
                      SelectItem(data[0])
                    }
                    else{
                        const data=result.data.data;
                        setDataArray(data)
                        setSelectedItemId(data[0].id)
                        SelectItem(data[0])
                    }


                }
            })
        }
    }, [isOpenMedia])
    const handleCheckboxChange = (item) => {
        setSelectedItemId(item.id);
        // console.log(item)
    };

    const SelectItem=(item)=>{
        // console.log(item)
        setVideoKey((prevKey) => prevKey + 1);
        setFormData({
            title: item.title ?? item.name,
            name: item.name,
            type: item.type,
            description: item.description,
            url: item.url
        })
    }
    return (
        <>
            <Transition appear show={isOpenMedia} as={Fragment}>
                <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[90vw] transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg flex justify-between items-center p-6 font-medium leading-6 text-gray-900"
                                        style={{ boxShadow: ' 0px 0px 16px 0px rgba(0, 0, 0, 0.20)' }}
                                    >
                                        <div className='flex w-[90%] items-center justify-between'>
                                            <div className='text-xl font-bold text-left'>
                                                Media Detail
                                            </div>
                                        </div>
                                        <span
                                            onClick={closeModal}
                                            className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </Dialog.Title>
                                    <div className='flex'>
                                        <div className='w-1/3 bg-[#D2D6DC] px-4 pb-6'>
                                            <div className='pt-6 space-y-4'>
                                                <>
                                                    <div className='h-[250px] bg-[#E1E9F2] rounded-tr-lg rounded-tl-lg flex justify-center items-center'>
                                                        {
                                                            formData.type === 'video' ?
                                                                <video controls key={videoKey} className='h-full w-full object-cover rounded-tr-lg rounded-tl-lg'>
                                                                    <source src={formData?.url} type="video/mp4" />
                                                                </video>
                                                                :
                                                                formData.type === 'image' ?
                                                                    <img src={formData?.url} alt='Media' className='object-cover h-full w-full rounded-tr-lg rounded-tl-lg' />
                                                                    :
                                                                    formData.type === 'audio' ?
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                                <path d="M25.496 20.1627V5.4959C25.495 5.29562 25.449 5.09813 25.3613 4.91808C25.2736 4.73803 25.1464 4.58006 24.9893 4.45589C24.8344 4.33058 24.6534 4.24159 24.4596 4.19544C24.2657 4.14929 24.064 4.14717 23.8693 4.18922L11.8693 6.85591C11.573 6.92272 11.3085 7.08865 11.1194 7.32626C10.9303 7.56387 10.8279 7.85891 10.8293 8.16259V19.2827C9.91906 18.853 8.89404 18.73 7.908 18.9322C6.92196 19.1344 6.02806 19.6508 5.3604 20.4041C4.69275 21.1574 4.28734 22.1068 4.20499 23.11C4.12265 24.1132 4.3678 25.1161 4.90369 25.9681C5.43957 26.8202 6.23728 27.4755 7.17715 27.8358C8.11702 28.1961 9.14838 28.2419 10.1165 27.9664C11.0846 27.6908 11.9373 27.1088 12.5466 26.3076C13.1559 25.5064 13.489 24.5293 13.496 23.5227C13.5097 23.2918 13.5097 23.0603 13.496 22.8294V9.22927L22.8293 7.16258V16.616C21.9185 16.186 20.8929 16.0631 19.9064 16.2658C18.9198 16.4684 18.0257 16.9857 17.3582 17.7398C16.6907 18.494 16.2858 19.4444 16.2045 20.4482C16.1232 21.4521 16.3697 22.4552 16.9071 23.307C17.4445 24.1588 18.2438 24.8132 19.1848 25.172C20.1259 25.5308 21.1579 25.5747 22.126 25.2969C23.0941 25.0191 23.9459 24.4348 24.5536 23.6316C25.1612 22.8285 25.4919 21.8498 25.496 20.8427C25.5086 20.6162 25.5086 20.3892 25.496 20.1627Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                                        :
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M15.4688 7.03125C14.5641 7.03125 13.8281 6.29527 13.8281 5.39062V0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V7.03125H15.4688ZM6.70312 16.875H10.1119C10.5002 16.875 10.815 17.1898 10.815 17.5781C10.815 17.9664 10.5002 18.2812 10.1119 18.2812H6.70312C6.31481 18.2812 6 17.9664 6 17.5781C6 17.1898 6.31481 16.875 6.70312 16.875ZM6 13.8281C6 13.4398 6.31481 13.125 6.70312 13.125H17.0156C17.4039 13.125 17.7188 13.4398 17.7188 13.8281C17.7188 14.2164 17.4039 14.5312 17.0156 14.5312H6.70312C6.31481 14.5312 6 14.2164 6 13.8281ZM17.0156 9.375C17.4039 9.375 17.7188 9.68981 17.7188 10.0781C17.7188 10.4664 17.4039 10.7812 17.0156 10.7812H6.70312C6.31481 10.7812 6 10.4664 6 10.0781C6 9.68981 6.31481 9.375 6.70312 9.375H17.0156Z" fill="#161E2E" />
                                                                                <path d="M15.2344 5.39101C15.2344 5.52024 15.3395 5.62538 15.4688 5.62538H20.8272C20.6986 5.38791 20.5331 5.1698 20.3343 4.9817L15.8145 0.705664C15.6391 0.539727 15.4437 0.401305 15.2344 0.291992V5.39101H15.2344Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='text-sm break-all text-center bg-transparent text-primaryDark p-1'>
                                                        {formData.name}
                                                    </div>
                                                </>
                                                {/* <div className="mt-4 flex justify-center gap-x-6 items-center">
                                                    <button
                                                        type="button"
                                                        // onClick={() => DeleteMedia(formData.id)}
                                                        className="flex gap-x-2 py-2 px-5 w-[187px] items-center justify-center rounded-3xl hover:bg-[#E41D1D] border-[1px] text-[#E41D1D] border-[#E41D1D] hover:bg-opacity-[0.8] text-base  font-semibold leading-[18px] hover:text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                    >
                                                        Delete Permanently
                                                    </button>

                                                </div> */}
                                                <div className='h-[85px] rounded-lg bg-whiteGrey pt-4 px-4'>
                                                    <label htmlFor="title" className="block text-sm font-normal leading-5 text-primaryDark">
                                                        Title
                                                    </label>
                                                    <div className="block w-full truncate bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold">
                                                        {formData.title ?? ''}
                                                    </div>

                                                </div>
                                                <div className='h-[150px] rounded-lg bg-whiteGrey pt-4 px-4'>
                                                    <label htmlFor="description" className="block text-sm font-normal leading-5 text-primaryDark">
                                                        Description
                                                    </label>
                                                    <div className="block resize-none w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold">
                                                        {formData.description ?? ''}

                                                    </div>
                                                </div>
                                                <div className="mt-4 flex justify-center gap-x-6 items-center">
                                                    <button
                                                        type="button"
                                                        onClick={() => selectedMedia(formData)}
                                                        className="flex gap-x-2 py-2 px-5 w-full items-center justify-center rounded-3xl bg-primaryBlue border-[1px] text-whiteGrey border-primaryBlue hover:bg-opacity-[0.8] text-base  font-semibold leading-[18px] hover:text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                    >
                                                        Select Media
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-2/3 px-6 py-6 max-h-[660px] overflow-y-auto newscrollbar'>
                                            <div className='flex flex-wrap gap-x-10 gap-y-7'>
                                                {
                                                    DataArray?.map((item, index) => (
                                                        <label
                                                        onClick={()=>SelectItem(item)}
                                                            htmlFor={`default-checkbox-${index}`} key={`item-${index}`} className={`flex- flex-col w-[186px] rounded-lg relative ${selectedItemId === item.id ? 'border-[1.5px] border-primaryBlue cursor-pointer' : 'border-[1.5px]'}`}>

                                                            <input
                                                                checked={selectedItemId === item.id}
                                                                onChange={() => handleCheckboxChange(item)}
                                                                id={`default-checkbox-${index}`} type="checkbox" className={`w-4 h-4 cursor-pointer absolute -right-2 -top-2 ${ selectedItemId === item.id ? 'block': 'hidden'}` }/>

                                                            <div className='h-[140px] bg-[#E1E9F2] rounded-tr-lg rounded-tl-lg flex justify-center items-center'>
                                                                {
                                                                    item.type === 'video' ?
                                                                        <video className='h-full object-cover rounded-tr-lg rounded-tl-lg'>
                                                                            <source src={item?.url} type="video/mp4"  />
                                                                        </video>
                                                                        :
                                                                        item.type === 'image' ?
                                                                            <img src={item?.url} alt='Media' className='object-cover h-full w-full rounded-tr-lg rounded-tl-lg' />
                                                                            :
                                                                            item.type === 'audio' ?
                                                                                <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                                        <path d="M25.496 20.1627V5.4959C25.495 5.29562 25.449 5.09813 25.3613 4.91808C25.2736 4.73803 25.1464 4.58006 24.9893 4.45589C24.8344 4.33058 24.6534 4.24159 24.4596 4.19544C24.2657 4.14929 24.064 4.14717 23.8693 4.18922L11.8693 6.85591C11.573 6.92272 11.3085 7.08865 11.1194 7.32626C10.9303 7.56387 10.8279 7.85891 10.8293 8.16259V19.2827C9.91906 18.853 8.89404 18.73 7.908 18.9322C6.92196 19.1344 6.02806 19.6508 5.3604 20.4041C4.69275 21.1574 4.28734 22.1068 4.20499 23.11C4.12265 24.1132 4.3678 25.1161 4.90369 25.9681C5.43957 26.8202 6.23728 27.4755 7.17715 27.8358C8.11702 28.1961 9.14838 28.2419 10.1165 27.9664C11.0846 27.6908 11.9373 27.1088 12.5466 26.3076C13.1559 25.5064 13.489 24.5293 13.496 23.5227C13.5097 23.2918 13.5097 23.0603 13.496 22.8294V9.22927L22.8293 7.16258V16.616C21.9185 16.186 20.8929 16.0631 19.9064 16.2658C18.9198 16.4684 18.0257 16.9857 17.3582 17.7398C16.6907 18.494 16.2858 19.4444 16.2045 20.4482C16.1232 21.4521 16.3697 22.4552 16.9071 23.307C17.4445 24.1588 18.2438 24.8132 19.1848 25.172C20.1259 25.5308 21.1579 25.5747 22.126 25.2969C23.0941 25.0191 23.9459 24.4348 24.5536 23.6316C25.1612 22.8285 25.4919 21.8498 25.496 20.8427C25.5086 20.6162 25.5086 20.3892 25.496 20.1627Z" fill="#161E2E" />
                                                                                    </svg>
                                                                                </div>
                                                                                :
                                                                                <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                        <path d="M15.4688 7.03125C14.5641 7.03125 13.8281 6.29527 13.8281 5.39062V0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V7.03125H15.4688ZM6.70312 16.875H10.1119C10.5002 16.875 10.815 17.1898 10.815 17.5781C10.815 17.9664 10.5002 18.2812 10.1119 18.2812H6.70312C6.31481 18.2812 6 17.9664 6 17.5781C6 17.1898 6.31481 16.875 6.70312 16.875ZM6 13.8281C6 13.4398 6.31481 13.125 6.70312 13.125H17.0156C17.4039 13.125 17.7188 13.4398 17.7188 13.8281C17.7188 14.2164 17.4039 14.5312 17.0156 14.5312H6.70312C6.31481 14.5312 6 14.2164 6 13.8281ZM17.0156 9.375C17.4039 9.375 17.7188 9.68981 17.7188 10.0781C17.7188 10.4664 17.4039 10.7812 17.0156 10.7812H6.70312C6.31481 10.7812 6 10.4664 6 10.0781C6 9.68981 6.31481 9.375 6.70312 9.375H17.0156Z" fill="#161E2E" />
                                                                                        <path d="M15.2344 5.39101C15.2344 5.52024 15.3395 5.62538 15.4688 5.62538H20.8272C20.6986 5.38791 20.5331 5.1698 20.3343 4.9817L15.8145 0.705664C15.6391 0.539727 15.4437 0.401305 15.2344 0.291992V5.39101H15.2344Z" fill="#161E2E" />
                                                                                    </svg>
                                                                                </div>
                                                                }
                                                            </div>
                                                            <div className='text-sm break-all text-center bg-transparent text-primaryDark p-1 line-clamp-3'>
                                                                {item.title ? item.title : item.name}
                                                            </div>

                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default MediaGalleryModal