import React, { useState } from 'react'
import SceduleModal from './sceduleModal';
import { ReplaceIconWhite } from '../../assets/icon-file';

function SliderConatiner({ sliderArray, setSliderArray, PublishNewSlide, DeleteSlide, UpdateSlide, OpenMedia }) {
    const [isOpen, setIsOpen] = useState(false)

    const handleBlockChange = (e, index) => {
        const type = e.target.type;
        const name = e.target.name;
        // console.log(type)
        let value = '';
        if (type === 'file') {
            value = e.target.files[0];
            // setSelectedFile(value);
            // console.log(value)
        }
        else {
            value = e.target.value
        }
        // Create a new array by spreading the existing state
        const newData = [...sliderArray];
        // Update the specific object in the array
        newData[index] = { ...newData[index], [name]: value };
        // Update the state with the new array
        setSliderArray(newData);
        // console.log(newData)

    }
    const currentDate = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        time: '',
        date: '',
        end_date: ''
    });
    const [flag, setFlag] = useState(false)
    const [index, setindex] = useState(0);
    const openMoadl = (index) => {
        setindex(index)
        setIsOpen(true)
        setFormData({
            time: '',
            date: currentDate,
            end_date: currentDate
        })
        setFlag(false)
    }

    const dateformat = (inputDate) => {
        // const inputDate = "2023-10-27";
        const dateObject = new Date(inputDate);

        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate

    }
    const ReplaceImage = (indexToUpdate) => {
        const updatedSliderArray = [...sliderArray];
        updatedSliderArray[indexToUpdate].image = null;
        setSliderArray(updatedSliderArray);
    }
    return (
        <div className='px-10 py-8'>
            <div className='text-primaryDark text-xl font-bold mb-4'>
                Home Page Carousel Slider:
            </div>
            {
                sliderArray?.map((slide, index) => (
                    <div key={'slide-' + index}>
                        <div className='text-lg font-normal text-primaryDark pb-3'>Slide {index + 1}:</div>
                        <div className='flex justify-between gap-6'>
                            <div className='w-2/4 max-w-[475px]'>
                                <div className=''>
                                    {slide.image ? (
                                        <div className='relative'>
                                            {
                                                typeof slide.image === 'string' ?

                                                    <img
                                                        src={slide.image}
                                                        alt="Preview"
                                                        className='w-full h-[286px] rounded-xl object-cover cursor-pointer'
                                                    />
                                                    :
                                                    <img
                                                        src={URL.createObjectURL(slide.image)}
                                                        alt="Preview"
                                                        className='w-full h-[286px] rounded-xl object-cover cursor-pointer'
                                                    />
                                            }
                                            <div style={{ background: `linear-gradient(0deg, rgba(22, 30, 46, 0.70) 0%, rgba(22, 30, 46, 0.30) 100%) ` }} className='w-full h-full rounded-xl absolute top-0 flex items-center justify-center bg-opacity-70 opacity-0 transition-opacity duration-300 hover:opacity-100'>
                                                <div>
                                                    <label

                                                        // htmlFor={`fileInput-${index}`}
                                                        onClick={() => ReplaceImage(index)}
                                                        className="flex cursor-pointer w-[190px] gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-whiteGrey hover:bg-opacity-[0.9] text-sm md:text-base font-bold leading-[18px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                    >
                                                        <span aria-hidden="true" className='text-[13px]'>
                                                            <ReplaceIconWhite />
                                                        </span>
                                                        Replace Image
                                                    </label>
                                                    {
                                                        slide.is_publish &&
                                                        <button

                                                            onClick={() => DeleteSlide(slide?.id)}
                                                            className="flex bg-[#E41D1D] mt-6 cursor-pointer w-[190px] gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-[#E41D1D] hover:bg-opacity-[0.9] text-sm md:text-base font-bold leading-[18px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                        >
                                                            <span aria-hidden="true" className='text-[13px]'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                    <path d="M15.8333 6.33325C15.6123 6.33325 15.4004 6.42105 15.2441 6.57733C15.0878 6.73361 15 6.94557 15 7.16659V16.4924C14.9761 16.9138 14.7867 17.3087 14.4729 17.5911C14.1592 17.8734 13.7466 18.0204 13.325 17.9999H6.67501C6.25342 18.0204 5.84083 17.8734 5.5271 17.5911C5.21337 17.3087 5.02392 16.9138 5.00001 16.4924V7.16659C5.00001 6.94557 4.91221 6.73361 4.75593 6.57733C4.59965 6.42105 4.38769 6.33325 4.16668 6.33325C3.94566 6.33325 3.7337 6.42105 3.57742 6.57733C3.42114 6.73361 3.33334 6.94557 3.33334 7.16659V16.4924C3.35713 17.3559 3.72216 18.1748 4.34849 18.7698C4.97483 19.3647 5.8114 19.6872 6.67501 19.6666H13.325C14.1886 19.6872 15.0252 19.3647 15.6515 18.7698C16.2779 18.1748 16.6429 17.3559 16.6667 16.4924V7.16659C16.6667 6.94557 16.5789 6.73361 16.4226 6.57733C16.2663 6.42105 16.0544 6.33325 15.8333 6.33325Z" fill="#F9F9F9" />
                                                                    <path d="M16.6667 3.83325H13.3333V2.16659C13.3333 1.94557 13.2455 1.73361 13.0893 1.57733C12.933 1.42105 12.721 1.33325 12.5 1.33325H7.5C7.27899 1.33325 7.06702 1.42105 6.91074 1.57733C6.75446 1.73361 6.66667 1.94557 6.66667 2.16659V3.83325H3.33333C3.11232 3.83325 2.90036 3.92105 2.74408 4.07733C2.5878 4.23361 2.5 4.44557 2.5 4.66659C2.5 4.8876 2.5878 5.09956 2.74408 5.25584C2.90036 5.41212 3.11232 5.49992 3.33333 5.49992H16.6667C16.8877 5.49992 17.0996 5.41212 17.2559 5.25584C17.4122 5.09956 17.5 4.8876 17.5 4.66659C17.5 4.44557 17.4122 4.23361 17.2559 4.07733C17.0996 3.92105 16.8877 3.83325 16.6667 3.83325ZM8.33333 3.83325V2.99992H11.6667V3.83325H8.33333Z" fill="#F9F9F9" />
                                                                    <path d="M9.16667 14.6667V8.83333C9.16667 8.61232 9.07887 8.40036 8.92259 8.24408C8.76631 8.0878 8.55435 8 8.33333 8C8.11232 8 7.90036 8.0878 7.74408 8.24408C7.5878 8.40036 7.5 8.61232 7.5 8.83333V14.6667C7.5 14.8877 7.5878 15.0996 7.74408 15.2559C7.90036 15.4122 8.11232 15.5 8.33333 15.5C8.55435 15.5 8.76631 15.4122 8.92259 15.2559C9.07887 15.0996 9.16667 14.8877 9.16667 14.6667Z" fill="#F9F9F9" />
                                                                    <path d="M12.5 14.6667V8.83333C12.5 8.61232 12.4122 8.40036 12.2559 8.24408C12.0997 8.0878 11.8877 8 11.6667 8C11.4457 8 11.2337 8.0878 11.0774 8.24408C10.9211 8.40036 10.8333 8.61232 10.8333 8.83333V14.6667C10.8333 14.8877 10.9211 15.0996 11.0774 15.2559C11.2337 15.4122 11.4457 15.5 11.6667 15.5C11.8877 15.5 12.0997 15.4122 12.2559 15.2559C12.4122 15.0996 12.5 14.8877 12.5 14.6667Z" fill="#F9F9F9" />
                                                                </svg>
                                                            </span>
                                                            Delete Slide
                                                        </button>
                                                    }
                                                </div>
                                                {/* <input
                                                    type="file"
                                                    id={`fileInput-${index}`}
                                                    name='image'
                                                    accept="image/*"  
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleBlockChange(e, index)}
                                                /> */}
                                            </div>
                                        </div>
                                    ) :
                                        <div
                                            className=' border-primaryBlue py-14 p-4 rounded-xl border-dashed border-[1px] flex flex-col justify-center items-center'>
                                            <label htmlFor={`fileInput-${index}`} className='flex flex-col justify-center items-center gap-4 cursor-pointer'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="79" height="68" viewBox="0 0 79 68" fill="none">
                                                    <path d="M41.1781 16.589L41.2822 16.6201L41.2867 16.6149C41.779 16.7039 42.2673 16.4092 42.4119 15.9209C43.73 11.4921 47.8825 8.39817 52.5089 8.39817C53.0567 8.39817 53.5009 7.95393 53.5009 7.40621C53.5009 6.85848 53.0566 6.41426 52.5089 6.41426C46.823 6.41426 42.0449 10.2 40.5107 15.3555C40.3542 15.8807 40.6533 16.4325 41.1781 16.589Z" fill="#186CCE" stroke="#F9FFF9" strokeWidth="0.3375" />
                                                    <path d="M64.0334 47.7432H59.0943C58.6398 47.7432 58.2711 47.3745 58.2711 46.92C58.2711 46.4655 58.6398 46.0968 59.0943 46.0968H64.0334C70.8418 46.0968 76.3814 40.5572 76.3814 33.7488C76.3814 26.9405 70.8418 21.4009 64.0334 21.4009H63.9147C63.6759 21.4009 63.449 21.2974 63.2926 21.1169C63.1362 20.9364 63.0656 20.6971 63.0997 20.4607C63.1732 19.948 63.2103 19.4329 63.2103 18.9313C63.2103 13.0308 58.4092 8.22972 52.5086 8.22972C50.2131 8.22972 48.024 8.94708 46.1776 10.3047C45.7719 10.6029 45.1956 10.4706 44.961 10.0243C39.732 0.0671616 26.0743 -1.26998 18.9935 7.39184C16.0106 11.0409 14.8386 15.7878 15.7778 20.4143C15.8812 20.9253 15.4902 21.4015 14.971 21.4015H14.6411C7.83278 21.4015 2.2932 26.9411 2.2932 33.7495C2.2932 40.5578 7.83278 46.0974 14.6411 46.0974H19.5803C20.0348 46.0974 20.4035 46.4661 20.4035 46.9206C20.4035 47.3751 20.0348 47.7438 19.5803 47.7438H14.6411C6.92479 47.7438 0.646729 41.4658 0.646729 33.7494C0.646729 26.2495 6.57722 20.1084 13.9949 19.7698C13.2982 14.9699 14.6306 10.1283 17.7187 6.34992C25.2997 -2.92455 39.8282 -1.885 45.9697 8.4567C47.929 7.22837 50.171 6.58399 52.5084 6.58399C59.6572 6.58399 65.322 12.6686 64.8264 19.7774C72.1757 20.1896 78.0275 26.2983 78.0275 33.7488C78.0275 41.4658 71.7494 47.7432 64.0331 47.7432L64.0334 47.7432Z" fill="#186CCE" />
                                                    <path d="M18.478 46.4551C18.478 57.8963 27.7859 67.204 39.2269 67.204C50.6681 67.204 59.9758 57.8962 59.9758 46.4551C59.9758 35.014 50.6681 25.7063 39.2269 25.7063C27.7857 25.7063 18.478 35.0141 18.478 46.4551ZM20.4623 46.4551C20.4623 36.1086 28.8802 27.6905 39.2269 27.6905C49.5734 27.6905 57.9915 36.1085 57.9915 46.4551C57.9915 56.8016 49.5734 65.2198 39.2269 65.2198C28.8804 65.2198 20.4623 56.8018 20.4623 46.4551Z" fill="#186CCE" stroke="#F9FFF9" strokeWidth="0.3375" />
                                                    <path d="M38.8322 54.7396C38.8322 55.1656 39.1777 55.511 39.6037 55.511C40.0295 55.511 40.3751 55.1661 40.3751 54.7396V39.07C40.3751 38.644 40.0296 38.2985 39.6037 38.2985C39.1777 38.2985 38.8322 38.644 38.8322 39.07V54.7396Z" fill="#186CCE" stroke="#186CCE" strokeWidth="0.3375" />
                                                    <path d="M39.6033 40.1625L35.3276 44.4383L39.6033 40.1625ZM39.6033 40.1625L43.8792 44.4384C44.0296 44.5888 44.2277 44.6643 44.4247 44.6643L39.6033 40.1625ZM34.2365 44.4384C34.5378 44.7396 35.0264 44.7398 35.3275 44.4384L39.0577 38.5261L34.2365 43.3474C33.9351 43.6487 33.9351 44.137 34.2365 44.4384ZM40.1488 38.526C39.8475 38.2248 39.3589 38.2246 39.0578 38.526L44.4247 44.6643C44.6214 44.6643 44.8195 44.5895 44.9702 44.4384C45.2715 44.137 45.2715 43.6487 44.9702 43.3474L40.1488 38.526Z" fill="#186CCE" stroke="#186CCE" strokeWidth="0.3375" />
                                                </svg>
                                                <span className='text-primaryBlue text-base font-bold underline'>
                                                    Upload or Choose Image
                                                </span>
                                            </label>
                                            <input
                                                type="file"
                                                id={`fileInput-${index}`}
                                                name='image'
                                                accept="image/*"  // Specify the file types allowed (e.g., images)
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleBlockChange(e, index)}
                                            />

                                            <div
                                                className='mt-[10px] mb-4 text-base font-semibold leading-[normal] flex justify-center'
                                            >Or</div>
                                            <div className='w-full'>
                                                <button
                                                    type="button"
                                                    onClick={() => OpenMedia(index)}
                                                    className="mx-auto flex w-full max-w-[273px] h-8 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-sm font-bold leading-[28px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                                >
                                                    Choose file from media
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='flex gap-x-4 mt-6 justify-center'>
                                    <button
                                        type="button"
                                        onClick={() => openMoadl(index)}
                                        className="flex w-[164px] gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-bold leading-[18px] text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    >
                                        <span aria-hidden="true" className='text-[13px]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                <path d="M6.56079 1.75C6.21533 1.75 5.93579 2.02985 5.93579 2.375V2.79279H5.15759C3.5658 2.79279 2.27124 4.08734 2.27124 5.67883V14.3327C2.27124 15.9239 3.5658 17.2184 5.15759 17.2184H10.6651C11.4426 18.4375 12.8035 19.25 14.3531 19.25C16.7659 19.25 18.7288 17.2871 18.7288 14.8747C18.7288 13.3247 17.9162 11.9637 16.6969 11.1863V5.67883C16.6969 4.08734 15.4023 2.79279 13.8112 2.79279H13.033V2.375C13.033 2.02985 12.7534 1.75 12.408 1.75C12.0625 1.75 11.783 2.02985 11.783 2.375V2.79279H7.18579V2.375C7.18579 2.02985 6.90625 1.75 6.56079 1.75ZM6.56079 5.08557C6.90625 5.08557 7.18579 4.80573 7.18579 4.46057V4.04279H11.783V4.46057C11.783 4.80573 12.0625 5.08557 12.408 5.08557C12.7534 5.08557 13.033 4.80573 13.033 4.46057V4.04279H13.8112C14.7133 4.04279 15.4469 4.77673 15.4469 5.67883V10.6434C15.0966 10.5527 14.7314 10.4994 14.3531 10.4994C11.941 10.4994 9.97815 12.4623 9.97815 14.8747C9.97815 15.253 10.0315 15.6181 10.1222 15.9684H5.15759C4.25549 15.9684 3.52124 15.2348 3.52124 14.3327V5.67883C3.52124 4.77673 4.25549 4.04279 5.15759 4.04279H5.93579V4.46057C5.93579 4.80573 6.21533 5.08557 6.56079 5.08557ZM11.2281 14.8747C11.2281 13.1514 12.6301 11.7494 14.3531 11.7494C16.0768 11.7494 17.4788 13.1514 17.4788 14.8747C17.4788 16.598 16.0768 18 14.3531 18C12.6301 18 11.2281 16.598 11.2281 14.8747Z" fill="#186CCE" />
                                                <path d="M14.3531 15.4996H15.7899C16.1354 15.4996 16.4149 15.2198 16.4149 14.8746C16.4149 14.5295 16.1354 14.2496 15.7899 14.2496H14.9781V13.4385C14.9781 13.0933 14.6986 12.8135 14.3531 12.8135C14.0077 12.8135 13.7281 13.0933 13.7281 13.4385V14.8746C13.7281 15.2198 14.0077 15.4996 14.3531 15.4996Z" fill="#186CCE" />
                                            </svg>
                                        </span>
                                        Schedule
                                    </button>
                                    {
                                        slide.is_publish ?
                                            <button
                                                type="button"
                                                // disabled={slide.is_publish}
                                                onClick={() => UpdateSlide(slide)}
                                                className="flex gap-x-2 w-[164px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-bold leading-[18px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                            >
                                                Update
                                                <span aria-hidden="true" className='text-[13px]'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                        <g clipPath="url(#clip0_1737_6393)">
                                                            <path d="M15.832 7.76801C15.6676 7.42855 15.3935 7.15446 15.054 6.99008L2.407 0.870241C1.57387 0.466608 0.571319 0.814814 0.167686 1.64794C-0.0379446 2.07243 -0.0552305 2.56393 0.120117 3.00181L2.31929 8.49867L0.120117 13.9961C-0.222426 14.8585 0.198984 15.8352 1.06135 16.1777C1.25634 16.2552 1.46413 16.2954 1.67395 16.2964C1.92781 16.2964 2.17839 16.2391 2.407 16.1288L15.054 10.0073C15.8872 9.60373 16.2355 8.60118 15.832 7.76801ZM1.92034 15.1214C1.64259 15.2558 1.30843 15.1397 1.17397 14.8619C1.10545 14.7204 1.09975 14.5566 1.15822 14.4107L3.29929 9.05739H14.4523L1.92034 15.1214ZM3.29932 7.93992L1.15826 2.58665C1.06914 2.37327 1.12447 2.12684 1.29625 1.97205C1.46253 1.81003 1.7136 1.77202 1.92034 1.87764L14.4523 7.93992H3.29932Z" fill="#F9F9F9" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1737_6393">
                                                                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>

                                            </button>
                                            :
                                            <button
                                                type="button"
                                                disabled={slide.is_publish}
                                                onClick={() => PublishNewSlide(slide)}
                                                className="flex gap-x-2 w-[164px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-bold leading-[18px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                            >
                                                Publish
                                                <span aria-hidden="true" className='text-[13px]'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                        <g clipPath="url(#clip0_1737_6393)">
                                                            <path d="M15.832 7.76801C15.6676 7.42855 15.3935 7.15446 15.054 6.99008L2.407 0.870241C1.57387 0.466608 0.571319 0.814814 0.167686 1.64794C-0.0379446 2.07243 -0.0552305 2.56393 0.120117 3.00181L2.31929 8.49867L0.120117 13.9961C-0.222426 14.8585 0.198984 15.8352 1.06135 16.1777C1.25634 16.2552 1.46413 16.2954 1.67395 16.2964C1.92781 16.2964 2.17839 16.2391 2.407 16.1288L15.054 10.0073C15.8872 9.60373 16.2355 8.60118 15.832 7.76801ZM1.92034 15.1214C1.64259 15.2558 1.30843 15.1397 1.17397 14.8619C1.10545 14.7204 1.09975 14.5566 1.15822 14.4107L3.29929 9.05739H14.4523L1.92034 15.1214ZM3.29932 7.93992L1.15826 2.58665C1.06914 2.37327 1.12447 2.12684 1.29625 1.97205C1.46253 1.81003 1.7136 1.77202 1.92034 1.87764L14.4523 7.93992H3.29932Z" fill="#F9F9F9" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1737_6393">
                                                                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>

                                            </button>

                                    }

                                </div>
                            </div>
                            <div className='flex-grow'>
                                <div className='space-y-4'>
                                    <div className='bg-whiteGrey rounded-lg h-[85px] p-4'>
                                        <label htmlFor="title" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                            Add Title
                                        </label>
                                        <div className="">
                                            <input
                                                id="title"
                                                name="title"
                                                type="text"
                                                autoComplete="off"
                                                required
                                                value={slide.title}
                                                placeholder='write here...'
                                                onChange={(e) => handleBlockChange(e, index)}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                    <div className='bg-whiteGrey rounded-lg h-[85px] p-4'>
                                        <label htmlFor="description" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                            Add Decription
                                        </label>
                                        <div className="">
                                            <input
                                                id="description"
                                                name="description"
                                                type="text"
                                                autoComplete="off"
                                                required
                                                value={slide.description}
                                                placeholder='write here...'
                                                onChange={(e) => handleBlockChange(e, index)}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                    {/* <div>
                                    
                                    </div> */}
                                    <div className='flex gap-x-3'>
                                        <div className='bg-whiteGrey rounded-lg h-[85px] flex-grow p-4'>
                                            <label htmlFor="btnText" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                                Button Text
                                            </label>
                                            <div className="">
                                                <input
                                                    id="btnText"
                                                    name="btnText"
                                                    type="text"
                                                    autoComplete="off"
                                                    required
                                                    value={slide.btnText}
                                                    placeholder='write here...'
                                                    onChange={(e) => handleBlockChange(e, index)}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        <div className='bg-whiteGrey rounded-lg h-[85px] flex-grow p-4'>
                                            <label htmlFor="btnUrl" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                                Button URL
                                            </label>
                                            <div className="">
                                                <input
                                                    id="btnUrl"
                                                    name="btnUrl"
                                                    type="text"
                                                    autoComplete="off"
                                                    required
                                                    value={slide.btnUrl}
                                                    placeholder='write here...'
                                                    onChange={(e) => handleBlockChange(e, index)}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-whiteGrey rounded-lg h-[85px] p-4'>
                                        <label htmlFor="btnText" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                            Sechedule Date
                                        </label>
                                        <div className="">
                                            <div
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            >{dateformat(slide.date)} to {dateformat(slide.end_date)}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                ))
            }
            <SceduleModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleBlockChange={handleBlockChange}
                index={index}
                flag={flag}
                setFlag={setFlag}
                formData={formData}
                setFormData={setFormData}
            />
        </div>
    )
}

export default SliderConatiner