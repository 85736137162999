import React from 'react';
import deleteIcon from '../../assets/delete-icon.svg'
import editIcon from '../../assets/edit-icon.svg'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { AdminBlockUser, DeleteUser } from '../../service/service';
import ReactPaginate from 'react-paginate';
function UserTable({ userData, showUserList, setIsLoading, setUserData, EditUser,
    totalPages, currentPage, handlePageChange, noData, userPermissions, headCells
}) {

    const toggleBlockStatus = (id, status) => {

        Swal.fire({
            title: 'Are you sure?',
            text: `You want ${status ? 'unblock' : 'block'} this user!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoading(true);
                const formData = {
                    id,
                    blocks: !status,
                }
                // console.log(formData)
                AdminBlockUser(formData)
                    .then((result) => {
                        setIsLoading(false)
                        if (result.status) {
                            showUserList()
                        }
                        else {
                            Swal.fire({
                                title: 'Error!',
                                text: result.message,
                                icon: 'error',
                                timer: 3000,
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                    .catch((error) => {
                        setIsLoading(false)
                        console.log(error.message);
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            timer: 3000,
                            confirmButtonText: 'OK'
                        })
                    });

            }
        })



    };
    const deleteUser = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoading(true);
                const formData = {
                    id,
                }
                DeleteUser(formData)
                    .then((result) => {
                        setIsLoading(false)
                        if (result.status) {
                            const updatedItems = userData.filter(item => item.id !== id);
                            setUserData(updatedItems);
                            Swal.fire({
                                icon: 'success',
                                title: "User Deleted Successfully!",
                                showConfirmButton: false,
                                timer: 1500
                            }
                            )
                        }
                        else {
                            Swal.fire({
                                title: 'Error!',
                                text: result.message,
                                icon: 'error',
                                timer: 3000,
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                    .catch((error) => {
                        setIsLoading(false)
                        console.log(error.message);
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            timer: 3000,
                            confirmButtonText: 'OK'
                        })
                    });

            }
        })

    }

    return (
        <div className='px-10 py-8'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-whiteGrey">
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xl h-[70px] text-whiteGrey font-bold leading-[21px] bg-primaryDark ">
                        <tr>
                            {headCells?.map((item) => (
                                item.isShow &&
                                <th key={item.id} scope="col" className="px-6 py-3 whitespace-nowrap">
                                    {item.id}
                                </th>
                            ))}
                            <th scope="col" className="px-6 py-3 w-fit">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-base font-semibold leading-[21px] text-primaryDark'>
                        {
                            userData?.map((obj) => (
                                <tr key={obj.id} className="bg-whiteGrey h-[69px] border-b hover:bg-gray-100">
                                    {
                                        headCells[0].isShow &&
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {obj.name}
                                        </td>
                                    }
                                    {
                                        headCells[1].isShow &&
                                        <td className="px-6 py-4">
                                            <Link
                                                to={`mailto:${obj.email}`}
                                                className="font-medium text-primaryBlue  hover:underline"
                                            >
                                                {obj.email}
                                            </Link>
                                        </td>
                                    }
                                    {
                                        headCells[2].isShow &&
                                        <td className="px-6 py-4">
                                            {obj.roles[0]?.name ? obj.roles[0]?.name : obj.is_admin === 1 ? 'Admin User' : 'Subscriber'}
                                        </td>
                                    }
                                    {
                                        headCells[3].isShow &&
                                        <td className="px-6 py-4">
                                            {obj.block ?
                                                <span className='px-4 py-1 bg-red-500 bg-opacity-20 rounded-[34px] text-[#E41D1D]'>Disabled</span>
                                                :
                                                <span className='px-4 py-1 bg-green-600 bg-opacity-10 rounded-[34px] text-[#249A1A]'>Enabled</span>

                                            }
                                        </td>
                                    }
                                    <td className="px-6 py-4 text-right">
                                        {
                                            obj.id !== 1 &&
                                            <div className='flex gap-x-4'>
                                                {
                                                    userPermissions?.includes('edit_user') &&
                                                    <>
                                                        <span onClick={() => toggleBlockStatus(obj.id, obj.block)} className='w-6 h-6 cursor-pointer'>
                                                            {obj.block ?
                                                                <svg fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"></path>
                                                                </svg>

                                                                :
                                                                <svg fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"></path>
                                                                </svg>

                                                            }
                                                        </span>
                                                        {
                                                            // obj.is_admin===1 &&
                                                            <span
                                                                onClick={() => EditUser(obj)}
                                                                className='cursor-pointer'>
                                                                <img src={editIcon} className='max-w-[unset]' alt="Edit" />
                                                            </span>
                                                        }
                                                    </>
                                                }
                                                {

                                                    userPermissions?.includes('delete_user') &&
                                                    <span
                                                        onClick={() => deleteUser(obj.id)}
                                                        className='cursor-pointer'>
                                                        <img src={deleteIcon} className='max-w-[unset]' alt="Delete" />
                                                    </span>
                                                }
                                            </div>
                                        }
                                    </td>

                                </tr>

                            ))
                        }
                    </tbody>

                </table>
                {
                    userData.length === 0 &&

                    <div className='flex h-[80px] items-center justify-center text-lg font-bold'>
                        {noData}
                    </div>
                }
                <div className='pagination w-full bg-whiteGrey px-6'>
                    <ReactPaginate
                        className='flex justify-end items-center gap-x-3 h-[80px]'
                        pageCount={totalPages}
                        pageRangeDisplayed={5} // Adjust as needed
                        marginPagesDisplayed={2} // Adjust as needed
                        initialPage={currentPage}
                        previousLabel={
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z" fill="#161E2E" />
                            </svg>
                        }
                        nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.94801 19.9968C9.66304 19.6829 9.6887 19.1993 10.0053 18.9168L16.0756 13.5L10.0053 8.08324C9.6887 7.80069 9.66303 7.31712 9.94801 7.00317C10.233 6.68923 10.7207 6.66378 11.0373 6.94633L17.7447 12.9315C17.9072 13.0766 18 13.2832 18 13.5C18 13.7168 17.9072 13.9234 17.7447 14.0685L11.0373 20.0537C10.7207 20.3362 10.233 20.3108 9.94801 19.9968Z" fill="#161E2E" />
                        </svg>}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default UserTable;
