import React from 'react';

export function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
        <button onClick={onClick} className="custom-arrow prev-arrow border-[#D2D6DC] border-[1px] rounded-2xl p-6 hidden lg:block">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2.61882 11.3658C2.69421 11.1614 2.80725 10.9746 2.95146 10.8162L10.8714 2.48975C11.0191 2.33448 11.1944 2.21131 11.3874 2.12728C11.5804 2.04325 11.7872 2 11.9961 2C12.4179 2 12.8224 2.17617 13.1207 2.48975C13.2684 2.64502 13.3855 2.82935 13.4655 3.03222C13.5454 3.23509 13.5865 3.45252 13.5865 3.67211C13.5865 4.11558 13.419 4.54089 13.1207 4.85447L7.89351 10.3333H19.916C20.3361 10.3333 20.739 10.5087 21.0361 10.821C21.3331 11.1333 21.5 11.5569 21.5 11.9986C21.5 12.4402 21.3331 12.8638 21.0361 13.1761C20.739 13.4884 20.3361 13.6639 19.916 13.6639H7.89351L13.1207 19.1427C13.2691 19.2975 13.387 19.4817 13.4674 19.6846C13.5478 19.8875 13.5892 20.1052 13.5892 20.3251C13.5892 20.5449 13.5478 20.7626 13.4674 20.9655C13.387 21.1684 13.2691 21.3526 13.1207 21.5074C12.9734 21.6635 12.7982 21.7874 12.6052 21.8719C12.4122 21.9565 12.2052 22 11.9961 22C11.7869 22 11.5799 21.9565 11.3869 21.8719C11.1939 21.7874 11.0187 21.6635 10.8714 21.5074L2.95146 13.1809C2.80725 13.0226 2.69421 12.8358 2.61882 12.6314C2.46039 12.226 2.46039 11.7712 2.61882 11.3658Z" fill="#F4FBF7" />
            </svg>
        </button>
    );
}

export function CustomNextArrow(props) {
    const { onClick } = props;
    return (
        <button onClick={onClick} className="custom-arrow next-arrow border-[#D2D6DC] border-[1px] rounded-2xl p-6 hidden lg:block">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.3812 11.3658C21.3058 11.1614 21.1927 10.9746 21.0485 10.8162L13.1286 2.48975C12.9809 2.33448 12.8056 2.21131 12.6126 2.12728C12.4196 2.04325 12.2128 2 12.0039 2C11.5821 2 11.1776 2.17617 10.8793 2.48975C10.7316 2.64502 10.6145 2.82935 10.5345 3.03222C10.4546 3.23509 10.4135 3.45252 10.4135 3.67211C10.4135 4.11558 10.581 4.54089 10.8793 4.85447L16.1065 10.3333H4.08399C3.66389 10.3333 3.261 10.5087 2.96394 10.821C2.66688 11.1333 2.5 11.5569 2.5 11.9986C2.5 12.4402 2.66688 12.8638 2.96394 13.1761C3.261 13.4884 3.66389 13.6639 4.08399 13.6639H16.1065L10.8793 19.1427C10.7309 19.2975 10.613 19.4817 10.5326 19.6846C10.4522 19.8875 10.4108 20.1052 10.4108 20.3251C10.4108 20.5449 10.4522 20.7626 10.5326 20.9655C10.613 21.1684 10.7309 21.3526 10.8793 21.5074C11.0266 21.6635 11.2018 21.7874 11.3948 21.8719C11.5878 21.9565 11.7948 22 12.0039 22C12.2131 22 12.4201 21.9565 12.6131 21.8719C12.8061 21.7874 12.9813 21.6635 13.1286 21.5074L21.0485 13.1809C21.1927 13.0226 21.3058 12.8358 21.3812 12.6314C21.5396 12.226 21.5396 11.7712 21.3812 11.3658Z" fill="#F4FBF7" />
            </svg>
        </button>
    );
}
