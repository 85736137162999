import React, { useState } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import DashboardSideNav from '../../adminComponents/DashboardComponents/DashboardSideNav'
import DashboardHeader from '../../adminComponents/DashboardComponents/DashboardHeader'
import avatar from '../../assets/avatar.svg'
import { useDispatch, useSelector } from 'react-redux';
import { UpdateAdminProfile } from '../../service/service'
import { updateAdminUser } from '../../store/slices/AdminUserSlice'
import Swal from 'sweetalert2'

function AdminSetting() {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.adminUser.userData)
    const [formData, setFormData] = useState({
        f_name: userData.name?.split(' ')[0],
        l_name: userData.name?.split(' ')[1],
        email: userData.email,
        phoneNumber: (userData.phone === null || userData.phone === 'null') ? '' : userData.phone,

    })
    const [formErrors, setFormErrors] = useState({
        f_name: '',
        l_name: ''
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (!/\s/.test(value)) {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
    
            }))
          }
    }
    const submitData = () => {
        const data = {
            name: formData.f_name + ' ' + formData.l_name,
            id: userData.id
        }
        if (validateForm()) {
            setIsLoading(true)
            UpdateAdminProfile(data).then((result) => {
                setIsLoading(false)
                if (result.status) {
                    // console.log(result)
                    dispatch(updateAdminUser({
                        userData: {
                            ...userData,
                            name: formData.f_name + ' ' + formData.l_name
                        }
                    }));
                    Swal.fire({
                        icon: 'success',
                        title: "Profile Updated Successfully.",
                        showConfirmButton: false,
                        timer: 1500
                      }
                      )
                }
            })
        }
    }
    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formData.f_name) {
            isValid = false;
            errors.f_name = 'First name is required';
        }
        if (!formData.l_name) {
            isValid = false;
            errors.l_name = 'Last name is required';
        }
        setFormErrors(errors);
        return isValid;
    };
    return (
        <>
            {
                isLoading &&
                <div className="overlay">
                    <div className="loaderImage-container">
                        <ThreeCircles
                            height="100"
                            width="100"
                            color="#F9F9F9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    </div>
                </div>
            }
            <div className='bg-whiteGrey'>
                <DashboardSideNav />
                <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                    <DashboardHeader title={'Setting'} subtitle={'Profile'} />
                    <div className='px-10 py-8 text-primaryDark'>
                        <div className='flex items-center gap-6 mb-9'>
                            <div className='w-[90px] h-[90px] rounded-full border-[1px] border-primaryBlue'>
                                <span>
                                    <img src={avatar} alt="profile" />
                                </span>
                            </div>
                            <div>
                                <div className='text-xl font-bold'>{userData.name}</div>
                                <div className='text-base font-normal'>{userData.roles[0].name}</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-5'>

                            <div className='flex  gap-x-5'>
                                <div className='flex-grow'>
                                    <div className='bg-whiteGrey rounded-lg h-[85px] p-4 max-w-screen-sm'>
                                        <label htmlFor="description" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                            First Name
                                        </label>
                                        <div className="">
                                            <input
                                                id="f_name"
                                                name="f_name"
                                                type="text"
                                                autoComplete="off"
                                                required
                                                value={formData.f_name}
                                                onChange={handleChange}
                                                placeholder='write here...'
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                    {formErrors.f_name && <div className="text-red-500 px-2 !mt-1">{formErrors.f_name}</div>}
                                </div>
                                <div className='flex-grow '>
                                <div className='bg-whiteGrey rounded-lg h-[85px] p-4 max-w-screen-sm'>
                                    <label htmlFor="description" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                        Last Name
                                    </label>
                                    <div className="">
                                        <input
                                            id="l_name"
                                            name="l_name"
                                            type="text"
                                            autoComplete="off"
                                            required
                                            value={formData.l_name}
                                            onChange={handleChange}
                                            placeholder='write here...'
                                            className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                        />
                                    </div>
                                </div>
                                {formErrors.l_name && <div className="text-red-500 px-2 !mt-1">{formErrors.l_name}</div>}

                                </div>
                            </div>
                            <div className='flex  gap-x-5'>
                                <div className='bg-whiteGrey flex-grow rounded-lg h-[85px] p-4 max-w-screen-sm'>
                                    <label htmlFor="description" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                        Email Address
                                    </label>
                                    <div className="">
                                        <input
                                            id="email"
                                            readOnly={true}
                                            name="email"
                                            type="email"
                                            autoComplete="off"
                                            required
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder='write here...'
                                            className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                        />
                                    </div>
                                </div>
                                <div className='bg-whiteGrey flex-grow rounded-lg h-[85px] p-4 max-w-screen-sm'>
                                    <label htmlFor="description" className="block text-sm font-normal text-primaryDark text-opacity-80">
                                        Phone No.
                                    </label>
                                    <div className="">
                                        <input
                                            id="phoneNumber"
                                            readOnly={true}
                                            name="phoneNumber"
                                            type="tel"
                                            autoComplete="off"
                                            required
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            placeholder='+000000000000'
                                            className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-6 flex justify-end max-w-2xl'>
                            <button
                                type="button"
                                onClick={submitData}
                                className="flex gap-x-2 px-6 h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AdminSetting