import React from 'react';
import { useNavigate } from 'react-router';

function Unauthorized() {
    const navigate = useNavigate()
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full md:w-1/2 lg:w-1/3">
        <h2 className="text-2xl font-semibold mb-4">Unauthorized Access</h2>
        <p className="text-gray-700 mb-4">
          You do not have permission to access this page. Please contact the administrator for assistance.
        </p>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={() => {
            navigate('/admin')
          }}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
}

export default Unauthorized;
