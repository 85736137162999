import React from 'react'
import logo from '../../assets/logo-dark.svg'
function LoginHeader() {
    return (
        <div className='pt-[52px] flex w-full justify-center admin-logo-div mb-[132px]'>
            <img src={logo} alt="inside chassidus" />
        </div>
    )
}

export default LoginHeader