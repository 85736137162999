import { Disclosure } from '@headlessui/react';
import arrowUp from '../../assets/arrowUp.svg';
import BlockSelectFile from "../EditorNewBlock/BlockSelectFile";
export default function TabSection2(props) {
  const { tabText, selectedBlockTitle, blocksAmount, blocks, setBlocks } = props;
  const videoData = blocks?.find(item => item.block === "Video");

  return (
    <div className="p-4 flex-col space-y-3">
      {
        tabText !== "Series" &&
        <p>Select a block from the right side of the page to see more options here.</p>
      }
      {
        blocksAmount > 0 && selectedBlockTitle && 
        <h1 className="text-base font-bold">{selectedBlockTitle}</h1>
      }
      {
        blocksAmount > 0 && selectedBlockTitle === "Video Options" &&
        <Disclosure defaultOpen className="rounded-lg py-4 px-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                <span className="text-sm font-bold mb-2">Video Poster</span>
                <img
                  alt=""
                  src={arrowUp}
                  className={`${open ? '' : 'rotate-180 transform'} h-5 w-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-sm text-primaryDark rounded-lg bg-[#D2D6DC] xs:bg-opacity-[0.4] py-4 px-2">
                <BlockSelectFile
                  fileType="Images"
                  inputType="image/*"
                  videoPoster={true}
                  setBlocks={setBlocks}
                  data={videoData?.metaData?.videoPoster}                                      
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      }
    </div>
  )
}
