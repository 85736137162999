import { ReplaceIcon, DeleteIconDark, ArrowDownIcon, ArrowUpIcon } from "../../assets/icon-file";

export default function FloatingControls(props) {
  const { 
    type, blockType, blocks, 
    replaceBlock, removeBlock, index, 
    handleReorderBlocks, fileUploaded, value
  } = props;
  const isOrderUpDisabled = blocks?.length < 2 || index === 0 ? true : false;
  const isOrderDownDisabled = blocks?.length < 2 || index === blocks?.length - 1 ? true : false;

  return (
    <div
      className={`w-fit z-20 absolute 
        ${ type === "Documents" && fileUploaded ? 'top-12 right-4' : fileUploaded ? 'top-4 right-4' : 'top-0 right-0 '} 
        bg-whiteGrey h-[30px] rounded-xl flex`}
    >
      <button type="button" onClick={(event) => replaceBlock(event)} className={`flex xl:gap-x-2 items-center justify-center px-4 py-1 text-sm font-medium ${fileUploaded || value?.length > 0 ? 'text-gray-900 hover:bg-gray-400 hover:text-white  dark:hover:text-white dark:hover:bg-gray-700' : 'text-gray-300'} focus:bg-gray-900 focus:text-white bg-transparent border border-gray-900 rounded-s-xl dark:border-white dark:text-white dark:focus:bg-gray-700`}>
        <span className="">
          <ReplaceIcon 
            classes={`${ 
              (blockType === 'select file' && !fileUploaded) || 
              (blockType === 'text field' && value?.length ===  0) ? 
              'fill-gray-300' : 'fill-gray-900'
            }`} 
          />
        </span>
        <div className="xs:invisible xs:max-w-0 xl:visible xl:min-w-[50px]">{blockType === 'select file' ? 'Replace' : 'Reset'}</div>
      </button>
      <div className="flex flex-col w-full border-y border-gray-900">
        <button
          className={`flex items-center justify-center w-full px-4 max-h-[14px] ${isOrderUpDisabled ? 'text-gray-300' : 'hover:bg-gray-400 hover:text-white dark:hover:text-white dark:hover:bg-gray-700'} dark:border-white dark:text-white`} 
          type="button" 
          disabled={isOrderUpDisabled} 
          onClick={() => handleReorderBlocks(index)}
        >
          <ArrowUpIcon height="15px" />
        </button>
        <button
          className={`flex items-center justify-center w-full px-4 max-h-[14px] ${isOrderDownDisabled ? 'text-gray-300' : 'hover:bg-gray-400 hover:text-white dark:hover:text-white dark:hover:bg-gray-700'}  dark:border-white dark:text-white`}
          type="button" 
          disabled={isOrderDownDisabled} 
          onClick={() => handleReorderBlocks(index, "down")}
        >
          <ArrowDownIcon height="15px" />
        </button>
      </div>
      <button type="button" onClick={(event) => removeBlock(index, event)} className="flex flex-nowrap whitespace-nowrap xl:gap-x-2 items-center justify-center px-4 py-1 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-xl hover:bg-gray-400 hover:text-white focus:z-10 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
        <span>
          <DeleteIconDark dimension={16}/>
        </span>
        <div className="xs:invisible xs:max-w-0 xl:visible xl:min-w-[80px]">Delete Block</div>
      </button>
    </div>
  )
}