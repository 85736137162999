import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { DropdownIcon } from '../assets/icon-file'

function StatusDropdown({status,setFilterData,selected, setSelected}) {
    
    useEffect(() => {
        setFilterData(prev => ({ ...prev, status: selected }));
    }, [selected]);
  return (
  <div className='w-[175px] bg-whiteGrey h-14 rounded-lg'>
      <Listbox value={selected} onChange={setSelected}>
    <div className="relative">
      <Listbox.Button className="relative w-full px-3 cursor-pointer h-[57px] bg-transparent py-2 pr-10 text-left focus:outline-none sm:text-base sm:leading-normal font-semibold">
        <span className="block truncate">{selected?.name}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <DropdownIcon
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute z-10 top-[60px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {status.map((status, roleIdx) => (
            <Listbox.Option
              key={roleIdx}
              className={({ active }) =>
                `relative cursor-default select-none py-2 px-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-gray-900'
                }`
              }
              disabled={status.disabled}
              value={status}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`block truncate ${selected ? 'font-semibold' : 'font-semibold'
                      }`}
                  >
                    {status.name}
                  </span>
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  </Listbox>
  </div>
  )
}

export default StatusDropdown