import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import icon from '../../assets/otp-icon.svg'
import OtpInput from "react-otp-input";
import { CodeVerify } from '../../service/service';
function OtpVerification({ setStep,emailData }) {
    const [otp, setOtp] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setloading] = useState(false);
 
    
    
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(emailData)
        const FormData = {
            code:otp,
            user_id:emailData?.id
        }
        setloading(true)
        CodeVerify(FormData)
            .then((result) => {

                if (result.status) {
                    setErrorMsg('')
                    // console.log(result.data);
                    setStep(2)
                    setloading(false)
                }
                else {
                    setErrorMsg(result.message)
                    setloading(false)
                }

            })
            .catch((error) => {
                console.log(error.message);
                setErrorMsg(error.message)
                setloading(false)
            });


    };
    return (
        <div className='w-full'>
            <div className='max-w-[611px] mx-auto px-2 xs:px-4 sm:px-14 space-y-4'>
                <div>
                    <div className='w-fit mx-auto p-4 bg-[#E1E9F2] rounded-full'>
                        <img src={icon} alt="" />
                    </div>
                </div>
                <div className='text-2xl font-bold leading-[28px] text-center'>
                    OTP verification
                </div>
                <div className='text-primaryDark text-lg font-normal leading-[28px] text-center'>
                    Please enter the OTP code from your email to verify your account
                </div>
                <form className="space-y-[14px]" onSubmit={handleSubmit}>
                    <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span style={{ width: "16px" }}></span>}
                                renderInput={(props) => <input {...props} 
                                inputMode='numeric'
                                // onKeyDown={(e) => {
                                //     // Allow numeric keys and some control keys (e.g., arrow keys, backspace)
                                //     if (!(e.key >= '0' && e.key <= '9') && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                //       e.preventDefault();
                                //     }
                                //   }}
                                />}
                                isInputNum={true}
                                placeholder='------'
                                inputStyle={{
                                  
                                    borderRadius: "8px",
                                    width: "75px",
                                    height: "75px",
                                    fontSize: "40px",
                                    color: "#000",
                                    fontWeight: "400",
                                    
                                    background:"rgb(210, 214, 220,0.4)",
                                    
                                  }}
                            />
                    {errorMsg !== '' && <p className="text-red-500 px-3">{errorMsg}</p>}
                    <div className='text-primaryDark text-lg font-normal leading-[28px] text-center !mt-2'>
                        <span className='text-[#515F7B] mr-1'>
                            Haven’t received the email yet?
                        </span>
                        <Link
                            to=""
                            className="text-primaryBlue">
                            Send again
                        </Link>
                    </div>
                    {/* Submit button */}
                    <div className='pt-[10px]'>
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Verify
                        </button>
                    </div>
                </form>
                <hr className='h-[1px] bg-[#D2D6DC]' />
                <div className='text-center text-lg font-semibold'>
                    <span className='text-[#515F7B] mr-1'>
                        Remember password?
                    </span>
                    <Link
                        to="/admin"
                        className="text-primaryBlue">
                        Login
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default OtpVerification