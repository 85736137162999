// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: {
    userData: null, // User data
    userPermissions: null, // User permissions
  },

  reducers: {
    setAdminUser: (state, action) => {
        return {
          ...state,
          userData: action.payload.userData,
          userPermissions: action.payload.userPermissions,
        };
      },
    logoutAdminUser:(state)=>{
        return {
            userData: null, // User data
            userPermissions: null, // User permissions
          }
    },
    updateAdminUser: (state, action) => {
      const dynamicKey = Object.keys(action.payload)[0]; // Get the dynamic key
      return {
        ...state,
        [dynamicKey]: action.payload[dynamicKey] // Use computed property name to set the dynamic key
      };
    },

    passMultiFactor : (state , action)=>{
      state.userData.multifactor=true;

    }
    // updateSeriesForm:(state,action)=>{
    //   const dynamicKey = Object.keys(action.payload)[0]; // Get the dynamic key
    //   return {
    //     ...state,
    //      [dynamicKey]: {...action.payload[dynamicKey],[Object.keys(action.payload[dynamicKey])[0]]:[Object.keys(action.payload[dynamicKey])[1]]} // Use computed property name to set the dynamic key
    //   };
    // }
  },
});

export const { setAdminUser,logoutAdminUser,updateAdminUser,passMultiFactor } = adminUserSlice.actions;
export default adminUserSlice.reducer;
