import { Dialog, Transition, Combobox, } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { UserSearch } from '../../service/service';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';



export default function SearchModal({ searchOpen, setSearchOpen }) {
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [classes, setClassess] = useState([]);
    const [series, setSeries] = useState([]);
    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState({})

    function closeModal() {
        setSearchOpen(false)
        setSelected({})
    }
    // let timeoutId;

    const makeAPICall = (searchValue) => {

        const data = { search: searchValue };

        UserSearch(data)
            .then((result) => {
                setloading(false)
                // console.log(result.data)
                setClassess(result.data?.classes[0] ?? []);
                setSeries(result.data?.series[0] ?? []);
            });
    };


    const handleChange = (event) => {
        const searchValue = event.target.value;
        setQuery(searchValue)
        if (searchValue) {
            setloading(true)
        }

        // clearTimeout(timeoutId);

        // timeoutId = setTimeout(() => {
        //     if (searchValue !== '') {
        //         makeAPICall(searchValue); // Make the API call after the delay
        //     }
        // }, 1000);
    };
    useEffect(() => {
        const getData = setTimeout(() => {
            if (query !== '') {
                makeAPICall(query); // Make the API call after the delay
            }
            if(query===''){
                setloading(false)
            }
        }, 1000)

        return () => clearTimeout(getData)
    }, [query])


    const handleDropdown = (data) => {
       
        setSelected(data)
        if ('block' in data) {
            closeModal()
            navigate(`/${data.custom_permalink?? data.slug}`, { state: { data } });
        }
        else{
            closeModal()
            navigate(`/series/${data.perma_link ?? data.slug}`, { state: { data } });
        }
        // console.log(data)
    }
    const viewAll = (param) => {
        // console.log(param)
        const data = {
            type: param,
            query,
            classes,
            series
        }
        // console.log('Data before navigation:', data);
        closeModal()
        navigate('/view-all', { state: { data } });
    }
    return (
        <>
            <Transition appear show={searchOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[600px] transform rounded-lg bg-whiteGrey  text-left align-middle shadow-xl transition-all">

                                    <div className=' rounded-lg md:bg-opacity-[0.4] py-1'>

                                        <Combobox

                                            as='div'
                                            value={selected}
                                            onChange={handleDropdown}
                                        >
                                            <div className="flex items-center gap-x-2 w-full px-4 py-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                                </svg>
                                                <Combobox.Input
                                                    onChange={handleChange}
                                                    displayValue={(person) => person.title}
                                                    // onChange={(event) => setQuery(event.target.value)}
                                                    placeholder='search...'
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>

                                            <Combobox.Options className={`py-2 px-4 border-t-[1px] border-[#D2D6DC]`}>
                                                {
                                                    loading ?
                                                        <div className='flex justify-center'>
                                                            <ColorRing
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                ariaLabel="blocks-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass="blocks-wrapper"
                                                                colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                                                            />
                                                        </div>

                                                        :
                                                        <>
                                                            {
                                                                classes.length === 0 && series.length === 0 ?
                                                                    <div className='flex justify-center h-[50vh]'>
                                                                        No data found
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            classes.length > 0 &&
                                                                            <>
                                                                                <div className='text-sm font-bold pl-4 text-primaryDark'>
                                                                                    Classes
                                                                                </div>
                                                                                {
                                                                                    classes?.slice(0, 3)?.map((item) => (
                                                                                        <Combobox.Option
                                                                                            key={item.id}
                                                                                            className={({ active }) =>
                                                                                                `relative cursor-default select-none py-2 pl-4 pr-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-primaryDark'
                                                                                                }`
                                                                                            }
                                                                                            value={item}
                                                                                        >
                                                                                            {item.title}

                                                                                        </Combobox.Option>
                                                                                    ))
                                                                                }
                                                                                <div onClick={() => viewAll('class')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                                                                                <hr className='my-4 bg-[#D2D6DC] h-[1px]' />
                                                                            </>
                                                                        }
                                                                        {
                                                                            series.length > 0 &&
                                                                            <>
                                                                                <div className='text-sm font-bold pl-4 text-primaryDark'>
                                                                                    Series
                                                                                </div>
                                                                                {
                                                                                    series?.slice(0, 3)?.map((item) => (
                                                                                        <Combobox.Option
                                                                                            key={item.id}
                                                                                            className={({ active }) =>
                                                                                                `relative cursor-default select-none py-2 pl-4 pr-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-primaryDark'
                                                                                                }`
                                                                                            }
                                                                                            value={item}
                                                                                        >
                                                                                            {item.title}

                                                                                        </Combobox.Option>
                                                                                    ))
                                                                                }
                                                                                <div onClick={() => viewAll('series')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                                                                            </>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }

                                            </Combobox.Options>
                                            <div className="mt-4 flex justify-center gap-x-10 mb-6 items-center">
                                                <button
                                                    type="button"
                                                    className="w-[146px] md:w-[240px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                                                    onClick={closeModal}
                                                >
                                                    Cancel
                                                </button>

                                                <button
                                                    type="button"
                                                    // disabled={loading}
                                                    onClick={() => viewAll('all')}
                                                    className="w-[146px] md:w-[240px] h-[45px] py-2 text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </Combobox>


                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
