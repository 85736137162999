import React, { useEffect, useState } from 'react'
import deleteIcon from '../../assets/delete-icon.svg'
import editIcon from '../../assets/edit-icon.svg'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import { Tooltip } from "react-tooltip";
import { DeleteAdminCategory } from '../../service/service';
function CategoriesTable(
    {
        responseData, EditCategory, setIsLoading,
        totalPages, currentPage, setCurrentPage ,handlePageChange, noData,
        userPermissions,showCategoriesList,headCells
    }
) {


    const [items, setItems] = useState(responseData);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        setItems(responseData)
    }, [responseData])
    // Handle individual checkbox change
    const handleCheckboxChange = (itemId) => {
        const updatedItems = items.map((item) =>
            item.id === itemId ? { ...item, isChecked: !item.isChecked } : item
        );
        setItems(updatedItems);
        setSelectAll(updatedItems.every((item) => item.isChecked));
    };

    // Handle "Select All" checkbox change
    const handleSelectAllChange = () => {
        const updatedSelectAll = !selectAll;
        setSelectAll(updatedSelectAll);
        const updatedItems = items.map((item) => ({ ...item, isChecked: updatedSelectAll }));
        setItems(updatedItems);
    };
    const deleteCategory = (id) => {
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoading(true);
                const formData = {
                    id,
                }
                DeleteAdminCategory(formData)
                    .then((result) => {
                        setIsLoading(false)
                        if (result.status) {
                            // const updatedItems = responseData.filter(item => item.id !== id);
                            // setItems(updatedItems);
                            if(items.length<=1){
                                const newCount =currentPage-1;
                                showCategoriesList(result.message ,newCount)
                                setCurrentPage(newCount)
                                // handlePageChange({selected:newCount-1})
                            }
                            else{
                                showCategoriesList(result.message)
                            }
                          
                            
                        }
                        else {
                            Swal.fire({
                                title: 'Error!',
                                text: result.message,
                                icon: 'error',
                                timer: 3000,
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                    .catch((error) => {
                        setIsLoading(false)
                        console.log(error.message);
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            timer: 3000,
                            confirmButtonText: 'OK'
                        })
                    });

            }
        })

    }

    return (
        <div className='px-10 py-8'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-whiteGrey">
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xl h-[70px] text-whiteGrey font-bold leading-[21px] bg-primaryDark ">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                <div className="flex items-center justify-center">
                                    <input id="default-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="w-5 h-5 cursor-pointer" />
                                </div>
                            </th>
                            {headCells?.map((item) => (
                                item.isShow &&
                                <th key={item.id} scope="col" className="px-6 py-3">
                                    {item.id}
                                </th>
                            ))}
                            <th scope="col" className="px-6 py-3">
                                Actions
                            </th>

                        </tr>
                    </thead>
                    <tbody className='text-base font-semibold leading-[21px] text-primaryDark'>
                        {
                            items?.map((obj) => (
                                <tr key={obj.id} className="bg-whiteGrey h-[69px] border-b hover:bg-gray-100">
                                    <td className="px-6 py-4">
                                        <div className="flex items-center justify-center">
                                            <input id="default-checkbox"
                                                type="checkbox"
                                                checked={obj.isChecked}
                                                onChange={() => handleCheckboxChange(obj.id)}
                                                className="w-5 h-5 cursor-pointer" />
                                        </div>
                                    </td>
                                   {
                                    headCells[0].isShow &&
                                   <td className="px-6 py-4  whitespace-nowrap truncate max-w-[180px]">
                                        {obj.name}
                                    </td>
                                    }
                                   {
                                    headCells[1].isShow &&
                                   <td className="px-6 py-4  whitespace-nowrap truncate max-w-[180px]">
                                        {obj.slug}
                                    </td>
                                    }
                                   {
                                    headCells[2].isShow &&
                                   <td className="px-6 py-4 tooltip-parent ">
                                        <div
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={obj.description}
                                            className='whitespace-nowrap truncate max-w-[270px] cursor-pointer'>
                                            {obj.description}
                                        </div>

                                    </td>
                                    }
                                    <td className="px-6 py-4  whitespace-nowrap truncate max-w-[180px]">
                                        <div className='flex gap-x-4'>
                                            {
                                                userPermissions?.includes('edit_category') &&
                                                <span
                                                    onClick={() => EditCategory(obj)}
                                                    className='cursor-pointer'>
                                                    <img src={editIcon} className='max-w-[unset]' alt="Edit" />
                                                </span>
                                            }
                                            {
                                                userPermissions?.includes('delete_category') &&
                                                <span
                                                    onClick={() => deleteCategory(obj.id)}
                                                    className='cursor-pointer'>
                                                    <img src={deleteIcon} className='max-w-[unset]' alt="Delete" />
                                                </span>
                                            }
                                        </div>
                                    </td>

                                </tr>

                            ))
                        }
                    </tbody>

                </table>
                <Tooltip
                    id="my-tooltip"
                    place="bottom-start"
                    style={{ backgroundColor: "#161E2E", color: "#F9F9F9", maxWidth: '231px' }}
                />
                {
                    responseData?.length === 0 &&

                    <div className='flex h-[80px] items-center justify-center text-lg font-bold'>
                        {noData}
                    </div>
                }
                <div className='pagination w-full bg-whiteGrey px-6'>
                    <ReactPaginate
                        className='flex justify-end items-center gap-x-3 h-[80px]'
                        pageCount={totalPages}
                        pageRangeDisplayed={5} // Adjust as needed
                        marginPagesDisplayed={2} // Adjust as needed
                        initialPage={currentPage}
                        previousLabel={
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z" fill="#161E2E" />
                            </svg>
                        }
                        nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.94801 19.9968C9.66304 19.6829 9.6887 19.1993 10.0053 18.9168L16.0756 13.5L10.0053 8.08324C9.6887 7.80069 9.66303 7.31712 9.94801 7.00317C10.233 6.68923 10.7207 6.66378 11.0373 6.94633L17.7447 12.9315C17.9072 13.0766 18 13.2832 18 13.5C18 13.7168 17.9072 13.9234 17.7447 14.0685L11.0373 20.0537C10.7207 20.3362 10.233 20.3108 9.94801 19.9968Z" fill="#161E2E" />
                        </svg>}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default CategoriesTable