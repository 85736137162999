import React, { useEffect, useState } from 'react'
import DashboardSideNav from '../../adminComponents/DashboardComponents/DashboardSideNav'
import DashboardHeader from '../../adminComponents/DashboardComponents/DashboardHeader';
import { ThreeCircles } from 'react-loader-spinner'
import RoleTable from '../../adminComponents/RoleManagement/RoleTable'
import RoleModal from '../../adminComponents/RoleManagement/RoleModal'
import { AdminHeaderSearch, AdminListRole, AdvanceTableSearch, PermissionDropdown } from '../../service/service'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import { FilterIcon } from '../../assets/icon-file';
import { MultiSelect } from "react-multi-select-component";
function RoleManagement() {
    const userPermissions = useSelector((state) => state.adminUser.userPermissions)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [responseData, setResponseData] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [noData, setNoData] = useState('')
    const [DropdownOptions, setDropdownOptions] = useState([])
    const [array, setarray] = useState();
    const [formData, setFormData] = useState({
        name: '',
        permission: []
    });
    const [formErrors, setFormErrors] = useState({
        fullName: '',
        permission: ''
    });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [filterData, setFilterData] = useState({
        name: '',
        role_permissions: []
    })
    const [headCells, setHeadCells] = useState([
        {
            id: 'Name',
            isShow: true
        },
        {
            id: 'Permissions',
            isShow: true
        },
    ])
    function handlePageChange(selectedPage) {

        setCurrentPage(selectedPage.selected);
    }
    const itemsPerPage = 10; // Number of items per page
    useEffect(() => {
        userPermissions?.includes('edit_role') &&
            PermissionDropdown().then((response) => {
                if (response.status) {
                    const permissionsDropdownOptions = response.data?.map((obj) => ({ label: obj.name, value: obj.id }))
                    setDropdownOptions(permissionsDropdownOptions)
                }
            })
    }, [])
    useEffect(() => {
        if(isSearching){
            HandleTableSearch()
          }
          else{

              showRoleList()
          }
    }, [currentPage, itemsPerPage])
    const showRoleList = () => {
        setIsLoading(true);
        AdminListRole(currentPage, itemsPerPage)
            .then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setResponseData(result.data.data)
                    setarray(result.data.data?.map((obj) => { return { id: obj.id, length: 3 } }))
                    if (result?.data.data?.length === 0) {
                        setNoData('No data to show')
                    }
                    const totalPages = Math.ceil(result.data.total / itemsPerPage);
                    setTotalPages(totalPages)
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.message);
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            });
    }

    const CreateRole = () => {
        setFormData(
            {
                name: '',
                permission: []
            }
        )
        setFormErrors({
            fullName: '',
            permission: ''
        })
        setIsOpen(true)
        setIsEdit(false)

    }
    const EditRole = (data) => {
        // console.log(data)
        setFormErrors({
            fullName: '',
            permission: ''
        })
        setIsOpen(true)
        setIsEdit(true)
        setFormData({
            name: data.name,
            id: data.id,
            permission: data.permissions ? data.permissions.map((item) => ({ label: item.name, value: item.id })) : []
        })

    }
    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        const index = parseInt(name.split('-')[1]); // Extracting the index from the name

        // Update the headCells state based on the checkbox change
        setHeadCells((prevHeadCells) =>
            prevHeadCells.map((item, idx) =>
                idx === index ? { ...item, isShow: checked } : item
            )
        );
    };
    const buttonElement =
        
        <div className='flex gap-x-4'>
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button
                    className="flex gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-normal leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                    <span aria-hidden="true" className='text-[13px]'>
                        <FilterIcon />
                    </span>
                    Filter

                </Menu.Button>
                <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-2 py-1 space-y-1">
                        {headCells?.map((item, index) => (
                            <div key={item.id} className='flex gap-2'>
                                <input onChange={handleCheckbox} name={`item-${index}`} type="checkbox" checked={item.isShow} id={`item-${index}`} />
                                <label htmlFor={`item-${index}`}>{item.id}</label>
                            </div>
                        ))}
                    </div>
                </Menu.Items>
            </Menu>
            {
                userPermissions?.includes('add_role') &&
            <button
                type="button"
                onClick={CreateRole}
                className="flex gap-x-2 w-[137px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
                <span aria-hidden="true" className='text-[13px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <g clipPath="url(#clip0_966_2238)">
                            <path d="M10.9453 6.44531H7.05469V2.55469C7.05469 1.9722 6.58249 1.5 6 1.5C5.41751 1.5 4.94531 1.9722 4.94531 2.55469V6.44531H1.05469C0.472195 6.44531 0 6.91751 0 7.5C0 8.08249 0.472195 8.55469 1.05469 8.55469H4.94531V12.4453C4.94531 13.0278 5.41751 13.5 6 13.5C6.58249 13.5 7.05469 13.0278 7.05469 12.4453V8.55469H10.9453C11.5278 8.55469 12 8.08249 12 7.5C12 6.91751 11.5278 6.44531 10.9453 6.44531Z" fill="#F9F9F9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_966_2238">
                                <rect width="12" height="13" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                Add Role
            </button>
            }

        </div>;

    const [searchTerm, setSearchTerm] = useState('');
    const performSearch = (searchTerm) => {

        AdminHeaderSearch('search-role', searchTerm, currentPage, itemsPerPage)
            .then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setResponseData(result.data.data)
                    setarray(result.data.data?.map((obj) => { return { id: obj.id, length: 3 } }))
                    if (result?.data.data?.length === 0) {
                        setNoData('No data to show')
                    }
                    const totalPages = Math.ceil(result.data.total / itemsPerPage);
                    setTotalPages(totalPages)
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }

            })

    };
    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchTerm !== '') {
                performSearch(searchTerm);
            }
            if (searchTerm === '') {
                // setIsLoading(false)
                showRoleList()
            }
        }, 1000)

        return () => clearTimeout(getData)
    }, [searchTerm])
    const inputElement = <div className='hidden lg:flex justify-between items-center border-[1px] border-[##D2D6DC] rounded-3xl pl-5 pr-2 w-[272px] h-[44px]'>
        <input value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='bg-transparent outline-none' type="text" placeholder='Search' />
        <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
            <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
            </svg>
        </div>
    </div>
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const HandleTableSearch = () => {
        const permissionNames = filterData.role_permissions.map(item => item.label);
        const updatedData = {
            ...filterData,
            per_page: itemsPerPage,
            role_permissions: permissionNames

        };
        setIsSearching(true)
        AdvanceTableSearch('advance-search-role', updatedData, isSearching?currentPage :0)
            .then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setResponseData(result.data.data)
                    if (result?.data.data?.length === 0) {
                        setNoData('No data to show')
                    }
                    const totalPages = Math.ceil(result.data.total / itemsPerPage);
                    setTotalPages(totalPages)

                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }

            })

    }
    const ResetAdvanceSearch = () => {

        setFilterData({
            name: '',
            role_permissions: []

        })
        setIsSearching(false)
        showRoleList()
    }
    return (
        <>
            {
                isLoading &&
                <div className="overlay">
                    <div className="loaderImage-container">
                        <ThreeCircles
                            height="100"
                            width="100"
                            color="#F9F9F9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    </div>
                </div>
            }

            <div className='bg-whiteGrey'>
                <DashboardSideNav />
                <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                    <DashboardHeader title={'Users'} headerButton={buttonElement} subtitle={'Role Management'} Headerinput={inputElement} />
                    <div className='px-10 pt-8 flex flex-wrap gap-4 '>
                        <div className='flex flex-col gap-4 flex-1 text-base font-semibold'>
                            <label>
                                Name
                            </label>
                            <input
                                onChange={handleChange}
                                value={filterData.name}
                                className='bg-whiteGrey h-14 rounded-lg outline-none p-3' placeholder='Role Name' type="text" name="name" />
                        </div>
                        <div className='flex flex-col gap-4 flex-1 text-base font-semibold filterMultiSelect max-w-[50%]'>
                            <label>
                                Permissions
                            </label>
                            <MultiSelect
                                options={DropdownOptions}
                                value={filterData?.role_permissions}
                                onChange={(selectedItems) => setFilterData({ ...filterData, role_permissions: selectedItems })}
                                labelledBy="Select"
                            />
                        </div>

                        <div className='flex items-end pb-[6px]'>
                            <button
                                type="button"
                                onClick={HandleTableSearch}
                                className="flex gap-x-2 w-[137px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                                Search
                                <span aria-hidden="true" className='text-[13px]'>
                                    <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className='flex items-end pb-[6px]'>
                            <button
                                type="button"
                                onClick={ResetAdvanceSearch}
                                className="flex gap-x-2 px-8 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-normal leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                    <RoleTable responseData={responseData} setResponseData={setResponseData} EditRole={EditRole}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        noData={noData}
                        setIsLoading={setIsLoading}
                        setTotalPages={setTotalPages}
                        showRoleList={showRoleList}
                        array={array}
                        setarray={setarray}
                        userPermissions={userPermissions}
                        headCells={headCells}
                    />
                </main>
            </div>
            <RoleModal isOpen={isOpen} setIsOpen={setIsOpen} setResponseData={setResponseData} formData={formData} setFormData={setFormData} isEdit={isEdit}
                DropdownOptions={DropdownOptions}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setTotalPages={setTotalPages}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    )
}

export default RoleManagement