import { useState, useEffect } from "react";
import FloatingControls from './FloatingControls';
import { ReplaceIcon } from "../../assets/icon-file";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API;

export default function BlockSelectFile({
  data,
  fileType, 
  index, 
  inputType,
  openMedia,
  removeBlock,
  blocks,
  setBlocks,
  selectedBlockTitle,
  handleReorderBlocks,
  videoPoster = false
}) {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [urlInput, setURLInput] = useState(false);
  const [urlInputData, setURLInputData] = useState("");
  const [docOverlay, setDocOverlay] = useState(true);
  const fileUploaded = selectedFile || (!urlInput && urlInputData !== "");
  const blockTitle = selectedBlockTitle?.split(" ")[0];
  const fileTypeCapitalized = fileType.charAt(0).toUpperCase() + fileType.slice(1);

  useEffect(() => {
    setURLInputData(data ?? "");
  }, [data]);

  const uploadEntireFile = async (file) => {
    const formData = new FormData();
    formData.append("file_name", file.name);
    formData.append("file_type", file.type);
    formData.append("extension", file.name.split(".").pop().toLowerCase());
    formData.append("file", file);
  
    return await axios.post(baseUrl + "/user/singleUpload", formData);
  };
  
  const initiateMultipartUpload = async (file) => {
    const response = await axios.post(baseUrl + "/user/initiateMultipartUpload", {
      file_name: file.name,
      file_type: file.type, // MIME type
      extension: file.name.split(".").pop().toLowerCase(),
    });
  
    return [response.data.upload_id, response.data.key];
  };
  
  const uploadChunk = async (chunk, partNumber, uploadId, file, prefix) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("part_number", partNumber);
    formData.append("upload_id", uploadId);
    formData.append("type", file.type);
    formData.append("file_name", file.name);
    formData.append("prefix", prefix);
  
    return await axios.post(baseUrl + "/user/upload", formData);
  };
  
  const completeUpload = async (
    uploadId,
    filename,
    parts,
    type,
    file,
    prefix
  ) => {
    return await axios.post(baseUrl + "/user/completeMultipartUpload", {
      upload_id: uploadId,
      file_name: filename,
      parts,
      type,
      extension: file.name.split(".").pop().toLowerCase(),
      prefix: prefix,
    });
  };
  
  const handleFileUpload = async (file, setProgress) => {
    let chunkSize = 5 * 1024 * 1024; // Adjust the chunk size as needed
  
    // if its a small file upload it in its entirety
    if (file.size < chunkSize) {
      let res = await uploadEntireFile(file);
      //setProgress(100);
      return res;
    } else {
      // upload it as multi part
      const [id, prefix] = await initiateMultipartUpload(file);
  
      const totalSize = file.size;
      let start = 0;
      let partNumber = 1;
      const parts = [];
      let chunksUploaded = 0;
  
      while (start < totalSize) {
        let end = Math.min(start + chunkSize, totalSize);
        // Check if this is potentially the last chunk
        if (end === totalSize) {
          // Check if the last chunk is too small and there are previous chunks
          if (totalSize - start < chunkSize / 2 && parts.length > 0) {
            // Merge this chunk with the previous chunk
            start = parts.pop().start; // Remove the last part and update start to merge
            partNumber--;
            chunksUploaded--;
          }
          // After merging, or if no merging is required, define the final chunk
          end = totalSize;
        }
  
        if (start !== end) {
          // Ensure not to process zero-length chunks
          const chunk = file.slice(start, end);
          const response = await uploadChunk(chunk, partNumber, id, file, prefix);
          chunksUploaded++;
          const calculatedProgress = Math.round(
            (chunksUploaded / Math.ceil(totalSize / chunkSize)) * 100
          );
          setProgress(calculatedProgress);
          parts.push({
            PartNumber: partNumber,
            ETag: response.data.Etag,
            start: start,
          });
          partNumber++;
        }
        start = end; // Move the start up to the end for the next chunk
      }
  
      let res = await completeUpload(
        id,
        file.name,
        parts,
        file.type,
        file,
        prefix
      );
      return res;
    }
  };

  const handleBlockChange = ({ event, index, chunkedFile }) => {
    const value = chunkedFile || (event.target.type === 'file' ? event.target.files[0] : event.target.value);
    setBlocks(prevData => {
      // Create a new array by mapping over the previous state
      return prevData.map((item, i) => {
        if (item.block === "Video" && videoPoster) {
          return { ...item, metaData: { videoPoster: value } }
        }
        if (i === index) {
          // Update the specific object in the array
          return { ...item, value: value, isFileUploaded: true };
        } 
        return item;
      });
    });
};

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Please select a file.");
      return;
    } else {
      setProgress(progress + 1);
      let res = await handleFileUpload(file, setProgress);
      setURLInputData(res?.data?.data);
      setURLInput(false);
      handleBlockChange({ event, index, chunkedFile: res?.data?.data });
    }
  };

  const handleChange = (event) => {
    setURLInputData(event.target.value);
    handleBlockChange({ event, index });
  };

  const replaceBlock = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setURLInputData("");
    setProgress(0);
    setBlocks(prevData => {
      // Create a new array by mapping over the previous state
      return prevData.map((item) => {
        if (videoPoster && item.block === "Video") {
          return { ...item, metaData: { videoPoster: "" } }
        } 
        return fileType === item.block ? { ...item, value: "", isFileUploaded: false } : item
      });
    });
  }

  const submitURL = () => {
    setURLInput(false);
  };

  const handleDocOverlay = (status) => {
    setDocOverlay(status)
  }

  return (
    <div className="relative">
      {
        (blockTitle === fileTypeCapitalized || (blocks && !fileUploaded)) &&
        <FloatingControls 
          type={fileType}
          blockType="select file" 
          blocks={blocks}
          replaceBlock={replaceBlock} 
          removeBlock={removeBlock} 
          index={index}
          handleReorderBlocks={handleReorderBlocks}
          fileUploaded={fileUploaded}   
        />
      }
     {fileUploaded ? (
        <div className="relative">
          {fileType === "Video" ? (
            urlInputData !== "" ? (
              <video
                onClick={(event) => event.preventDefault()}
                className="rounded-lg w-full max-h-[400px] bg-whiteGrey"
                controls
              >
                <source src={urlInputData} type="video/mp4" />
              </video>
            ) : (
              <>
                <video
                  onClick={(event) => event.preventDefault()}
                  className="rounded-lg w-full max-h-[400px] bg-whiteGrey"
                  controls
                >
                  <source
                    src={URL.createObjectURL(selectedFile)}
                    type={selectedFile.type}
                  />
                </video>
              </>
            )
          ) : fileType === "Images" ? (
            urlInputData !== "" ? (
              <img
                src={urlInputData}
                alt="Preview"
                className="rounded-lg w-full h-auto max-h-[400px] object-contain bg-whiteGrey"
              />
            ) : (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Preview"
                className="rounded-lg w-full h-auto max-h-[400px] object-contain bg-whiteGrey"
              />
            )
          ) : fileType === "Audio" ? (
            urlInputData !== "" ? (
              <div className="bg-whiteGrey rounded-xl min-h-[146px] p-4 flex items-center">
                <audio controls className="w-full">
                  <source src={urlInputData} type="audio/ogg" />
                  <source src={urlInputData} type="audio/mpeg" />
                  Your browser does not support the audio tag.
                </audio>
              </div>
            ) : (
              <div className="bg-whiteGrey min-h-[146px] flex items-center">
                <audio controls className="w-full">
                  <source
                    src={URL.createObjectURL(selectedFile)}
                    type={selectedFile.type}
                  />
                  Your browser does not support the audio tag.
                </audio>
              </div>
            ) 
          ) : (
            // Document
            urlInputData !== "" ? (
              <>
                <div 
                  className="font-semibold break-all showDoc" 
                  onClick={() => handleDocOverlay(false)}
                  onMouseLeave={() => handleDocOverlay(true)}
                >
                  {/* Transparent clickable overlay so the mouse can interact with the area of the document. Without overlay the document prevents interaction. */}
                  {
                    docOverlay && 
                    <div className="absolute top-0 left-0 w-full h-full z-10 bg-transparent pointer-events-auto"></div>
                  }
                  <embed
                    src={urlInputData}
                    className="max-[1100px]:h-[150px] max-[1200px]:h-[200px] max-[1300px]:h-[250px] max-[1400px]:h-[300px] max-[1500px]:h-[350px] h-[400px] w-full rounded-lg "
                    type="application/pdf"
                    title="Embedded Content" // Provides a title for the embed element improving accessibility
                  />
                </div>
              </>
            ) : (
              <>
                <DocumentTextIcon className="h-16 w-16" />
                <div className="font-semibold max-w-[95%] break-all">
                  {selectedFile.name}
                </div>
              </>
            )
          )}
        </div>
      ) : (
        <div className="min-h-[114px]">
          <div className={`${ videoPoster ? '' : 'flex w-full justify-between h-5 mb-4'}`}>
            <div className="block text-xl font-extrabold leading-5 text-primaryDark">
              { 
                videoPoster ? "" :
                fileType === "Video" ? "Video" : 
                fileType === "Images" ? "Image" : 
                fileType === "Audio" ? "Audio" : 
                "Document"
              }
            </div>
          </div>
          {progress > 0 ? (
            <div style={{ width: "100%", marginTop: "20px" }}>
              <progress
                className="w-full"
                value={progress}
                max="100"
              ></progress>
              <p>{`${progress}%`}</p>
            </div>
            ) : (
              <>
                <div className={`text-sm ${ videoPoster ? "font-normal" : "font-semibold"}`}>
                  {`Upload ${ 
                    fileType === "Video" ? "a video" : 
                    fileType === "Images" ? "an image" : 
                    fileType === "Audio" ? "an audio" : 
                    fileType === "Documents" ? "a document" : 
                    ""
                  } file${ videoPoster ? "" : ", pick one from your media library,"} or add
                  one with a URL.`}
                </div>
                <div
                  className="flex gap-3 mt-6"
                  onClick={(event) => event.stopPropagation()}
                >
                  <label
                    htmlFor={`fileInput-${index}`}
                    className="flex gap-x-2 px-5 h-[34px] items-center justify-center rounded-3xl border-[1px] border-primaryBlue bg-primaryBlue hover:bg-opacity-[0.9] text-sm  font-bold leading-[18px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    Upload
                  </label>
                  <input
                    type="file"
                    id={`fileInput-${index}`}
                    accept={inputType}
                    style={{ display: "none" }}
                    onChange={(event) => handleFileChange(event)}
                  />
                  <button
                    type="button"
                    onClick={() => setURLInput(true)}
                    className="flex gap-x-2 px-5 h-[34px] items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm  font-bold leading-[18px] text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    Insert from URL
                  </button>
                  {!videoPoster && (
                    <button
                      type="button"
                      onClick={() => openMedia(index, fileType)}
                      className="flex gap-x-2 px-5 h-[34px] items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm  font-bold leading-[18px] text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    >
                      Choose from Media
                    </button>
                  )}
                </div>
              </>
          )}
          {
            urlInput &&
            <div
              className={`flex w-[${
                videoPoster ? "w-full" : "430px"
              }] pt-4 gap-x-2 justify-between items-center`}
              onClick={(event) => event.stopPropagation()}
            >
              <div className={`h-[39px] w-full rounded-lg ${videoPoster ? 'bg-whiteGrey' : 'bg-[#D2D6DC] md:bg-opacity-[0.4]'} px-4`}>
                <input
                  id={`urlInput-${index}`}
                  name={`urlInput-${index}`}
                  type="text"
                  autoComplete="off"
                  placeholder="Enter url here"
                  value={urlInputData}
                  onChange={handleChange}
                  className="block h-full w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-sm sm:leading-normal font-semibold"
                />
              </div>
              <button
                className="p-3 bg-primaryBlue text-whiteGrey rounded-lg h-[39px]"
                onClick={submitURL}
                type="button"
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3.7929 14.293L10.0858 8.0001L3.7929 1.7072C3.60725 1.51927 3.50349 1.26552 3.50429 1.00136C3.50509 0.737196 3.61039 0.48408 3.79718 0.297288C3.98397 0.110495 4.23709 0.00520102 4.50125 0.00439914C4.76542 0.00359726 5.01917 0.107353 5.20709 0.293008L12.2071 7.29301C12.3946 7.48054 12.5 7.73489 12.5 8.0001C12.5 8.26531 12.3946 8.51966 12.2071 8.7072L5.20709 15.7072C5.01917 15.8929 4.76542 15.9966 4.50125 15.9958C4.23709 15.995 3.98397 15.8897 3.79718 15.7029C3.61039 15.5161 3.50509 15.263 3.50429 14.9988C3.50349 14.7347 3.60725 14.4809 3.7929 14.293Z"
                  fill="#F9F9F9"
                />
              </svg>
            </button>
            </div>
          }
        </div>
      )}
      {videoPoster && fileUploaded &&
        <div className="mt-3">
          <button
            type="button"
            onClick={(event) => replaceBlock(event)}
            className="mx-auto flex gap-x-2 px-5 h-[34px] items-center justify-center rounded-3xl border-[1px] border-primaryDark hover:bg-opacity-[0.9] text-xs  font-bold leading-[18px] text-primaryDark shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <span>
              <ReplaceIcon />
            </span>
            Replace
          </button>
        </div>
      }
    </div>
  )
}