// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import adminUserSlice from './slices/AdminUserSlice';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userDataSlice from './slices/UserDataSlice';

const rootReducer = combineReducers({
  adminUser: adminUserSlice, 
  userdata: userDataSlice ,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["adminUser","userdata"],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);



const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
