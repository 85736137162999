import React, { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
function SceduleModal({isOpen, setIsOpen,handleBlockChange,index,formData,setFormData,flag,setFlag}) {

   const handleChange=(e)=>{
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    handleBlockChange(e, index)
   } 
    function closeModal() {
        setIsOpen(false)
    }
    const handleSetTime=()=>{
        if(formData.end_date ==='' || formData.date===''){
            setFlag(true)
        }
        else{
            setFlag(false)
            setIsOpen(false)
        }
    }
  return (
    <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-[411px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            as="h3"
                            className="text-lg flex justify-between items-center font-medium leading-6 text-gray-900"
                        >
                            <div className='flex w-[90%] items-center justify-between'>
                                {/* Schedule time */}
                                {/* <button
                                    // onClick={() => handlePublishTime('now')}
                                    className="px-4 py-1 bg-transparent hover:bg-primaryBlue hover:bg-opacity-10 border-[1px] border-primaryBlue text-primaryBlue rounded-md transition duration-300"

                                >Now</button> */}
                            </div>
                            <span
                                onClick={closeModal}
                                className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </Dialog.Title>
                        <div className='pt-6 space-y-4'>
                            {/* <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <label htmlFor="time" className="block text-sm font-normal leading-5 text-primaryDark">
                                    Time
                                </label>
                                <div className="">
                                    <input
                                        id="time"
                                        name="time"
                                        type="time"
                                        autoComplete="off"
                                        required
                                        value={formData.time}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formData.time ==='' && flag && <p className="text-red-500 px-3 !mt-1">This field is required</p>} */}
                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <label htmlFor="time" className="block text-sm font-normal leading-5 text-primaryDark">
                                    Start Date
                                </label>
                                <div className="">
                                    <input
                                        id="date"
                                        name="date"
                                        type="date"
                                        autoComplete="off"
                                        required
                                        value={formData.date}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formData.date ==='' && flag && <p className="text-red-500 px-3 !mt-1">This field is required</p>}
                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <label htmlFor="end_date" className="block text-sm font-normal leading-5 text-primaryDark">
                                    End Date
                                </label>
                                <div className="">
                                    <input
                                        id="end_date"
                                        name="end_date"
                                        type="date"
                                        autoComplete="off"
                                        required
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formData.end_date ==='' && flag && <p className="text-red-500 px-3 !mt-1">This field is required</p>}
                            <div className="mt-4 flex justify-center gap-4 items-center">
                                <button
                                    type="button"   
                                    className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="button"
                                    // disabled={loading}
                                    onClick={handleSetTime}
                                    className="w-[146px] h-[45px] py-2 text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                                >
                                    Set Time
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </div>
        </div>
    </Dialog>
</Transition>
  )
}

export default SceduleModal