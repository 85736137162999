import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Banner from '../../components/banner/Banner'
import Section1 from '../../components/aboutUS/Section1'
import Section2 from '../../components/aboutUS/Section2'
import Section3 from '../../components/aboutUS/Section3'
import { Helmet } from 'react-helmet'

function AboutUs() {
  return (
   <>
     <Header/>
     <Banner title={'About Rabbi Paltiel and Inside Chassidus'}/>
     <Section1/>
     <Section2/>
     <Section3/>
     <Footer/>
   </>
  )
}

export default AboutUs