import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIcon } from '../assets/icon-file';

function DragableTable({ items, setItems ,loading }) {


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = [...items];
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        // console.log(reorderedItems)
        setItems(reorderedItems);
    };

    return (
        <div className="pb-8">
            <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-whiteGrey">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xl h-[70px] text-whiteGrey font-bold leading-[21px] bg-primaryDark">
                        <tr>
                            <th scope="col" className="px-6 py-3 w-3"></th>
                            <th scope="col" className="px-6 py-3 w-3">
                                Sr#
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Title
                            </th>
                            <th scope="col" className="px-6 py-3 w-32">
                                Type
                            </th>
                        </tr>
                    </thead>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="text-base font-semibold leading-[21px] text-primaryDark"
                                >

                                    {items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                            {(provided) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    //   {...provided.dragHandleProps}
                                                    className="bg-whiteGrey h-[69px] border-b hover:bg-gray-100"
                                                >
                                                    <td
                                                        {...provided.dragHandleProps}
                                                        className="px-6 py-4 cursor-move"
                                                    >
                                                        <DragIcon className="h-[20px]" />
                                                    </td>
                                                    <td className="px-6 py-4">{index}</td>
                                                    <td className="px-6 py-4">{item.title}</td>
                                                    <td className="px-6 py-4">{item.type}</td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>
                {
                    items.length === 0 &&
                    <div className="bg-whiteGrey border-b hover:bg-gray-100 w-full flex h-[80px] items-center justify-center text-lg font-bold">{loading? 'Loading...' :' No child exist'}</div>
                }
            </div>
        </div>
    );
}

export default DragableTable;
