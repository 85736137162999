import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { DeleteAdminMedia } from '../../service/service';
import Swal from 'sweetalert2';

function MediaConatiner({ DataArray, openModal, ListMediaItems, setIsLoading,noData }) {

    const [showSelction, setShowSelection] = useState(false);
    const [items, setItems] = useState([]);
    const [checkedItems, setcheckedItems] = useState([]);
    useEffect(() => {
        setItems(DataArray)
    }, [DataArray])

    const handleCheckbox = () => {

        setShowSelection(!showSelction);
    };
    const handleCheckboxChange = (itemId) => {
        const updatedItems = items.map((item) =>
            item.id === itemId ? { ...item, isChecked: !item.isChecked } : item
        );
        setItems(updatedItems);
        const checkedItemIds = updatedItems
            .filter((item) => item.isChecked)
            .map((item) => item.id);
        // console.log(checkedItemIds);
        setcheckedItems(checkedItemIds)

    };
    const DeleteMedia = () => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoading(true);
                const formData = {
                    id: checkedItems
                }
                DeleteAdminMedia(formData).then((result) => {
                    if (result.status) {
                        setcheckedItems([])
                        setShowSelection(false)
                        ListMediaItems('media deleted successfully')
                    }
                })
                    .catch((error) => {
                        setIsLoading(false)
                        setcheckedItems([])
                        setShowSelection(false)
                        console.log(error.message);
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            timer: 3000,
                            confirmButtonText: 'OK'
                        })
                    });

            }
        })
    }
    return (
        <div className='px-10 py-8'>
            {
                noData ?
                    <div className="flex h-[80px] items-center justify-center text-lg font-bold">No data to show</div>
                    :
                    <>
                        <div className='flex justify-between items-center text-primaryDark text-xl font-bold mb-4'>
                            <div>
                                Media:
                                <div className="flex items-center text-base font-normal leading-[normal] mt-2 gap-x-2 cursor-pointer">
                                    <input id="default-checkbox" type="checkbox"
                                        checked={showSelction}
                                        onChange={handleCheckbox}
                                        className="w-4 h-4 cursor-pointer" />
                                    <label htmlFor='default-checkbox' className='cursor-pointer'>Select</label>
                                </div>
                            </div>
                            {
                                showSelction && checkedItems.length > 0 &&
                                <button
                                    type="button"
                                    onClick={() => DeleteMedia()}
                                    className="flex mr-10 gap-x-2 px-5 h-[34px] items-center justify-center rounded-3xl hover:bg-[#E41D1D] border-[1px] text-[#E41D1D] border-[#E41D1D] hover:bg-opacity-[0.8] text-base  font-semibold leading-[18px] hover:text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                >
                                    Delete Permanently
                                </button>
                            }
                        </div>
                        <div className='flex flex-wrap gap-x-10 gap-y-7'>
                            {
                                items?.map((item, index) => (
                                    <React.Fragment key={`item-${index}`}>
                                        {
                                            showSelction ?
                                                <label
                                                    htmlFor={`default-checkbox-${index}`} key={`item-${index}`} className={`flex- flex-col w-[186px] rounded-lg relative ${showSelction ? 'border-[1.5px] border-primaryBlue cursor-pointer' : ''}`}>
                                                    {
                                                        showSelction &&
                                                        <input
                                                            checked={item.isChecked}
                                                            onChange={() => handleCheckboxChange(item.id)}
                                                            id={`default-checkbox-${index}`} type="checkbox" className="w-4 h-4 cursor-pointer absolute -right-2 -top-2" />
                                                    }
                                                    <div className='h-[140px] bg-[#E1E9F2] rounded-tr-lg rounded-tl-lg flex justify-center items-center'>
                                                        {
                                                            item.type === 'video' ?
                                                                <video className='h-full object-cover rounded-tr-lg rounded-tl-lg'>
                                                                    <source src={item?.url} type="video/mp4" />
                                                                </video>
                                                                :
                                                                item.type === 'image' ?
                                                                    <img src={item?.url} alt='Media' className='object-cover h-full w-full rounded-tr-lg rounded-tl-lg' />
                                                                    :
                                                                    item.type === 'audio' ?
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                                <path d="M25.496 20.1627V5.4959C25.495 5.29562 25.449 5.09813 25.3613 4.91808C25.2736 4.73803 25.1464 4.58006 24.9893 4.45589C24.8344 4.33058 24.6534 4.24159 24.4596 4.19544C24.2657 4.14929 24.064 4.14717 23.8693 4.18922L11.8693 6.85591C11.573 6.92272 11.3085 7.08865 11.1194 7.32626C10.9303 7.56387 10.8279 7.85891 10.8293 8.16259V19.2827C9.91906 18.853 8.89404 18.73 7.908 18.9322C6.92196 19.1344 6.02806 19.6508 5.3604 20.4041C4.69275 21.1574 4.28734 22.1068 4.20499 23.11C4.12265 24.1132 4.3678 25.1161 4.90369 25.9681C5.43957 26.8202 6.23728 27.4755 7.17715 27.8358C8.11702 28.1961 9.14838 28.2419 10.1165 27.9664C11.0846 27.6908 11.9373 27.1088 12.5466 26.3076C13.1559 25.5064 13.489 24.5293 13.496 23.5227C13.5097 23.2918 13.5097 23.0603 13.496 22.8294V9.22927L22.8293 7.16258V16.616C21.9185 16.186 20.8929 16.0631 19.9064 16.2658C18.9198 16.4684 18.0257 16.9857 17.3582 17.7398C16.6907 18.494 16.2858 19.4444 16.2045 20.4482C16.1232 21.4521 16.3697 22.4552 16.9071 23.307C17.4445 24.1588 18.2438 24.8132 19.1848 25.172C20.1259 25.5308 21.1579 25.5747 22.126 25.2969C23.0941 25.0191 23.9459 24.4348 24.5536 23.6316C25.1612 22.8285 25.4919 21.8498 25.496 20.8427C25.5086 20.6162 25.5086 20.3892 25.496 20.1627Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                                        :
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M15.4688 7.03125C14.5641 7.03125 13.8281 6.29527 13.8281 5.39062V0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V7.03125H15.4688ZM6.70312 16.875H10.1119C10.5002 16.875 10.815 17.1898 10.815 17.5781C10.815 17.9664 10.5002 18.2812 10.1119 18.2812H6.70312C6.31481 18.2812 6 17.9664 6 17.5781C6 17.1898 6.31481 16.875 6.70312 16.875ZM6 13.8281C6 13.4398 6.31481 13.125 6.70312 13.125H17.0156C17.4039 13.125 17.7188 13.4398 17.7188 13.8281C17.7188 14.2164 17.4039 14.5312 17.0156 14.5312H6.70312C6.31481 14.5312 6 14.2164 6 13.8281ZM17.0156 9.375C17.4039 9.375 17.7188 9.68981 17.7188 10.0781C17.7188 10.4664 17.4039 10.7812 17.0156 10.7812H6.70312C6.31481 10.7812 6 10.4664 6 10.0781C6 9.68981 6.31481 9.375 6.70312 9.375H17.0156Z" fill="#161E2E" />
                                                                                <path d="M15.2344 5.39101C15.2344 5.52024 15.3395 5.62538 15.4688 5.62538H20.8272C20.6986 5.38791 20.5331 5.1698 20.3343 4.9817L15.8145 0.705664C15.6391 0.539727 15.4437 0.401305 15.2344 0.291992V5.39101H15.2344Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='text-sm break-all text-center bg-transparent text-primaryDark p-1'>
                                                        {item.title ? item.title : item.name}
                                                    </div>

                                                </label>
                                                :
                                                <label
                                                    onClick={() => openModal(item)}
                                                    htmlFor={`default-checkbox-${index}`} key={`item-${index}`} className={`flex- flex-col w-[186px] rounded-lg relative ${showSelction ? 'border-[1.5px] border-primaryBlue cursor-pointer' : 'cursor-pointer'}`}>
                                                    {
                                                        showSelction &&
                                                        <input id={`default-checkbox-${index}`} type="checkbox" className="w-4 h-4 cursor-pointer absolute -right-2 -top-2" />
                                                    }
                                                    <div className='h-[140px] bg-[#E1E9F2] rounded-tr-lg rounded-tl-lg flex justify-center items-center'>
                                                        {
                                                            item.type === 'video' ?
                                                                <video className='h-full object-cover rounded-tr-lg rounded-tl-lg'>
                                                                    <source src={item?.url} type="video/mp4" />
                                                                </video>
                                                                :
                                                                item.type === 'image' ?
                                                                    <img src={item?.url} alt='Media' className='object-cover h-full w-full rounded-tr-lg rounded-tl-lg' />
                                                                    :
                                                                    item.type === 'audio' ?
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                                <path d="M25.496 20.1627V5.4959C25.495 5.29562 25.449 5.09813 25.3613 4.91808C25.2736 4.73803 25.1464 4.58006 24.9893 4.45589C24.8344 4.33058 24.6534 4.24159 24.4596 4.19544C24.2657 4.14929 24.064 4.14717 23.8693 4.18922L11.8693 6.85591C11.573 6.92272 11.3085 7.08865 11.1194 7.32626C10.9303 7.56387 10.8279 7.85891 10.8293 8.16259V19.2827C9.91906 18.853 8.89404 18.73 7.908 18.9322C6.92196 19.1344 6.02806 19.6508 5.3604 20.4041C4.69275 21.1574 4.28734 22.1068 4.20499 23.11C4.12265 24.1132 4.3678 25.1161 4.90369 25.9681C5.43957 26.8202 6.23728 27.4755 7.17715 27.8358C8.11702 28.1961 9.14838 28.2419 10.1165 27.9664C11.0846 27.6908 11.9373 27.1088 12.5466 26.3076C13.1559 25.5064 13.489 24.5293 13.496 23.5227C13.5097 23.2918 13.5097 23.0603 13.496 22.8294V9.22927L22.8293 7.16258V16.616C21.9185 16.186 20.8929 16.0631 19.9064 16.2658C18.9198 16.4684 18.0257 16.9857 17.3582 17.7398C16.6907 18.494 16.2858 19.4444 16.2045 20.4482C16.1232 21.4521 16.3697 22.4552 16.9071 23.307C17.4445 24.1588 18.2438 24.8132 19.1848 25.172C20.1259 25.5308 21.1579 25.5747 22.126 25.2969C23.0941 25.0191 23.9459 24.4348 24.5536 23.6316C25.1612 22.8285 25.4919 21.8498 25.496 20.8427C25.5086 20.6162 25.5086 20.3892 25.496 20.1627Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                                        :
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M15.4688 7.03125C14.5641 7.03125 13.8281 6.29527 13.8281 5.39062V0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V7.03125H15.4688ZM6.70312 16.875H10.1119C10.5002 16.875 10.815 17.1898 10.815 17.5781C10.815 17.9664 10.5002 18.2812 10.1119 18.2812H6.70312C6.31481 18.2812 6 17.9664 6 17.5781C6 17.1898 6.31481 16.875 6.70312 16.875ZM6 13.8281C6 13.4398 6.31481 13.125 6.70312 13.125H17.0156C17.4039 13.125 17.7188 13.4398 17.7188 13.8281C17.7188 14.2164 17.4039 14.5312 17.0156 14.5312H6.70312C6.31481 14.5312 6 14.2164 6 13.8281ZM17.0156 9.375C17.4039 9.375 17.7188 9.68981 17.7188 10.0781C17.7188 10.4664 17.4039 10.7812 17.0156 10.7812H6.70312C6.31481 10.7812 6 10.4664 6 10.0781C6 9.68981 6.31481 9.375 6.70312 9.375H17.0156Z" fill="#161E2E" />
                                                                                <path d="M15.2344 5.39101C15.2344 5.52024 15.3395 5.62538 15.4688 5.62538H20.8272C20.6986 5.38791 20.5331 5.1698 20.3343 4.9817L15.8145 0.705664C15.6391 0.539727 15.4437 0.401305 15.2344 0.291992V5.39101H15.2344Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='text-sm break-all text-center bg-transparent text-primaryDark p-1'>
                                                        {item.title ? item.title : item.name}
                                                    </div>

                                                </label>
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </div>

                    </>
            }

        </div>
    )
}

export default MediaConatiner