import React, { useEffect, useState } from "react";
import img from "../../assets/admin-login-new.png";
import logo from "../../assets/logo-dark.svg";
import { SubmitOTP, VerifytOTP, fetch2factor } from "../../service/service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logoutAdminUser, passMultiFactor } from "../../store/slices/AdminUserSlice";
import { useNavigate } from "react-router";
const TwoFactorLand = ({ children }) => {
  const [svg, setsvg] = useState("");
  const [key, setkey] = useState("");
  const [loading, setLoading] = useState(false)
  const [otpError, setOtpError] = useState('')
  const { mfa_enabled, multifactor } = useSelector((state) => {
    return state.adminUser.userData;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(mfa_enabled);
    if (mfa_enabled==0) {
      fetch2factor()
        .then((data) => {
          setsvg(data.qr_code);
          setkey(data["2fa_secret"]);
        })
        .catch(() => {});
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (/[^0-9]/.test(e.target.otp.value)) {
      console.log(e.target.otp.value);
 
      setOtpError('The OTP must be a number.')
    } else {
      
      setLoading(true)
      SubmitOTP({ otp: e.target.otp.value }, key)
        .then((data) => {
          setLoading(false)
          if (data.status) {
            // console.log("gasdfads");
            dispatch(passMultiFactor());
            setOtpError('')
          }
        })
        .catch((error) => {
          setLoading(false)
          setOtpError(error.message)
        });
    }
  }

  function handleSubmitCode(e) {
    e.preventDefault();
    if (/[^0-9]/.test(e.target.otp.value)) {
      console.log(e.target.otp.value);
 
      setOtpError('The OTP must be a number.')
    }
    else{

      setLoading(true)
      VerifytOTP({ otp: e.target.otp.value }, key)
        .then((result) => {
          setLoading(false)
          if (result.status) {
            dispatch(passMultiFactor());
            setOtpError('')
          }
        })
        .catch((error) => {
          setLoading(false)
          setOtpError(error.message)
        
        });
    }
  }

  if (multifactor) {
    return <>{children}</>;
  }
const BacktoLogin =()=>{
  localStorage.removeItem('adminToken');
  localStorage.removeItem('adminData');
  navigate("/admin");
  dispatch(logoutAdminUser())
}
  return (
    <div className="flex h-screen">
      <div className="w-1/2 hidden lg:block">
        <img
          src={img}
          alt="Login page"
          className="h-full w-full object-cover"
        />
      </div>

      <div className="w-full lg:w-1/2 overflow-y-auto">
        <div className="px-2 xs:px-4 sm:px-12 pt-[52px]">
          <div className="admin-logo-div w-fit mx-auto mb-[132px]">
            <img src={logo} alt="inside chassidus" />
          </div>

          {mfa_enabled == 0 ? (
            <div className="max-w-[611px] mx-auto px-2 xs:px-0 lg:px-14">
              <div className="text-2xl font-bold leading-[28px] mb-6 text-center">
                Two Factor Authentication
              </div>
              <div className="flex justify-center">
                <div dangerouslySetInnerHTML={{ __html: svg }} />
              </div>

              <div>
                <form onSubmit={handleSubmit}>
                  <div className="h-[85px] rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-normal leading-5 text-primaryDark"
                    >
                      Code
                    </label>
                    <div className="">
                      <input
                        type="text"
                        required
                        name="otp"
                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                      />
                    </div>
                  </div>
                  {otpError !=='' && <div className="text-red-500 px-3 pt-[10px]">{otpError}</div>}
                  <div className="pt-[20px] ">
                  <button
                      type="submit"
                      disabled={loading}
                      className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="pt-[20px] text-center text-primaryBlue">
                    Go back to <span 
                    onClick={BacktoLogin}
                    className="cursor-pointer font-bold underline">Login</span>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="max-w-[611px] mx-auto px-2 xs:px-0 lg:px-14">
              <div className="text-2xl font-bold leading-[28px] mb-6">
                ONE TIME PASSWORD
              </div>

              <div>
                <form onSubmit={handleSubmitCode}>
                  <div className="h-[85px] rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-normal leading-5 text-primaryDark"
                    >
                      Code
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="otp"
                        required
                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                      />
                    </div>
                  </div>
                  {otpError !=='' && <div className="text-red-500 px-3 pt-[10px]">{otpError}</div>}
                  <div className="pt-[20px]">
                  
                    <button
                      type="submit"
                      disabled={loading}
                      className="flex w-full h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="pt-[20px] text-center text-primaryBlue">
                    Go back to <span
                     onClick={BacktoLogin}
                    className="cursor-pointer font-bold underline">Login</span>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TwoFactorLand;
