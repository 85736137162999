import React from 'react'
import Background from '../../components/signupLogin/Background'

function Signup() {
  return (
    <>
    <Background/>
    </>
  )
}

export default Signup