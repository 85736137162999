import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import  { store,persistor } from './store/store';
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from './ScrollToTopOnNavigate';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
<GoogleOAuthProvider 
clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
    <ScrollToTop/>
      <App />
    </BrowserRouter>
    </PersistGate>
  </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
