import React, { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
const CheckboxTree = ({ data, onChange, value, fetchMoreData, hasMore }) => {
    const [checkedItems, setCheckedItems] = useState([]);
    useEffect(() => {
        setCheckedItems(value ? value : [])
    }, [])


    const handleCheckboxChange = (id) => {
        const newCheckedItems = [...checkedItems];
        // console.log(newCheckedItems)
        const index = newCheckedItems.indexOf(id);

        if (index !== -1) {
            newCheckedItems.splice(index, 1); // Item already checked, so uncheck it
        } else {
            newCheckedItems.push(id); // Item not checked, so check it
        }

        setCheckedItems(newCheckedItems);
        onChange(newCheckedItems); // Call the onChange callback with the updated checked items
    };

    const renderCheckbox = (item) => {
        const isChecked = checkedItems.includes(item.id);

        return (
            <div key={item.id} className="parent-cat">

                <>
                    <label className="flex items-center gap-x-2 text-[#515F7B] text-sm font-semibold mb-4">
                        <div>
                            <input
                            disabled={item.disabled ? true : false}
                                type="checkbox"
                                checked={isChecked}
                                className="h-5 w-5"
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                        </div>
                        <span className={`${item.disabled ? 'opacity-50' : ""}`}>
                            {item.name ? item.name : item.title}
                        </span>
                    </label>
                    {item.children && item.children?.length > 0 && (
                        <div style={{ marginLeft: "20px" }}>
                            {item.children.map((child) => renderCheckbox(child))}
                        </div>
                    )}
                </>
            </div>


        );
    };

    return data?.length > 0 ?
        // <div className="h-[200px] overflow-auto pl-3">{data.map((item) => renderCheckbox(item))}</div>
        <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4 className='px-2'>Loading...</h4>}
            height={200}
            // endMessage={
            //     <p style={{ textAlign: "center" }}>
            //         <b>Yay! You have seen it all</b>
            //     </p>
            // }
        >
            {data.map((item) => renderCheckbox(item))}
        </InfiniteScroll>
        : <div className="h-[200px] overflow-auto pl-3">Nothing found</div>;
};

export default CheckboxTree

