import React from 'react'
// import { Tooltip } from 'react-tooltip'

function Banner({title,description}) {
    return (
        <section id='banner-section' className='px-2 xs:px-5'>
            <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
               <div className='flex items-center min-h-[94px] md:min-h-[190px] py-4'>
               <h1
            //    data-tooltip-id="my-tooltip"
            //    data-tooltip-content={`${title}`}
               className='text-whiteGrey leading-[normal] text-[22px] md:text-[40px] font-extrabold'>
                {title}
                <br />
                <div className='text-whiteGrey text-lg font-semibold whitespace-break-spaces'>
                   {/* {description} */}
                   <div dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                </h1>
                
               </div>
             
            </div>
            {/* <Tooltip
                    id="my-tooltip"
                    place="bottom-start"
                    style={{ backgroundColor: "#161E2E", color: "#F9F9F9", maxWidth: '300px', }}
                /> */}
        </section>
    )
}

export default Banner