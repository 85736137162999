import React, { useState } from 'react'
import LoginHeader from '../../adminComponents/LoginHeader/LoginHeader'
import ForgotPassword from '../../adminComponents/ResetComponents/ForgotPassword'
import CreateNewPassword from '../../adminComponents/ResetComponents/CreateNewPassword'
import OtpVerification from '../../adminComponents/ResetComponents/OtpVerification';

function ResetPassword() {
    const [step, setStep] = useState(0);
    const [emailData, setEmailData]= useState({})
    
    return (
        <>
            <LoginHeader />
            <div className='flex justify-center items-center'>
                {
                    step === 0 &&
                    <ForgotPassword setStep={setStep} setEmailData={setEmailData} />
                }
                {
                    step === 1 && 
                    <OtpVerification setStep={setStep} emailData={emailData}/>
                }
                {
                    step === 2 &&
                    <CreateNewPassword setStep={setStep} emailData={emailData}/>
                }
            </div>
        </>
    )
}

export default ResetPassword