import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Fragment, useState } from 'react'
// import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { CloseEyeIcon, DropdownIcon, OpenEyeIcon } from '../../assets/icon-file';
import { AddAdminUser, AdminListUser, EditAdminUser } from '../../service/service';
import Swal from 'sweetalert2'
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';

export default function UserModal({ isOpen, setIsOpen, roles, setUserData, formData, setFormData, selected, setSelected, isEdit,
  currentPage, itemsPerPage, formErrors, setFormErrors
}) {


  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  const handleTogglePassword = (inputName) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [inputName]: !prevShowPassword[inputName]
    }));
  };
  function closeModal() {
    setIsOpen(false)
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors({
      fullName: '',
      email: '',
      password: '',
      phoneNumber: '',
      role: ''
    });
    if (isEdit) {
      if (validateForm()) {
        formData.role = selected ? selected.id : null;
        try {
          setloading(true)
          const data = {
            id: formData.id,
            fullname: formData.fullName,
            role_id: selected ? selected.id : null,
            email: formData.email,
            is_admin: selected ? true : false,

          };
          const result = await EditAdminUser(data);
          setIsOpen(false)
          if (result.status) {
            setloading(false)
            setFormData({
              fullName: '',
              email: '',
              password: '',
              phoneNumber: '',
              role: ''
            });
            setSelected('')
            AdminListUser(currentPage, itemsPerPage)
              .then((result) => {
                if (result.status) {
                  setUserData(result.data.data)
                }
                else {
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                  })
                }

              })

            Swal.fire({
              icon: 'success',
              title: result.message,
              showConfirmButton: false,
              timer: 1500
            }
            )
          }
          else {
            setloading(false)
            Swal.fire({
              title: 'Error!',
              text: result.message,
              icon: 'error',
              timer: 3000,
              confirmButtonText: 'OK'
            })
          }
        } catch (error) {
          setIsOpen(false)
          console.log(error.message);
          setloading(false)
          Swal.fire({
            title: 'Error!',
            text: error.message,
            icon: 'error',
            timer: 3000,
            confirmButtonText: 'OK'
          })
        }
      }
    }
    else {
      if (validateForm()) {
        formData.role = selected.id;
        try {
          setloading(true)
          const data = {
            fullname: formData.fullName,
            role_id: selected.id,
            phone: formData.phoneNumber,
            email: formData.email,
            password: formData.password
          };
          const result = await AddAdminUser(data);
          setIsOpen(false)
          if (result.status) {
            setloading(false)
            setFormData({
              fullName: '',
              email: '',
              password: '',
              phoneNumber: '',
              role: ''
            });
            setSelected('')
            AdminListUser(currentPage, itemsPerPage)
              .then((result) => {
                if (result.status) {
                  setUserData(result.data.data)
                }
                else {
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                  })
                }

              })

            Swal.fire({
              icon: 'success',
              title: result.message,
              showConfirmButton: false,
              timer: 1500
            }
            )
          }
          else {
            setloading(false)
            Swal.fire({
              title: 'Error!',
              text: result.message,
              icon: 'error',
              timer: 3000,
              confirmButtonText: 'OK'
            })
          }
        } catch (error) {
          setIsOpen(false)
          console.log(error.message);
          setloading(false)
          Swal.fire({
            title: 'Error!',
            text: error.message,
            icon: 'error',
            timer: 3000,
            confirmButtonText: 'OK'
          })
        }
      }
    }
  };
  function validatePassword(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

    if (!regex.test(password)) {
      return false;
    }

    return true;
  }
  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.fullName) {
      isValid = false;
      errors.fullName = 'Full Name is required';
    }
    if (!selected && formData.is_admin === 1) {
      isValid = false;
      errors.role = 'Role is required';
    }
    if (!formData.email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'Invalid email format';
    }
    if (formData.phoneNumber) {
      if (formData.phoneNumber[0] !== '+') {
        isValid = false;
        errors.phoneNumber = 'Invalid phone number format';
      }
      else if (formData.phoneNumber.length < 10) {
        isValid = false;
        errors.phoneNumber = 'Phone number is too short';
      }
    }

    if (!formData.password && !isEdit) {
      isValid = false;
      errors.password = 'Password is required';
    }
    else if (!(validatePassword(formData.password)) && !isEdit) {
      isValid = false;
      errors.password = 'Invalid password';
    }

    setFormErrors(errors);
    return isValid;
  };
  const validateInput = (e) => {
    const key = e.key;
    const isAllowed =
      (key >= '0' && key <= '9') || // Check if key is a number
      key === '+' ||    // Check if key is the plus sign (+)
      key === 'Backspace' ||
      key === 'ArrowRight' || // Check if key is the right arrow key
      key === 'ArrowLeft'; // Check if key is the left arrow key


    if (!isAllowed) {
      e.preventDefault(); // Prevent input of non-allowed characters
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[411px] transform rounded-2xl bg-white px-8 pt-4 pb-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-[normal] flex justify-between items-center text-primaryDark"
                  >
                    {isEdit ? 'Edit User' : 'Add New User'}
                    <span
                      onClick={closeModal}
                      className='cursor-pointer'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </Dialog.Title>
                  <form className='pt-6 space-y-4' onSubmit={handleSubmit}>
                    <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                      <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                        Full Name *
                      </label>
                      <div className="">
                        <input
                          id="full-name"
                          name="fullName"
                          type="text"
                          autoComplete="name"
                          placeholder='John Smith'
                          required
                          value={formData.fullName}
                          onChange={handleChange}
                          className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                        />
                      </div>
                    </div>
                    {formErrors.fullName && <div className="text-red-500 px-2 !mt-1">{formErrors.fullName}</div>}

                    {/* Role */}
                    {
                      // formData.is_admin === 1 &&
                      <>
                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                          <label htmlFor="role" className="block text-sm font-normal leading-5 text-primaryDark">
                            Role *
                          </label>
                          <div className="">
                            <Listbox value={selected} onChange={setSelected}>
                              <div className="relative">
                                <Listbox.Button className="relative w-full cursor-pointer h-[43px] bg-transparent py-2 pr-10 text-left focus:outline-none sm:text-lg sm:leading-normal font-semibold">
                                  <span className="block truncate">{selected?.name}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <DropdownIcon
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {roles.map((roles, roleIdx) => (
                                      <Listbox.Option
                                        key={roleIdx}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 px-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-gray-900'
                                          }`
                                        }
                                        value={roles}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-semibold' : 'font-semibold'
                                                }`}
                                            >
                                              {roles.name}
                                            </span>
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                        </div>
                        {formErrors.role && <div className="text-red-500 px-2 !mt-1">{formErrors.role}</div>}

                      </>
                    }
                    {/* Email */}
                    <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                      <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                        Email *
                      </label>
                      <div className="">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder='Johsmith@mail.com'
                          required
                          readOnly={isEdit}
                          value={formData.email}
                          onChange={handleChange}
                          className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                        />
                      </div>
                    </div>
                    {formErrors.email && <div className="text-red-500 px-2 !mt-1">{formErrors.email}</div>}

                    {/* Phone Number */}
                    {
                      !isEdit &&

                      <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                        <label htmlFor="phone-number" className="block text-sm font-normal leading-5 text-primaryDark">
                          {`Phone Number (optional)`}
                        </label>
                        <div className="">
                          <input
                            id="phone-number"
                            name="phoneNumber"
                            type="text"
                            placeholder='+(123) 123-1234'
                            autoComplete="tel"
                            onKeyDown={validateInput}
                            maxLength={15}
                            // required
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                          />
                        </div>
                      </div>
                    }
                    {formErrors.phoneNumber && <div className="text-red-500 px-2 !mt-1">{formErrors.phoneNumber}</div>}

                    {/* Password */}
                    {
                      !isEdit &&
                      <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>

                        <div className='flex gap-x-1 items-center'>
                          <label htmlFor="password" className="block text-sm font-normal leading-5 text-primaryDark">
                            Password *
                          </label>
                          <InformationCircleIcon
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Password must be 8 characters or more, with at least one uppercase letter, one number, and one special character"
                            className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="relative">
                          <input
                            id="password"
                            name="password"
                            type={showPassword.password ? 'text' : 'password'}
                            autoComplete="new-password"
                            placeholder='*******'
                            required
                            value={formData.password}
                            onChange={handleChange}
                            className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                          />
                          <span
                            className='absolute top-2 right-0 cursor-pointer'
                            onClick={() => handleTogglePassword('password')}
                          >
                            {
                              showPassword.password ?
                                (
                                  <OpenEyeIcon
                                  className="w-6 h-6"
                                  aria-label="show password"
                                />
                                 

                                ) :
                                (
                                  <CloseEyeIcon
                                  className="w-6 h-6"
                                  aria-label="hide password"
                                />
                                )

                            }


                          </span>
                        </div>
                      </div>
                    }
                    {formErrors.password && <div className="text-red-500 px-2 !mt-1">{formErrors.password}</div>}



                    {/* Submit button */}

                    <div className="mt-4 flex justify-center gap-4 items-center">
                      <button
                        type="button"
                        className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        disabled={loading}
                        className="w-[146px] h-[45px] py-2 text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                      >
                        Submit
                      </button>
                    </div>
                    <Tooltip
                      id="my-tooltip"
                      place="bottom-start"
                      style={{ backgroundColor: "#161E2E", color: "#F9F9F9", width: '231px' }}
                    />
                  </form>



                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
