const BlockData = [
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <g clip-path="url(#clip0_1510_6294)">
                    <path d="M21.6844 15.205L14.1844 10.5175C13.8954 10.3369 13.5311 10.3273 13.2331 10.4925C12.9349 10.6577 12.75 10.9717 12.75 11.3125V20.6875C12.75 21.0283 12.9349 21.3423 13.2331 21.5075C13.3747 21.586 13.5312 21.625 13.6875 21.625C13.8602 21.625 14.0326 21.5773 14.1844 21.4825L21.6844 16.795C21.9585 16.6237 22.125 16.3233 22.125 16C22.125 15.6768 21.9586 15.3763 21.6844 15.205ZM14.6251 18.996V13.0039L19.4187 16L14.6251 18.996Z" fill="#161E2E"/>
                    <path d="M27.8126 3.8125H5.18756C2.60287 3.8125 0.500061 5.91531 0.500061 8.5V23.5C0.500061 26.0847 2.60287 28.1875 5.18756 28.1875H27.8126C30.3972 28.1875 32.5001 26.0847 32.5001 23.5V8.5C32.5001 5.91531 30.3972 3.8125 27.8126 3.8125ZM30.6251 23.5C30.6251 25.0508 29.3634 26.3125 27.8126 26.3125H5.18756C3.63675 26.3125 2.37506 25.0508 2.37506 23.5V8.5C2.37506 6.94919 3.63675 5.6875 5.18756 5.6875H27.8126C29.3634 5.6875 30.6251 6.94919 30.6251 8.5V23.5Z" fill="#161E2E"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1510_6294">
                        <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                    </defs>
                </svg>`,
        title: 'Video'
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <g clip-path="url(#clip0_1516_6359)">
          <path d="M28.4109 0H4.58906C2.33431 0 0.5 1.83438 0.5 4.08913V27.9109C0.5 30.1656 2.33431 32 4.58906 32H28.4109C30.6657 32 32.5 30.1656 32.5 27.9109V4.08913C32.5 1.83438 30.6657 0 28.4109 0ZM30.6151 27.9109C30.6151 29.1264 29.6263 30.1151 28.4109 30.1151H4.58906C3.37363 30.1151 2.38481 29.1263 2.38481 27.9109V24.473L8.58825 19.1948C8.81462 19.0022 9.14487 19.0004 9.37337 19.1901L13.2592 22.4169C13.6339 22.7279 14.1839 22.7024 14.5282 22.3577L23.7613 13.1106C23.9282 12.9434 24.1227 12.9272 24.2243 12.9324C24.3256 12.9376 24.5177 12.9737 24.6667 13.1571L30.6152 20.4815L30.6151 27.9109ZM30.6151 17.4916L26.1297 11.9687C25.6849 11.4209 25.0256 11.0861 24.3209 11.0499C23.6166 11.0142 22.9261 11.2794 22.4275 11.7788L13.802 20.4176L10.5776 17.7401C9.64269 16.9638 8.29244 16.9718 7.36681 17.7594L2.38481 21.9982V4.08913C2.38481 2.87369 3.37363 1.88488 4.58906 1.88488H28.4109C29.6264 1.88488 30.6151 2.87369 30.6151 4.08913V17.4916Z" fill="#161E2E"/>
          <path d="M10.5734 3.93713C8.06743 3.93713 6.02881 5.97588 6.02881 8.4817C6.02881 10.9876 8.0675 13.0263 10.5734 13.0263C13.0792 13.0263 15.1179 10.9876 15.1179 8.4817C15.1179 5.97582 13.0793 3.93713 10.5734 3.93713ZM10.5734 11.1414C9.10675 11.1414 7.91362 9.94826 7.91362 8.4817C7.91362 7.01507 9.10675 5.82195 10.5734 5.82195C12.04 5.82195 13.2331 7.01513 13.2331 8.4817C13.2331 9.94826 12.04 11.1414 10.5734 11.1414Z" fill="#161E2E"/>
        </g>
        <defs>
          <clipPath id="clip0_1516_6359">
            <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>`,
        title: 'Images'
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <path d="M27.6124 6.64175L21.5859 0.940375C20.9449 0.333937 20.1059 0 19.2236 0H7.75C5.85456 0 4.3125 1.54206 4.3125 3.4375V28.5625C4.3125 30.4579 5.85456 32 7.75 32H25.25C27.1454 32 28.6875 30.4579 28.6875 28.5625V9.13888C28.6875 8.19831 28.2956 7.28813 27.6124 6.64175ZM25.7913 7.5H21.125C20.9527 7.5 20.8125 7.35981 20.8125 7.1875V2.78975L25.7913 7.5ZM25.25 30.125H7.75C6.88844 30.125 6.1875 29.4241 6.1875 28.5625V3.4375C6.1875 2.57594 6.88844 1.875 7.75 1.875H18.9375V7.1875C18.9375 8.39369 19.9188 9.375 21.125 9.375H26.8125V28.5625C26.8125 29.4241 26.1116 30.125 25.25 30.125Z" fill="#161E2E"/>
        <path d="M23.1875 12.5H9.4375C8.91975 12.5 8.5 12.9197 8.5 13.4375C8.5 13.9553 8.91975 14.375 9.4375 14.375H23.1875C23.7052 14.375 24.125 13.9553 24.125 13.4375C24.125 12.9197 23.7052 12.5 23.1875 12.5Z" fill="#161E2E"/>
        <path d="M23.1875 17.5H9.4375C8.91975 17.5 8.5 17.9197 8.5 18.4375C8.5 18.9553 8.91975 19.375 9.4375 19.375H23.1875C23.7052 19.375 24.125 18.9553 24.125 18.4375C24.125 17.9197 23.7052 17.5 23.1875 17.5Z" fill="#161E2E"/>
        <path d="M13.9825 22.5H9.4375C8.91975 22.5 8.5 22.9197 8.5 23.4375C8.5 23.9553 8.91975 24.375 9.4375 24.375H13.9825C14.5002 24.375 14.92 23.9553 14.92 23.4375C14.92 22.9197 14.5002 22.5 13.9825 22.5Z" fill="#161E2E"/>
      </svg>`,
        title: 'Documents'
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <path d="M3.5 6C3.5 5.73478 3.60536 5.48043 3.79289 5.29289C3.98043 5.10536 4.23478 5 4.5 5H28.5C28.7652 5 29.0196 5.10536 29.2071 5.29289C29.3946 5.48043 29.5 5.73478 29.5 6C29.5 6.26522 29.3946 6.51957 29.2071 6.70711C29.0196 6.89464 28.7652 7 28.5 7H4.5C4.23478 7 3.98043 6.89464 3.79289 6.70711C3.60536 6.51957 3.5 6.26522 3.5 6ZM4.5 13.6667H17.132C17.3972 13.6667 17.6516 13.5613 17.8391 13.3738C18.0266 13.1862 18.132 12.9319 18.132 12.6667C18.132 12.4015 18.0266 12.1471 17.8391 11.9596C17.6516 11.772 17.3972 11.6667 17.132 11.6667H4.5C4.23478 11.6667 3.98043 11.772 3.79289 11.9596C3.60536 12.1471 3.5 12.4015 3.5 12.6667C3.5 12.9319 3.60536 13.1862 3.79289 13.3738C3.98043 13.5613 4.23478 13.6667 4.5 13.6667ZM28.5 18.3333H4.5C4.23478 18.3333 3.98043 18.4387 3.79289 18.6262C3.60536 18.8138 3.5 19.0681 3.5 19.3333C3.5 19.5985 3.60536 19.8529 3.79289 20.0404C3.98043 20.228 4.23478 20.3333 4.5 20.3333H28.5C28.7652 20.3333 29.0196 20.228 29.2071 20.0404C29.3946 19.8529 29.5 19.5985 29.5 19.3333C29.5 19.0681 29.3946 18.8138 29.2071 18.6262C29.0196 18.4387 28.7652 18.3333 28.5 18.3333ZM17.132 25H4.5C4.23478 25 3.98043 25.1054 3.79289 25.2929C3.60536 25.4804 3.5 25.7348 3.5 26C3.5 26.2652 3.60536 26.5196 3.79289 26.7071C3.98043 26.8946 4.23478 27 4.5 27H17.132C17.3972 27 17.6516 26.8946 17.8391 26.7071C18.0266 26.5196 18.132 26.2652 18.132 26C18.132 25.7348 18.0266 25.4804 17.8391 25.2929C17.6516 25.1054 17.3972 25 17.132 25Z" fill="#161E2E"/>
      </svg>`,
        title: 'Paragraph'
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4787 5.93323C24.7707 6.3239 24.9441 6.8079 24.9441 7.3319C24.9441 7.85723 24.7707 8.34123 24.4787 8.73056C24.1467 9.1719 24.2361 9.7999 24.6774 10.1319C25.1187 10.4626 25.7454 10.3732 26.0774 9.9319C26.6214 9.2079 26.9441 8.3079 26.9441 7.3319C26.9441 6.35723 26.6214 5.45723 26.0774 4.73323C25.7454 4.2919 25.1187 4.20256 24.6774 4.53323C24.2361 4.86523 24.1467 5.49323 24.4787 5.93323Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9227 4.73323C6.3787 5.45723 6.05603 6.35723 6.05603 7.3319C6.05603 8.3079 6.3787 9.2079 6.9227 9.9319C7.2547 10.3732 7.88136 10.4626 8.3227 10.1319C8.76403 9.7999 8.85336 9.1719 8.52136 8.73056C8.22936 8.34123 8.05603 7.85723 8.05603 7.3319C8.05603 6.8079 8.22936 6.3239 8.52136 5.93323C8.85336 5.49323 8.76403 4.86523 8.3227 4.53323C7.88136 4.20256 7.2547 4.2919 6.9227 4.73323Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9227 4.59998C28.4947 5.36264 28.8334 6.30798 28.8334 7.33331C28.8334 8.35864 28.4947 9.30398 27.9227 10.0666C27.5907 10.5066 27.68 11.1346 28.1214 11.4666C28.5627 11.7973 29.1894 11.708 29.5214 11.2666C30.3454 10.1706 30.8334 8.80931 30.8334 7.33331C30.8334 5.85731 30.3454 4.49598 29.5214 3.39998C29.1894 2.95864 28.5627 2.86931 28.1214 3.19998C27.68 3.53198 27.5907 4.15998 27.9227 4.59998Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.47875 3.39998C2.65475 4.49598 2.16675 5.85731 2.16675 7.33331C2.16675 8.80931 2.65475 10.1706 3.47875 11.2666C3.81075 11.708 4.43741 11.7973 4.87875 11.4666C5.32008 11.1346 5.40941 10.5066 5.07741 10.0666C4.50541 9.30398 4.16675 8.35864 4.16675 7.33331C4.16675 6.30798 4.50541 5.36264 5.07741 4.59998C5.40941 4.15998 5.32008 3.53198 4.87875 3.19998C4.43741 2.86931 3.81075 2.95864 3.47875 3.39998Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5001 1.66663C12.9867 1.66663 10.1667 4.38129 10.1667 7.69196V16.308C10.1667 19.6186 12.9867 22.3333 16.5001 22.3333C20.0134 22.3333 22.8334 19.6186 22.8334 16.308V7.69196C22.8334 4.38129 20.0134 1.66663 16.5001 1.66663ZM16.5001 20.3333C14.1227 20.3333 12.1667 18.548 12.1667 16.308V7.69196C12.1667 5.45196 14.1227 3.66663 16.5001 3.66663C18.8774 3.66663 20.8334 5.45196 20.8334 7.69196V16.308C20.8334 18.548 18.8774 20.3333 16.5001 20.3333Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 23.6666C12.308 23.6666 8.83337 20.8813 8.83337 17.3333V14.6666C8.83337 14.1146 8.38537 13.6666 7.83337 13.6666C7.28137 13.6666 6.83337 14.1146 6.83337 14.6666V17.3333C6.83337 21.8866 11.1187 25.6666 16.5 25.6666C21.8814 25.6666 26.1667 21.8866 26.1667 17.3333V14.6666C26.1667 14.1146 25.7187 13.6666 25.1667 13.6666C24.6147 13.6666 24.1667 14.1146 24.1667 14.6666V17.3333C24.1667 20.8813 20.692 23.6666 16.5 23.6666Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 24.6666V29.3333C15.5 29.8853 15.948 30.3333 16.5 30.3333C17.052 30.3333 17.5 29.8853 17.5 29.3333V24.6666C17.5 24.1146 17.052 23.6666 16.5 23.6666C15.948 23.6666 15.5 24.1146 15.5 24.6666Z" fill="#161E2E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 28.3334H12.5C11.948 28.3334 11.5 28.7814 11.5 29.3334C11.5 29.8854 11.948 30.3334 12.5 30.3334H20.5C21.052 30.3334 21.5 29.8854 21.5 29.3334C21.5 28.7814 21.052 28.3334 20.5 28.3334Z" fill="#161E2E"/>
      </svg>`,
        title: 'Audio'
    },
    {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.884 6.29994L12.5507 24.9666C12.3494 25.6746 12.76 26.4133 13.4667 26.6159C14.1747 26.8173 14.9134 26.4066 15.116 25.6999L20.4494 7.03328C20.6507 6.32528 20.24 5.58661 19.5334 5.38394C18.8254 5.18261 18.0867 5.59328 17.884 6.29994ZM6.38538 15.9999L10.776 20.3906C11.296 20.9106 11.296 21.7559 10.776 22.2759C10.256 22.7959 9.41072 22.7959 8.89072 22.2759L3.55738 16.9426C3.03605 16.4226 3.03605 15.5773 3.55738 15.0573L8.89072 9.72394C9.41072 9.20394 10.256 9.20394 10.776 9.72394C11.296 10.2439 11.296 11.0893 10.776 11.6093L6.38538 15.9999ZM26.6147 15.9999L22.224 11.6093C21.704 11.0893 21.704 10.2439 22.224 9.72394C22.744 9.20394 23.5894 9.20394 24.1094 9.72394L29.4427 15.0573C29.9641 15.5773 29.9641 16.4226 29.4427 16.9426L24.1094 22.2759C23.5894 22.7959 22.744 22.7959 22.224 22.2759C21.704 21.7559 21.704 20.9106 22.224 20.3906L26.6147 15.9999Z" fill="#161E2E"/>
      </svg>`,
        title: 'Custom HTML'
    },
]

export default BlockData;
