import React, { useEffect, useState } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import DashboardSideNav from '../../adminComponents/DashboardComponents/DashboardSideNav'
import { useDispatch, useSelector } from 'react-redux';
import DashboardHeader from '../../adminComponents/DashboardComponents/DashboardHeader';
import SliderConatiner from '../../adminComponents/ContentManagment/SliderConatiner';
import { AddAdminSlider, AdminListSlider, DeleteAdminSlider, UpdateAdminSlider } from '../../service/service';
import Swal from 'sweetalert2';
import MediaGalleryModal from '../../adminComponents/MediaGallary/MediaGalleryModal';
function ContentManagement() {
    const itemsPerPage = 10000;
    const currentDate = new Date().toISOString().split('T')[0];
    // const dispatch = useDispatch();
    const userPermissions = useSelector((state) => state.adminUser.userPermissions)
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [sliderArray, setSliderArray] = useState([{
        title: '',
        description: '',
        btnText: '',
        btnUrl: '',
        image: null,
        is_publish: false,
        date: currentDate,
        end_date: currentDate,
        time: ''
    },])
    const [isOpenMedia ,setIsOpenMedia]= useState(false)
    const [blockIndex, setBlockIndex]= useState()
   const OpenMedia =(index)=>{
    setBlockIndex(index)
    setIsOpenMedia(true)
   }
    const selectedMedia=(item)=>{
        
        const newData = [...sliderArray];
        // Update the specific object in the array
        newData[blockIndex] = { ...newData[blockIndex], image: item.url };
        // Update the state with the new array
        setSliderArray(newData);
        setIsOpenMedia(false)
        
    }
    useEffect(() => {
        fetchData()
    }, [page])
    const fetchData = async (param) => {
        setIsLoading(true);
        try {
            // Replace this with your actual API call
            const response = await AdminListSlider(page, itemsPerPage);
            const newData = await response.data.data;
            
            if (newData.length === 0) {
                setSliderArray(
                    [
                        {
                            title: '',
                            description: '',
                            btnText: '',
                            btnUrl: '',
                            image: null,
                            is_publish: false,
                            date: currentDate,
                            end_date: currentDate,
                            time: ''
                        }
                    ]
                )
            }
            else {

                const updatededDAta = newData?.map((item) => ({
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    btnText: item.buttonText,
                    btnUrl: item.buttonUrl,
                    image: item.url,
                    date: item.start_date,
                    end_date: item.end_date,
                    time: '',
                    is_publish: true,
                }))
                
                setSliderArray(updatededDAta.reverse());
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
            if (param) {
                Swal.fire({
                    icon: 'success',
                    title: `Slide ${param} successfully`,
                    showConfirmButton: false,
                    timer: 1500
                }
                )
            }
        }
    };
    
    const addNewSlide = () => {
        setSliderArray([{
            title: '',
            description: '',
            btnText: '',
            btnUrl: '',
            image: null,
            is_publish: false,
            date: currentDate,
            end_date: currentDate,
            time: ''
        }, ...sliderArray])
    }
    const PublishNewSlide = (data) => {
        setIsLoading(true)
        const Form_Data = new FormData();
        Form_Data.append('image', data.image);
        Form_Data.append('title', data.title);
        Form_Data.append('description', data.description);
        Form_Data.append('buttonText', data.btnText);
        Form_Data.append('buttonUrl', data.btnUrl);
        Form_Data.append('start_date', data.date);
        Form_Data.append('end_date', data.end_date);
        // Form_Data.append('time', data.time);
        AddAdminSlider(Form_Data).then((result) => {
            if (result.status) {
                fetchData('created')
            }
            else {
                setIsLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.message);
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            });
    }
    const UpdateSlide = (data) => {
        // console.log(sliderArray)
        setIsLoading(true)
        const Form_Data = new FormData();
        Form_Data.append('id', data.id);
        Form_Data.append('image', data.image);
        Form_Data.append('title', data.title);
        Form_Data.append('description', data.description);
        Form_Data.append('buttonText', data.btnText);
        Form_Data.append('buttonUrl', data.btnUrl);
        Form_Data.append('start_date', data.date);
        Form_Data.append('end_date', data.end_date);
        // Form_Data.append('time', data.time);
        UpdateAdminSlider(Form_Data).then((result) => {
            if (result.status) {
                fetchData('updated')
            }
            else {
                setIsLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.message);
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            });
    }
    const DeleteSlide = (id) => {
       
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoading(true);
                const formData = {
                    id
                }
                DeleteAdminSlider(formData)
                    .then((result) => {
                        if (result.status) {
                            fetchData('deleted')

                        }
                        else {
                            Swal.fire({
                                title: 'Error!',
                                text: result.message,
                                icon: 'error',
                                timer: 3000,
                                confirmButtonText: 'OK'
                            })
                        }

                    })
                    .catch((error) => {
                        setIsLoading(false)
                        console.log(error.message);
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            timer: 3000,
                            confirmButtonText: 'OK'
                        })
                    });

            }
        })
    }
    const buttonElement =
        userPermissions?.includes('add_slider') &&
        <div className='flex gap-x-4'>
            <button
                type="button"
                onClick={addNewSlide}
                className="flex gap-x-2 w-[137px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
                <span aria-hidden="true" className='text-[13px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <g clipPath="url(#clip0_966_2238)">
                            <path d="M10.9453 6.44531H7.05469V2.55469C7.05469 1.9722 6.58249 1.5 6 1.5C5.41751 1.5 4.94531 1.9722 4.94531 2.55469V6.44531H1.05469C0.472195 6.44531 0 6.91751 0 7.5C0 8.08249 0.472195 8.55469 1.05469 8.55469H4.94531V12.4453C4.94531 13.0278 5.41751 13.5 6 13.5C6.58249 13.5 7.05469 13.0278 7.05469 12.4453V8.55469H10.9453C11.5278 8.55469 12 8.08249 12 7.5C12 6.91751 11.5278 6.44531 10.9453 6.44531Z" fill="#F9F9F9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_966_2238">
                                <rect width="12" height="13" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                New Slide
            </button>

        </div>;
    return (
        <>
            {
                isLoading &&
                <div className="overlay">
                    <div className="loaderImage-container">
                        <ThreeCircles
                            height="100"
                            width="100"
                            color="#F9F9F9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    </div>
                </div>
            }
            <div className='bg-whiteGrey'>
                <DashboardSideNav />
                <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                    <DashboardHeader title={'Content'} headerButton={buttonElement} subtitle={'Content Management'} />
                    <SliderConatiner
                        sliderArray={sliderArray}
                        setSliderArray={setSliderArray}
                        PublishNewSlide={PublishNewSlide}
                        DeleteSlide={DeleteSlide}
                        UpdateSlide={UpdateSlide}
                        OpenMedia={OpenMedia}
                    />
                    <MediaGalleryModal
                        mediatype={'Images'}
                        setIsOpenMedia={setIsOpenMedia}
                        isOpenMedia={isOpenMedia}
                        selectedMedia={selectedMedia}
                    />
                </main>
            </div>
        </>
    )
}

export default ContentManagement