import React, { Fragment, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { AddAdminMedia, DeleteAdminMedia, UpdateAdminMedia } from '../../service/service';
import Swal from 'sweetalert2';
function MediaDetailModal({ formData, setFormData, isOpen, setIsOpen, isEdit, selectedFile, setSelectedFile, ListMediaItems, isLoading, setIsLoading }) {
    // const [flag, setFlag] = useState(false)
    // const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const copyMessage = () => {
        navigator.clipboard.writeText(formData.url ?? `https://insidechassidus.org/`);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }
    function closeModal() {
        setIsOpen(false)
    }

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file' && files.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
            setSelectedFile(files[0])
            // console.log(files[0])
            // if (files[0].type.includes('video')) {
            //     const video = document.createElement('video');
            //     video.src = URL.createObjectURL(files[0]);

            //     video.onloadedmetadata = () => {
            //         console.log(video.duration);
            //     };
            // }
            // else if(files[0].type.includes('audio')){
            //     const audio = new Audio();
            //     audio.src = URL.createObjectURL(files[0]);
                
            //     audio.onloadedmetadata = () => {
            //         console.log(audio.duration);
            //     };
            // }
        } else {

            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    const submitFormData = (param) => {
        // setFlag(true)
        setIsLoading(true)
        if (param === 'update') {
            // console.log(formData)
            const data = {
                id: formData.id,
                title: formData.title,
                description: formData.description,
                url: formData.url,
                name: formData.name,
                type: formData.type
            }
            UpdateAdminMedia(data).then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setIsOpen(false)
                    ListMediaItems('Media updated successfully')
                }
            })
                .catch((error) => {
                    setIsLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: error.message,
                        showConfirmButton: false,
                        timer: 1500
                    }
                    )
                })
        }
        else {
            const Form_Data = new FormData();
            Form_Data.append('file', formData.file);
            Form_Data.append('title', formData.title);
            Form_Data.append('desc', formData.description);
            AddAdminMedia(Form_Data).then((result) => {
                setIsLoading(false)
                if (result.status) {
                    setIsOpen(false)
                    ListMediaItems('Media uploaded successfully')
                }
            })
                .catch((error) => {
                    setIsLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: error.message,
                        showConfirmButton: false,
                        timer: 1500
                    }
                    )
                })
        }


    }
    const DeleteMedia = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoading(true);
                const formData = {
                    id: [id]
                }
                DeleteAdminMedia(formData).then((result) => {
                    if (result.status) {
                        setIsOpen(false)
                        ListMediaItems('media deleted successfully')
                    }
                })
                    .catch((error) => {
                        setIsLoading(false)
                        console.log(error.message);
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            timer: 3000,
                            confirmButtonText: 'OK'
                        })
                    });

            }
        })
    }
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[546px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg flex justify-between items-center font-medium leading-6 text-gray-900"
                                    >
                                        <div className='flex w-[90%] items-center justify-between'>
                                            <div className='text-xl font-bold text-left'>
                                                Media Detail
                                            </div>
                                        </div>
                                        <span
                                            onClick={closeModal}
                                            className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </Dialog.Title>
                                    <div className='pt-6 space-y-4'>
                                        {
                                            isEdit ?
                                                <>
                                                    <div className='h-[250px] bg-[#E1E9F2] rounded-tr-lg rounded-tl-lg flex justify-center items-center'>
                                                        {
                                                            formData.type.includes('video')?
                                                                <video className='h-full w-full object-cover rounded-tr-lg rounded-tl-lg'>
                                                                    <source src={formData?.url} type="video/mp4" />
                                                                </video>
                                                                :
                                                                formData.type.includes('image') ?
                                                                    <img src={formData?.url} alt='Media' className='object-cover h-full w-full rounded-tr-lg rounded-tl-lg' />
                                                                    :
                                                                    formData.type.includes('audio') ?
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                                <path d="M25.496 20.1627V5.4959C25.495 5.29562 25.449 5.09813 25.3613 4.91808C25.2736 4.73803 25.1464 4.58006 24.9893 4.45589C24.8344 4.33058 24.6534 4.24159 24.4596 4.19544C24.2657 4.14929 24.064 4.14717 23.8693 4.18922L11.8693 6.85591C11.573 6.92272 11.3085 7.08865 11.1194 7.32626C10.9303 7.56387 10.8279 7.85891 10.8293 8.16259V19.2827C9.91906 18.853 8.89404 18.73 7.908 18.9322C6.92196 19.1344 6.02806 19.6508 5.3604 20.4041C4.69275 21.1574 4.28734 22.1068 4.20499 23.11C4.12265 24.1132 4.3678 25.1161 4.90369 25.9681C5.43957 26.8202 6.23728 27.4755 7.17715 27.8358C8.11702 28.1961 9.14838 28.2419 10.1165 27.9664C11.0846 27.6908 11.9373 27.1088 12.5466 26.3076C13.1559 25.5064 13.489 24.5293 13.496 23.5227C13.5097 23.2918 13.5097 23.0603 13.496 22.8294V9.22927L22.8293 7.16258V16.616C21.9185 16.186 20.8929 16.0631 19.9064 16.2658C18.9198 16.4684 18.0257 16.9857 17.3582 17.7398C16.6907 18.494 16.2858 19.4444 16.2045 20.4482C16.1232 21.4521 16.3697 22.4552 16.9071 23.307C17.4445 24.1588 18.2438 24.8132 19.1848 25.172C20.1259 25.5308 21.1579 25.5747 22.126 25.2969C23.0941 25.0191 23.9459 24.4348 24.5536 23.6316C25.1612 22.8285 25.4919 21.8498 25.496 20.8427C25.5086 20.6162 25.5086 20.3892 25.496 20.1627Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                                        :
                                                                        <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M15.4688 7.03125C14.5641 7.03125 13.8281 6.29527 13.8281 5.39062V0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V7.03125H15.4688ZM6.70312 16.875H10.1119C10.5002 16.875 10.815 17.1898 10.815 17.5781C10.815 17.9664 10.5002 18.2812 10.1119 18.2812H6.70312C6.31481 18.2812 6 17.9664 6 17.5781C6 17.1898 6.31481 16.875 6.70312 16.875ZM6 13.8281C6 13.4398 6.31481 13.125 6.70312 13.125H17.0156C17.4039 13.125 17.7188 13.4398 17.7188 13.8281C17.7188 14.2164 17.4039 14.5312 17.0156 14.5312H6.70312C6.31481 14.5312 6 14.2164 6 13.8281ZM17.0156 9.375C17.4039 9.375 17.7188 9.68981 17.7188 10.0781C17.7188 10.4664 17.4039 10.7812 17.0156 10.7812H6.70312C6.31481 10.7812 6 10.4664 6 10.0781C6 9.68981 6.31481 9.375 6.70312 9.375H17.0156Z" fill="#161E2E" />
                                                                                <path d="M15.2344 5.39101C15.2344 5.52024 15.3395 5.62538 15.4688 5.62538H20.8272C20.6986 5.38791 20.5331 5.1698 20.3343 4.9817L15.8145 0.705664C15.6391 0.539727 15.4437 0.401305 15.2344 0.291992V5.39101H15.2344Z" fill="#161E2E" />
                                                                            </svg>
                                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='text-sm break-all text-center bg-transparent text-primaryDark p-1'>
                                                        {formData.name}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        selectedFile ?
                                                            <>
                                                                <div className='h-[250px] bg-[#E1E9F2] rounded-tr-lg rounded-tl-lg flex justify-center items-center'>
                                                                    {
                                                                        selectedFile.type.includes('video') ?
                                                                            <video className='h-full w-full object-cover rounded-tr-lg rounded-tl-lg'>
                                                                                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                                                                            </video>
                                                                            :
                                                                            selectedFile.type.includes('image') ?
                                                                                <img src={URL.createObjectURL(selectedFile)} alt='icon' className='object-cover h-full w-full rounded-tr-lg rounded-tl-lg' />
                                                                                :
                                                                                selectedFile.type.includes('audio') ?
                                                                                    <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                                                            <path d="M25.496 20.1627V5.4959C25.495 5.29562 25.449 5.09813 25.3613 4.91808C25.2736 4.73803 25.1464 4.58006 24.9893 4.45589C24.8344 4.33058 24.6534 4.24159 24.4596 4.19544C24.2657 4.14929 24.064 4.14717 23.8693 4.18922L11.8693 6.85591C11.573 6.92272 11.3085 7.08865 11.1194 7.32626C10.9303 7.56387 10.8279 7.85891 10.8293 8.16259V19.2827C9.91906 18.853 8.89404 18.73 7.908 18.9322C6.92196 19.1344 6.02806 19.6508 5.3604 20.4041C4.69275 21.1574 4.28734 22.1068 4.20499 23.11C4.12265 24.1132 4.3678 25.1161 4.90369 25.9681C5.43957 26.8202 6.23728 27.4755 7.17715 27.8358C8.11702 28.1961 9.14838 28.2419 10.1165 27.9664C11.0846 27.6908 11.9373 27.1088 12.5466 26.3076C13.1559 25.5064 13.489 24.5293 13.496 23.5227C13.5097 23.2918 13.5097 23.0603 13.496 22.8294V9.22927L22.8293 7.16258V16.616C21.9185 16.186 20.8929 16.0631 19.9064 16.2658C18.9198 16.4684 18.0257 16.9857 17.3582 17.7398C16.6907 18.494 16.2858 19.4444 16.2045 20.4482C16.1232 21.4521 16.3697 22.4552 16.9071 23.307C17.4445 24.1588 18.2438 24.8132 19.1848 25.172C20.1259 25.5308 21.1579 25.5747 22.126 25.2969C23.0941 25.0191 23.9459 24.4348 24.5536 23.6316C25.1612 22.8285 25.4919 21.8498 25.496 20.8427C25.5086 20.6162 25.5086 20.3892 25.496 20.1627Z" fill="#161E2E" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    :
                                                                                    <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                            <path d="M15.4688 7.03125C14.5641 7.03125 13.8281 6.29527 13.8281 5.39062V0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V7.03125H15.4688ZM6.70312 16.875H10.1119C10.5002 16.875 10.815 17.1898 10.815 17.5781C10.815 17.9664 10.5002 18.2812 10.1119 18.2812H6.70312C6.31481 18.2812 6 17.9664 6 17.5781C6 17.1898 6.31481 16.875 6.70312 16.875ZM6 13.8281C6 13.4398 6.31481 13.125 6.70312 13.125H17.0156C17.4039 13.125 17.7188 13.4398 17.7188 13.8281C17.7188 14.2164 17.4039 14.5312 17.0156 14.5312H6.70312C6.31481 14.5312 6 14.2164 6 13.8281ZM17.0156 9.375C17.4039 9.375 17.7188 9.68981 17.7188 10.0781C17.7188 10.4664 17.4039 10.7812 17.0156 10.7812H6.70312C6.31481 10.7812 6 10.4664 6 10.0781C6 9.68981 6.31481 9.375 6.70312 9.375H17.0156Z" fill="#161E2E" />
                                                                                            <path d="M15.2344 5.39101C15.2344 5.52024 15.3395 5.62538 15.4688 5.62538H20.8272C20.6986 5.38791 20.5331 5.1698 20.3343 4.9817L15.8145 0.705664C15.6391 0.539727 15.4437 0.401305 15.2344 0.291992V5.39101H15.2344Z" fill="#161E2E" />
                                                                                        </svg>
                                                                                    </div>
                                                                    }
                                                                </div>
                                                                <div className='text-sm break-all text-center bg-transparent text-primaryDark p-1'>
                                                                    {selectedFile.name}
                                                                </div>
                                                            </>
                                                            :
                                                            <div
                                                                className=' border-primaryBlue py-14 p-4 rounded-xl border-dashed border-[1px] flex flex-col justify-center items-center'>
                                                                <label htmlFor={`fileInput`} className='flex flex-col justify-center items-center gap-4 cursor-pointer'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="79" height="68" viewBox="0 0 79 68" fill="none">
                                                                        <path d="M41.1781 16.589L41.2822 16.6201L41.2867 16.6149C41.779 16.7039 42.2673 16.4092 42.4119 15.9209C43.73 11.4921 47.8825 8.39817 52.5089 8.39817C53.0567 8.39817 53.5009 7.95393 53.5009 7.40621C53.5009 6.85848 53.0566 6.41426 52.5089 6.41426C46.823 6.41426 42.0449 10.2 40.5107 15.3555C40.3542 15.8807 40.6533 16.4325 41.1781 16.589Z" fill="#186CCE" stroke="#F9FFF9" strokeWidth="0.3375" />
                                                                        <path d="M64.0334 47.7432H59.0943C58.6398 47.7432 58.2711 47.3745 58.2711 46.92C58.2711 46.4655 58.6398 46.0968 59.0943 46.0968H64.0334C70.8418 46.0968 76.3814 40.5572 76.3814 33.7488C76.3814 26.9405 70.8418 21.4009 64.0334 21.4009H63.9147C63.6759 21.4009 63.449 21.2974 63.2926 21.1169C63.1362 20.9364 63.0656 20.6971 63.0997 20.4607C63.1732 19.948 63.2103 19.4329 63.2103 18.9313C63.2103 13.0308 58.4092 8.22972 52.5086 8.22972C50.2131 8.22972 48.024 8.94708 46.1776 10.3047C45.7719 10.6029 45.1956 10.4706 44.961 10.0243C39.732 0.0671616 26.0743 -1.26998 18.9935 7.39184C16.0106 11.0409 14.8386 15.7878 15.7778 20.4143C15.8812 20.9253 15.4902 21.4015 14.971 21.4015H14.6411C7.83278 21.4015 2.2932 26.9411 2.2932 33.7495C2.2932 40.5578 7.83278 46.0974 14.6411 46.0974H19.5803C20.0348 46.0974 20.4035 46.4661 20.4035 46.9206C20.4035 47.3751 20.0348 47.7438 19.5803 47.7438H14.6411C6.92479 47.7438 0.646729 41.4658 0.646729 33.7494C0.646729 26.2495 6.57722 20.1084 13.9949 19.7698C13.2982 14.9699 14.6306 10.1283 17.7187 6.34992C25.2997 -2.92455 39.8282 -1.885 45.9697 8.4567C47.929 7.22837 50.171 6.58399 52.5084 6.58399C59.6572 6.58399 65.322 12.6686 64.8264 19.7774C72.1757 20.1896 78.0275 26.2983 78.0275 33.7488C78.0275 41.4658 71.7494 47.7432 64.0331 47.7432L64.0334 47.7432Z" fill="#186CCE" />
                                                                        <path d="M18.478 46.4551C18.478 57.8963 27.7859 67.204 39.2269 67.204C50.6681 67.204 59.9758 57.8962 59.9758 46.4551C59.9758 35.014 50.6681 25.7063 39.2269 25.7063C27.7857 25.7063 18.478 35.0141 18.478 46.4551ZM20.4623 46.4551C20.4623 36.1086 28.8802 27.6905 39.2269 27.6905C49.5734 27.6905 57.9915 36.1085 57.9915 46.4551C57.9915 56.8016 49.5734 65.2198 39.2269 65.2198C28.8804 65.2198 20.4623 56.8018 20.4623 46.4551Z" fill="#186CCE" stroke="#F9FFF9" strokeWidth="0.3375" />
                                                                        <path d="M38.8322 54.7396C38.8322 55.1656 39.1777 55.511 39.6037 55.511C40.0295 55.511 40.3751 55.1661 40.3751 54.7396V39.07C40.3751 38.644 40.0296 38.2985 39.6037 38.2985C39.1777 38.2985 38.8322 38.644 38.8322 39.07V54.7396Z" fill="#186CCE" stroke="#186CCE" strokeWidth="0.3375" />
                                                                        <path d="M39.6033 40.1625L35.3276 44.4383L39.6033 40.1625ZM39.6033 40.1625L43.8792 44.4384C44.0296 44.5888 44.2277 44.6643 44.4247 44.6643L39.6033 40.1625ZM34.2365 44.4384C34.5378 44.7396 35.0264 44.7398 35.3275 44.4384L39.0577 38.5261L34.2365 43.3474C33.9351 43.6487 33.9351 44.137 34.2365 44.4384ZM40.1488 38.526C39.8475 38.2248 39.3589 38.2246 39.0578 38.526L44.4247 44.6643C44.6214 44.6643 44.8195 44.5895 44.9702 44.4384C45.2715 44.137 45.2715 43.6487 44.9702 43.3474L40.1488 38.526Z" fill="#186CCE" stroke="#186CCE" strokeWidth="0.3375" />
                                                                    </svg>
                                                                    <span className='text-primaryBlue text-base font-bold underline'>
                                                                        Upload or Choose Media
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id={`fileInput`}
                                                                    name='file'
                                                                    accept="file"
                                                                    style={{ display: 'none' }}
                                                                    onChange={handleChange}
                                                                />

                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <label htmlFor="title" className="block text-sm font-normal leading-5 text-primaryDark">
                                                Title
                                            </label>
                                            <div className="">
                                                <input
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    autoComplete="off"
                                                    required
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {/* {formData.end_date === '' && flag && <p className="text-red-500 px-3 !mt-1">This field is required</p>} */}
                                        <div className='h-[150px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                            <label htmlFor="description" className="block text-sm font-normal leading-5 text-primaryDark">
                                                Description
                                            </label>
                                            <div className="">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    type="date"
                                                    autoComplete="off"
                                                    required
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                    className="block resize-none w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        {/* {formData.description === '' && flag && <p className="text-red-500 px-3 !mt-1">This field is required</p>} */}
                                        {
                                            isEdit &&
                                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                                <label htmlFor="url" className="block text-sm font-normal leading-5 text-primaryDark">
                                                    URL
                                                </label>
                                                <div className="flex items-center justify-between w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                >
                                                    <div className='truncate'>
                                                        {formData.url}
                                                    </div>
                                                    <span
                                                        onClick={copyMessage}
                                                        className='cursor-pointer'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                                                            <g clipPath="url(#clip0_1326_6335)">
                                                                <path d="M7.28906 12H2.8125C1.77859 12 0.9375 11.1589 0.9375 10.125V3.77344C0.9375 2.73953 1.77859 1.89844 2.8125 1.89844H7.28906C8.32297 1.89844 9.16406 2.73953 9.16406 3.77344V10.125C9.16406 11.1589 8.32297 12 7.28906 12ZM2.8125 2.83594C2.29559 2.83594 1.875 3.25653 1.875 3.77344V10.125C1.875 10.6419 2.29559 11.0625 2.8125 11.0625H7.28906C7.80597 11.0625 8.22656 10.6419 8.22656 10.125V3.77344C8.22656 3.25653 7.80597 2.83594 7.28906 2.83594H2.8125ZM11.0391 8.95312V1.875C11.0391 0.841095 10.198 0 9.16406 0H3.96094C3.70203 0 3.49219 0.209839 3.49219 0.46875C3.49219 0.727661 3.70203 0.9375 3.96094 0.9375H9.16406C9.68097 0.9375 10.1016 1.35809 10.1016 1.875V8.95312C10.1016 9.21204 10.3114 9.42188 10.5703 9.42188C10.8292 9.42188 11.0391 9.21204 11.0391 8.95312Z" fill="#515F7B" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1326_6335">
                                                                    <rect width="12" height="12" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        {
                                                            isCopied &&

                                                            <div className="success-message">
                                                                Copied to clipboard!
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isEdit ?
                                                <div className="mt-4 flex justify-center gap-x-6 items-center">
                                                    <button
                                                        type="button"
                                                        onClick={() => DeleteMedia(formData.id)}
                                                        className="flex gap-x-2 py-2 px-5 w-[187px] h-[45px] items-center justify-center rounded-3xl hover:bg-[#E41D1D] border-[1px] text-[#E41D1D] border-[#E41D1D] hover:bg-opacity-[0.8] text-base  font-semibold leading-[18px] hover:text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                    >
                                                        Delete Permanently
                                                    </button>
                                                    <button
                                                        type="button"
                                                        disabled={isLoading}
                                                        onClick={() => submitFormData('update')}
                                                        className="flex gap-x-2 py-2 px-5 h-[45px] w-[187px] items-center justify-center rounded-3xl text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue border-[1px]   text-base  font-semibold leading-[18px] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                                :
                                                <div className="mt-4 flex justify-center gap-x-6 items-center">

                                                    <button
                                                        type="button"
                                                        className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                                                        onClick={closeModal}
                                                    >
                                                        Cancel
                                                    </button>

                                                    <button
                                                        type="button"
                                                        disabled={isLoading}
                                                        onClick={submitFormData}
                                                        className="w-[146px] h-[45px] py-2 text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                                                    >
                                                        Upload
                                                    </button>

                                                </div>
                                        }

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default MediaDetailModal