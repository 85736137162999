const regexLibrary = {
  strongTagsAndText: /<strong>(.*?)<\/strong>/g,
  htmlTags: /(<([^>]+)>)/gi,
  numbersPeriodFormat: /^\d+\.$/,
  parenthesesAndText: /\([^)]*\)/g,
  nonAlphaNumerical: /[^a-zA-Z0-9]/g,
  nonLowerAlphaNumerical: /[^a-z0-9]$/,
  pausesAndStops: /[,.;:]/,
  textBeforeTags: /^[^<]+/,
  textBetweenTags: />([^<]+)</g,
  textAfterTags: /<\/[^>]+>([^<]*)$/,
};

export default regexLibrary;
