import React, { useEffect, useState } from 'react'

import SearchClasses from '../searchResult/SearchClasses';

function NonSpecifiedRoute() {
    // const [data, setData] = useState({});
    const [lastUrl, setlastUrl]=useState('')
    const currentPath = window.location.pathname;
    
    useEffect(() => {
        const handleRouteChange = () => {
          const pathname = currentPath.replace(/\/+$/, '');
       
          const stringWithoutLeadingSlash = pathname.startsWith('/')
            ? pathname.substring(1)
            : pathname;
          setlastUrl(stringWithoutLeadingSlash);
        };
    
        // Attach an event listener for route changes
        window.addEventListener('popstate', handleRouteChange);
    
        // Call it once to set the initial state
        handleRouteChange();
    
        // Cleanup the event listener when the component is unmounted
        return () => {
          window.removeEventListener('popstate', handleRouteChange);
        };
      }, [currentPath]);
    return (
        <>
    
        <SearchClasses
        uniqueName={lastUrl}
        />
        </>
    )
}

export default NonSpecifiedRoute