import React, { useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Banner from '../../components/banner/Banner'
import img from '../../assets/conatctus.png'
import { Link } from 'react-router-dom'
import { SubmitContactUs } from '../../service/service'
import Swal from 'sweetalert2'
function ContactUs() {

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        comment: '',
    });
    const [isLoading ,setIsLoading] = useState(false)
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        const nameParts = formData.fullName.split(' ');
        const data = {
            f_name: nameParts.shift(),
            l_name: nameParts.join(' '),
            email: formData.email,
            comment: formData.comment

        }
        SubmitContactUs(data).then((res)=>{
            setIsLoading(false)
            if(res.status){
                setFormData({
                    fullName: '',
                    email: '',
                    comment: '',
                })
                Swal.fire({
                    icon: 'success',
                    title: res.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        })
        .catch((err)=>{
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: err.message,
                showConfirmButton: false,
                timer: 3000
            })
        })
    };
    return (
        <>
            <Header />
            <Banner title={'Contact Us'} />
            <section id='contact-us' className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                    <div className='flex flex-col-reverse items-center lg:flex-row gap-[25px]'>
                        <div className="lg:w-1/2 ">
                            <div className="contact-us-box h-[360px] md:h-[469px] rounded-3xl text-whiteGrey p-4 xs:p-8 bg-primaryDark" style={{ background: `url(${img})`, backgroundRepeat: 'no-repeat' }}>
                                <h2 className='mb-8 text-2xl md:text-[28px] font-extrabold leading-[normal]'>
                                    Contact Information
                                </h2>
                                <div className='flex gap-x-4 items-center mb-8 text-base xs:text-lg md:text-xl font-semibold leading-[normal]'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M21.6979 15.8335C21.6979 17.8602 20.0493 19.5062 18.0252 19.5062H5.97562C3.95151 19.5062 2.30287 17.8601 2.30287 15.8335V8.16652C2.30287 7.49011 2.48807 6.85482 2.80954 6.30957L8.68898 12.189C9.56859 13.0712 10.7465 13.5573 12.0016 13.5573C13.2542 13.5573 14.4321 13.0712 15.3117 12.189L21.1911 6.30957C21.5127 6.85482 21.6978 7.49007 21.6978 8.16652V15.8335H21.6979ZM18.0251 4.49382H5.97562C5.13974 4.49382 4.36813 4.77671 3.75088 5.24738L9.68948 11.1885C10.3042 11.8006 11.1246 12.1402 12.0016 12.1402C12.8761 12.1402 13.6965 11.8006 14.3112 11.1885L20.2498 5.24738C19.6326 4.77671 18.861 4.49382 18.0251 4.49382ZM18.0251 3.07669H5.97562C3.16963 3.07669 0.885742 5.36058 0.885742 8.16657V15.8335C0.885742 18.6421 3.16963 20.9234 5.97562 20.9234H18.0251C20.8311 20.9234 23.115 18.6421 23.115 15.8335V8.16652C23.115 5.36053 20.8311 3.07669 18.0251 3.07669Z" fill="#D2D6DC" />
                                        </svg>
                                    </span>

                                    <Link
                                        to="mailto:Info@insidechassidus.org"
                                    >
                                        Info@insidechassidus.org
                                    </Link>

                                </div>
                                <div className='flex gap-x-4 items-center text-base xs:text-lg md:text-xl font-semibold leading-[normal] mb-[45px]'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g clipPath="url(#clip0_515_1999)">
                                                <path d="M19.1771 2.9726C17.2599 1.05536 14.7108 -0.00050354 11.9994 -0.00050354C9.28809 -0.00050354 6.73892 1.05536 4.82173 2.9726C2.90449 4.88988 1.84863 7.43895 1.84863 10.1503C1.84863 15.6352 7.0348 20.1973 9.82101 22.6482C10.2082 22.9888 10.5426 23.283 10.8089 23.5318C11.1427 23.8435 11.5711 23.9994 11.9994 23.9994C12.4278 23.9994 12.8561 23.8435 13.1899 23.5318C13.4563 23.2829 13.7906 22.9888 14.1778 22.6482C16.964 20.1973 22.1502 15.6352 22.1502 10.1503C22.1501 7.43895 21.0943 4.88988 19.1771 2.9726ZM13.2492 21.5927C12.8535 21.9407 12.5119 22.2414 12.2302 22.5045C12.1007 22.6253 11.898 22.6253 11.7686 22.5045C11.4869 22.2413 11.1452 21.9407 10.7495 21.5926C8.13013 19.2885 3.25442 14.9995 3.25442 10.1503C3.25442 5.32835 7.17735 1.40542 11.9993 1.40542C16.8213 1.40542 20.7442 5.32835 20.7442 10.1503C20.7443 14.9995 15.8686 19.2885 13.2492 21.5927Z" fill="#D2D6DC" />
                                                <path d="M11.9997 5.29348C9.53309 5.29348 7.52637 7.30015 7.52637 9.76677C7.52637 12.2334 9.53309 14.2401 11.9997 14.2401C14.4663 14.2401 16.473 12.2334 16.473 9.76677C16.473 7.30015 14.4663 5.29348 11.9997 5.29348ZM11.9997 12.8341C10.3083 12.8341 8.93224 11.4581 8.93224 9.76672C8.93224 8.07537 10.3083 6.69931 11.9997 6.69931C13.6911 6.69931 15.0671 8.07537 15.0671 9.76672C15.0671 11.4581 13.6911 12.8341 11.9997 12.8341Z" fill="#D2D6DC" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_515_1999">
                                                    <rect width="24" height="24" fill="white" transform="translate(0 -0.000488281)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span>
                                        437 Sterling St Suite 100 Brooklyn NY 11225
                                    </span>
                                </div>
                                <div>
                                    <div className='text-base xs:text-lg font-bold text-primaryBlue'>
                                        Follow us
                                    </div>
                                    <div className='flex items-center gap-x-[27px] mt-3'>
                                        <Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                                                <g clipPath="url(#clip0_148_2039)">
                                                    <path d="M27 13.6398C27 21.1723 21.0659 27.2787 13.7443 27.2787C11.4209 27.2787 9.2383 26.6624 7.33834 25.5832L0 28L2.39212 20.6802C1.18593 18.6259 0.491277 16.2136 0.491277 13.6389C0.492158 6.10637 6.42446 0 13.7461 0C21.0668 0.00182607 27 6.10728 27 13.6398ZM13.7434 2.17485C7.59895 2.17485 2.60078 7.31888 2.60078 13.6425C2.60078 16.1516 3.38964 18.4743 4.72348 20.3643L3.33241 24.6218L7.61304 23.2111C9.37389 24.409 11.4807 25.1066 13.7434 25.1066C19.8879 25.1066 24.8879 19.9635 24.8879 13.6398C24.8896 7.31888 19.8888 2.17485 13.7434 2.17485ZM20.4382 16.7816C20.3554 16.6437 20.1388 16.5597 19.8148 16.3917C19.4917 16.2237 17.892 15.4157 17.5953 15.3061C17.296 15.1938 17.0794 15.1372 16.8628 15.4723C16.6488 15.8073 16.0255 16.5597 15.8344 16.7843C15.6452 17.008 15.4559 17.0363 15.131 16.871C14.8061 16.7012 13.7584 16.3506 12.517 15.2121C11.5512 14.3246 10.8988 13.2317 10.7104 12.8966C10.5193 12.5624 10.691 12.3816 10.8521 12.2155C10.9974 12.0648 11.177 11.8247 11.3399 11.6284C11.5028 11.4339 11.5565 11.2961 11.6648 11.0724C11.7713 10.8487 11.7176 10.6542 11.6375 10.4853C11.5565 10.3173 10.9076 8.67382 10.6355 8.00457C10.3652 7.33622 10.095 7.4467 9.90478 7.4467C9.71549 7.4467 9.49891 7.41931 9.28232 7.41931C9.06574 7.41931 8.71357 7.50057 8.41687 7.83565C8.12016 8.17074 7.28024 8.97877 7.28024 10.6241C7.28024 12.2712 8.44328 13.8589 8.60616 14.0817C8.76992 14.3036 10.8548 17.7905 14.1572 19.129C17.4615 20.4666 17.4615 20.0201 18.0566 19.9635C18.6536 19.9069 19.9795 19.1554 20.2489 18.3757C20.5201 17.5923 20.5201 16.9212 20.4382 16.7816Z" fill="#D2D6DC" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_148_2039">
                                                        <rect width="27" height="28" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Link>
                                        <Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                <path d="M26.0343 37.1429V25.152H30.0595L30.6618 20.4777H26.0332V17.4949C26.0332 16.144 26.4103 15.2206 28.3452 15.2206H30.8206V11.04C30.392 10.9829 28.9235 10.8572 27.2149 10.8572C23.6458 10.8572 21.2035 13.0343 21.2035 17.0343V20.4812H17.1692V25.1555H21.2035V37.1429H26.0298H26.0343Z" fill="#D2D6DC" />
                                            </svg>
                                        </Link>
                                        <Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                                                <path d="M36.923 14.4889C35.8857 14.9471 34.786 15.2485 33.66 15.3831C34.8477 14.6738 35.7362 13.556 36.1592 12.2389C35.044 12.9033 33.8221 13.3694 32.5477 13.6166C32.0157 13.0508 31.3733 12.6001 30.6602 12.2925C29.9471 11.9849 29.1785 11.8268 28.4019 11.8281C27.5454 11.8281 26.7 12.0217 25.9289 12.3945C25.1577 12.7672 24.4809 13.3095 23.9489 13.9808C23.4169 14.652 23.0436 15.4349 22.8568 16.2707C22.67 17.1066 22.6747 17.9739 22.8704 18.8077C18.1511 18.5654 13.9627 16.3039 11.1588 12.8654C10.656 13.734 10.3913 14.7199 10.3915 15.7235C10.3915 17.6966 11.3954 19.432 12.9184 20.4543C12.0168 20.4232 11.1352 20.1796 10.3454 19.7435V19.8127C10.3454 22.5704 12.3069 24.8666 14.9054 25.3858C14.4277 25.5185 13.9246 25.5866 13.4088 25.5866C13.0419 25.5866 12.6865 25.552 12.338 25.4874C12.7018 26.6158 13.4087 27.6026 14.3602 28.31C15.3117 29.0175 16.4603 29.4102 17.6457 29.4335C15.6329 31.0113 13.1486 31.8674 10.5911 31.8647C10.1319 31.8647 9.67958 31.8381 9.23535 31.7862C11.8341 33.4554 14.8582 34.3418 17.9469 34.3397C28.3961 34.3397 34.1123 25.6777 34.1123 18.1708C34.1123 17.9251 34.1065 17.6793 34.095 17.4358C35.2027 16.6339 36.1661 15.6358 36.9277 14.4935L36.923 14.4889Z" fill="#D2D6DC" />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2">
                            <form onSubmit={handleSubmit} className="flex flex-col  justify-between gap-6">
                                <div className='text-base xs:text-lg font-semibold leading-[normal]'>
                                    Send a message directly to the Inside Chassidus Staff. Use our website contact form to send your questions or feedback.
                                </div>
                                <div className='flex flex-col sm:flex-row justify-between gap-6'>
                                    <div className='h-[85px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                            Full Name
                                        </label>
                                        <div className="">
                                            <input
                                                id="full-name"
                                                name="fullName"
                                                type="text"
                                                placeholder='Simon Adam'
                                                autoComplete="name"
                                                required
                                                value={formData.fullName}
                                                onChange={handleChange}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                    <div className='h-[85px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="email" className="block text-sm font-normal leading-5 text-primaryDark">
                                            Email address
                                        </label>
                                        <div className="">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder='simon123@gmail.com'
                                                autoComplete="email"
                                                required
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div className='h-[226px] w-full rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                                        <label htmlFor="comment" className="block text-sm font-normal leading-5 text-primaryDark">
                                            Comment/Message
                                        </label>
                                        <div className="h-[85%]">
                                            <textarea
                                                id="comment"
                                                name="comment"
                                                type="text"
                                                placeholder='Type your comment or message here.'
                                                autoComplete="off"
                                                required
                                                value={formData.comment}
                                                onChange={handleChange}
                                                className="block w-full h-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button 
                                    disabled={isLoading}
                                    type="submit" className="bg-primaryBlue flex w-full justify-center items-center text-whiteGrey text-base sm:text-lg md:text-base lg:text-lg font-bold leading-7 px-6 py-3 sm:px-8 sm:py-[14px] md:px-4 md:py-3 lg:px-8 lg:py-[14px] rounded-3xl h-[40px] sm:h-[48px] disabled:opacity-50">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ContactUs