import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { AddAdminMedia, LogoutUser } from '../../service/service';
import { logoutAdminUser } from '../../store/slices/AdminUserSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
const baseUrl = process.env.REACT_APP_API;
function AddNewMedia({ setUploader, setIsLoading, ListMediaItems }) {
    const userData = useSelector((state) => state.adminUser.userData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    function SignOutIcon(props) {
        return (

            <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>

        )
    }
    const handleSignOut = () => {
        const token = localStorage.getItem('adminToken');
        LogoutUser(token).then((result) => {

            localStorage.removeItem('adminToken');
            localStorage.removeItem('adminData');
            navigate("/admin");
            dispatch(logoutAdminUser())
        })
            .catch((error) => {
                console.log(error.message);

            });

    };

    const [uploads, setUploads] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const copyMessage = (url) => {
        navigator.clipboard.writeText(url);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }
    const handleFileChange = async (e) => {
        const files = e.target.files;

        if (files.length === 1) {
            const file = files[0];
            const fileData = {
                url: '',
                name: file.name,
                type: file.type.split('/')[0],
                progress: 1,
                title: '',
                description: ''
            };
            setUploads([fileData])
            const [id, prefix] = await initiateMultipartUpload(file);
            const chunkSize = 5 * 1024 * 1024;
            const totalParts = Math.ceil(file.size / chunkSize);
            let parts = [];

            let chunksUploaded = 0;

            async function uploadChunksInParallel(chunks, maxConcurrentUploads) {
                let currentIndex = 0; // Tracks the next chunk to upload
                let activeUploads = 0; // Tracks the number of currently active uploads
                const results = new Array(chunks.length); // Stores the result of each upload
            
                // Used to signal completion of all uploads
                let resolveAllUploads, rejectAllUploads;
                const allUploadsDone = new Promise((resolve, reject) => {
                    resolveAllUploads = resolve;
                    rejectAllUploads = reject;
                });
            
                // Function to handle the upload of a single chunk
                const uploadNextChunk = async () => {
                    if (currentIndex < chunks.length) {
                        const index = currentIndex;
                        const chunk = chunks[index];
                        currentIndex++; // Move to the next chunk
                        activeUploads++; // Increment active uploads
            
                        try {
                            // Perform the chunk upload
                            const response = await uploadChunk(chunk.data, index + 1, chunk.id, chunk.file, chunk.prefix);
                            // Update results with the response
                            results[index] = { PartNumber: index + 1, ETag: response.data.Etag };
                            
                            // Update progress (example updating method)
                            // This may need debouncing or throttling for performance
                            //fileData.progress = Math.round(((index + 1) / chunks.length) * 100);
                            fileData.progress = Math.round((results.length / chunks.length) * 100);
                            // setUploads((prevUploads) => {
                            //     const updatedUploads = [...prevUploads];
                            //     updatedUploads[chunk.fileIndex] = fileData;
                            //     return updatedUploads;
                            // });
                            setUploads([fileData]);
                        } catch (error) {
                            rejectAllUploads(error); // Reject the allUploadsDone promise on error
                            return;
                        }
            
                        // Decrement active uploads and check if all uploads are done
                        activeUploads--;
                        if (currentIndex === chunks.length && activeUploads === 0) {
                            resolveAllUploads(); // All uploads are completed
                        } else {
                            uploadNextChunk(); // Start uploading the next chunk
                        }
                    }
                };
            
                // Start the initial set of uploads
                for (let i = 0; i < Math.min(maxConcurrentUploads, chunks.length); i++) {
                    uploadNextChunk().catch(error => {
                        console.error("Error uploading chunk:", error);
                    });
                }
            
                await allUploadsDone; // Wait for all uploads to complete
                return results; // Return the results of all uploads
            }
            
            // Preparing chunks array with necessary data for each chunk
            let chunks = [];
            for (let j = 0; j < totalParts; j++) {
                const start = j * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                chunks.push({ 
                    data: file.slice(start, end),
                    id, // Assuming `id` is available in this scope
                    file, // The current file being processed
                    prefix, // Assuming `prefix` is available in this scope
                    //fileIndex: i // Index of the file in the initial file array, for progress tracking
                });
            }

            // Assuming you have defined `maxConcurrentUploads`
            const maxConcurrentUploads = 4;
            
            // You would call this function like before:
            parts = await uploadChunksInParallel(chunks, maxConcurrentUploads);
            // Proceed with completeUpload only after this line
            
        //    // Create an array of upload promises
        //     let uploadPromises = [];
        //     for (let j = 0; j < totalParts; j++) {
        //         const start = j * chunkSize;
        //         const end = Math.min(start + chunkSize, file.size);
        //         const chunk = file.slice(start, end);

        //         // Instead of awaiting here, push the promise into the array
        //         uploadPromises.push(uploadChunk(chunk, j + 1, id, file, prefix).then(response => {
        //             chunksUploaded++;
        //             const calculatedProgress = Math.round((chunksUploaded / totalParts) * 100);
        //             fileData.progress = calculatedProgress;
        //             setUploads([fileData]); // This might need adjustment for concurrency issues
        //             return { PartNumber: j + 1, ETag: response.data.Etag };
        //         }));
        //     }

        //     // Wait for all uploads to complete
        //     parts = await Promise.all(uploadPromises);

            fileData.url = (await completeUpload(id, file.name, parts, file.type, file, prefix)).data.data;
            //   console.log([fileData])
            setUploads([fileData]);
        }
        else {
            const filesArray = Array.from(files);
            const filesData = [];
            for (let i = 0; i < filesArray.length; i++) {
                const file = filesArray[i];
                const fileData = {
                    url: '',
                    name: file.name,
                    type: file.type.split('/')[0],
                    progress: 0,
                    title: '',
                    description: ''
                };
                setUploads((prevUploads) => {
                    const updatedUploads = [...prevUploads];
                    updatedUploads[i] = fileData;
                    return updatedUploads;
                });
            }
            for (let i = 0; i < filesArray.length; i++) {
                const file = filesArray[i];
                const fileData = {
                    url: '',
                    name: file.name,
                    type: file.type.split('/')[0],
                    progress: 0,
                    title: '',
                    description: ''
                };

                const [id, prefix] = await initiateMultipartUpload(file);
                const chunkSize = 5 * 1024 * 1024;
                const totalParts = Math.ceil(file.size / chunkSize);
                let parts = [];
                let chunksUploaded = 0;

                async function uploadChunksInParallel(chunks, maxConcurrentUploads) {
                    let currentIndex = 0; // Tracks the next chunk to upload
                    let activeUploads = 0; // Tracks the number of currently active uploads
                    const results = new Array(chunks.length); // Stores the result of each upload
                
                    // Used to signal completion of all uploads
                    let resolveAllUploads, rejectAllUploads;
                    const allUploadsDone = new Promise((resolve, reject) => {
                        resolveAllUploads = resolve;
                        rejectAllUploads = reject;
                    });
                
                    // Function to handle the upload of a single chunk
                    const uploadNextChunk = async () => {
                        if (currentIndex < chunks.length) {
                            const index = currentIndex;
                            const chunk = chunks[index];
                            currentIndex++; // Move to the next chunk
                            activeUploads++; // Increment active uploads
                
                            try {
                                // Perform the chunk upload
                                const response = await uploadChunk(chunk.data, index + 1, chunk.id, chunk.file, chunk.prefix);
                                // Update results with the response
                                results[index] = { PartNumber: index + 1, ETag: response.data.Etag };
                                
                                // Update progress (example updating method)
                                // This may need debouncing or throttling for performance
                                //fileData.progress = Math.round(((index + 1) / chunks.length) * 100);
                                fileData.progress = Math.round((results.length / chunks.length) * 100);
                                     setUploads((prevUploads) => {
                                        const updatedUploads = [...prevUploads];
                                        updatedUploads[i] = fileData;
                                        return updatedUploads;
                                    });
                            } catch (error) {
                                rejectAllUploads(error); // Reject the allUploadsDone promise on error
                                return;
                            }
                
                            // Decrement active uploads and check if all uploads are done
                            activeUploads--;
                            if (currentIndex === chunks.length && activeUploads === 0) {
                                resolveAllUploads(); // All uploads are completed
                            } else {
                                uploadNextChunk(); // Start uploading the next chunk
                            }
                        }
                    };
                
                    // Start the initial set of uploads
                    for (let i = 0; i < Math.min(maxConcurrentUploads, chunks.length); i++) {
                        uploadNextChunk().catch(error => {
                            console.error("Error uploading chunk:", error);
                        });
                    }
                
                    await allUploadsDone; // Wait for all uploads to complete
                    return results; // Return the results of all uploads
                }
                
                // Preparing chunks array with necessary data for each chunk
                let chunks = [];
                for (let j = 0; j < totalParts; j++) {
                    const start = j * chunkSize;
                    const end = Math.min(start + chunkSize, file.size);
                    chunks.push({ 
                        data: file.slice(start, end),
                        id, // Assuming `id` is available in this scope
                        file, // The current file being processed
                        prefix, // Assuming `prefix` is available in this scope
                        //fileIndex: i // Index of the file in the initial file array, for progress tracking
                    });
                }
    
                // Assuming you have defined `maxConcurrentUploads`
                const maxConcurrentUploads = 4;
                
                // You would call this function like before:
                parts = await uploadChunksInParallel(chunks, maxConcurrentUploads);
                // // Start by creating an array to hold all the upload promises
                // const uploadPromises = [];

                // for (let j = 0; j < totalParts; j++) {
                //     const start = j * chunkSize;
                //     const end = Math.min(start + chunkSize, file.size);
                //     const chunk = file.slice(start, end);

                //     // Push each upload promise into the array without waiting here
                //     uploadPromises.push(uploadChunk(chunk, j + 1, id, file, prefix));
                // }

                // // Use Promise.all to wait for all upload promises to resolve
                // const uploadResults = await Promise.all(uploadPromises);

                // // Process results after all uploads are complete
                // uploadResults.forEach((response, index) => {
                //     chunksUploaded++;
                //     const calculatedProgress = Math.round((chunksUploaded / totalParts) * 100);
                //     fileData.progress = calculatedProgress;
                //     // Assuming the state update here does not depend on the order of completion
                //     // If it does, consider a different approach to batch state updates outside the loop
                //     setUploads((prevUploads) => {
                //         const updatedUploads = [...prevUploads];
                //         updatedUploads[i] = fileData;
                //         return updatedUploads;
                //     });
                //     parts.push({ PartNumber: index + 1, ETag: response.data.Etag });
                // });

                // // At this point, all parts are uploaded, and 'parts' array is complete


                fileData.url = (await completeUpload(id, file.name, parts, file.type, file, prefix)).data.data;
                filesData.push(fileData);
            }

            setUploads(filesData);
        }
    };


    const initiateMultipartUpload = async (file) => {
        const response = await axios.post(baseUrl + '/user/initiateMultipartUpload', {
            file_name: file.name,
            file_type: file.type, // MIME type
            extension: file.name.split('.').pop().toLowerCase(),

        });
        // setUploadId(response.data.upload_id);
       // return response.data.upload_id;
	return [response.data.upload_id, response.data.key];
    };

    const uploadChunk = async (chunk, partNumber, uploadId, file, prefix) => {
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('part_number', partNumber);
        formData.append('upload_id', uploadId);
        formData.append('type', file.type)
        formData.append('file_name', file.name)
	formData.append('prefix', prefix)

        return await axios.post(baseUrl + '/user/upload', formData);

    };

    const completeUpload = async (uploadId, filename, parts, type, file, prefixkey) => {
        return await axios.post(baseUrl + '/user/completeMultipartUpload', {
            upload_id: uploadId,
            file_name: filename,
            parts,
            type,
            extension: file.name.split('.').pop().toLowerCase(),
	    prefix: prefixkey
        });
    };

    const SubmitMeida = () => {
        // console.log(uploads)
        setIsLoading(true)
        AddAdminMedia({ data: uploads }).then((result) => {
            setIsLoading(false)
            if (result.status) {
                setUploader(false)
                ListMediaItems('Media uploaded successfully')
            }
        })
            .catch((error) => {
                setIsLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    showConfirmButton: false,
                    timer: 1500
                }
                )
            })
    }
    return (
        <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
            <div className='px-[22px] sm:px-8 bg-whiteGrey'>
                <div className='flex justify-between items-center text-primaryDark sm:h-[72px] text-left'>
                    <div className='flex justify-center items-center gap-3 text-2xl leading-[21px] font-semibold'>
                        <div className='hidden sm:block'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M4 6.5H14.5M4 12.5H20M4 18.5H14.5" stroke="#161E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <span>
                            Content
                        </span>

                    </div>
                    <div className='flex justify-between items-center gap-x-5'>

                        <div className="relative flex">
                            <Menu>
                                {({ open }) => (
                                    <>
                                        <Menu.Button >
                                            <div className='flex gap-x-3 items-center justify-center'>
                                                <div className='flex items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>

                                                </div>
                                                <div className='text-base font-semibold leading-[21px] text-primaryDark'>
                                                    {userData?.name}
                                                </div>
                                                <div className='flex items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M10.25 15.6365C10.1163 15.6365 9.98251 15.5854 9.88045 15.4833L4.65319 10.2561C4.44894 10.0518 4.44894 9.72109 4.65319 9.51696C4.85745 9.31284 5.1882 9.31271 5.39232 9.51696L10.25 14.3746L15.1077 9.51696C15.312 9.31271 15.6427 9.31271 15.8468 9.51696C16.051 9.72122 16.0511 10.052 15.8468 10.2561L10.6196 15.4833C10.5175 15.5854 10.3837 15.6365 10.25 15.6365Z" fill="#161E2E" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </Menu.Button>

                                        {open && (
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className="z-10 bg-white border w-56 border-gray-300 absolute right-0 top-8 mt-2 rounded-md shadow-lg focus:outline-none"
                                                >
                                                    <div className="px-1 py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    className={`${active ? 'bg-primaryDark text-whiteGrey' : 'text-primaryDark'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-base font-semibold`}
                                                                    onClick={handleSignOut}
                                                                >
                                                                    {active ? (
                                                                        <SignOutIcon
                                                                            className="mr-2 h-5 w-5 text-whiteGrey"
                                                                            aria-hidden="true"
                                                                        />
                                                                    ) : (
                                                                        <SignOutIcon
                                                                            className="mr-2 h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                    Log Out
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        )}
                                    </>
                                )}
                            </Menu>
                        </div>
                    </div>
                </div>
                <hr className='bg-[#D2D6DC] h-[1px]' />
                <div className='flex justify-between items-center text-primaryDark h-[76px] text-left sm:border-b-[1px]'>
                    <div
                        onClick={() => setUploader(false)}
                        className='flex justify-center items-center gap-2 cursor-pointer text-lg lg:text-xl leading-[21px] font-semibold'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z" fill="#161E2E" />
                            </svg>
                        </span>

                        <span>
                            Back to Media
                        </span>
                    </div>
                    <div className='text-xl font-bold'>
                        Add New Files
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={SubmitMeida}
                            disabled={uploads.length === 0}
                            className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Submit Media
                        </button>
                    </div>

                </div>
            </div>
            <div className='pr-10 pl-[31px] py-8 min-h-screen'>
                <div
                    className=' border-primaryBlue py-14 p-4 rounded-xl border-dashed border-[1px] flex flex-col justify-center items-center w-full max-h-[300px]'>
                    <label htmlFor={`fileInput`} className='flex flex-col justify-center items-center gap-4 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="68" viewBox="0 0 79 68" fill="none">
                            <path d="M41.1781 16.589L41.2822 16.6201L41.2867 16.6149C41.779 16.7039 42.2673 16.4092 42.4119 15.9209C43.73 11.4921 47.8825 8.39817 52.5089 8.39817C53.0567 8.39817 53.5009 7.95393 53.5009 7.40621C53.5009 6.85848 53.0566 6.41426 52.5089 6.41426C46.823 6.41426 42.0449 10.2 40.5107 15.3555C40.3542 15.8807 40.6533 16.4325 41.1781 16.589Z" fill="#186CCE" stroke="#F9FFF9" strokeWidth="0.3375" />
                            <path d="M64.0334 47.7432H59.0943C58.6398 47.7432 58.2711 47.3745 58.2711 46.92C58.2711 46.4655 58.6398 46.0968 59.0943 46.0968H64.0334C70.8418 46.0968 76.3814 40.5572 76.3814 33.7488C76.3814 26.9405 70.8418 21.4009 64.0334 21.4009H63.9147C63.6759 21.4009 63.449 21.2974 63.2926 21.1169C63.1362 20.9364 63.0656 20.6971 63.0997 20.4607C63.1732 19.948 63.2103 19.4329 63.2103 18.9313C63.2103 13.0308 58.4092 8.22972 52.5086 8.22972C50.2131 8.22972 48.024 8.94708 46.1776 10.3047C45.7719 10.6029 45.1956 10.4706 44.961 10.0243C39.732 0.0671616 26.0743 -1.26998 18.9935 7.39184C16.0106 11.0409 14.8386 15.7878 15.7778 20.4143C15.8812 20.9253 15.4902 21.4015 14.971 21.4015H14.6411C7.83278 21.4015 2.2932 26.9411 2.2932 33.7495C2.2932 40.5578 7.83278 46.0974 14.6411 46.0974H19.5803C20.0348 46.0974 20.4035 46.4661 20.4035 46.9206C20.4035 47.3751 20.0348 47.7438 19.5803 47.7438H14.6411C6.92479 47.7438 0.646729 41.4658 0.646729 33.7494C0.646729 26.2495 6.57722 20.1084 13.9949 19.7698C13.2982 14.9699 14.6306 10.1283 17.7187 6.34992C25.2997 -2.92455 39.8282 -1.885 45.9697 8.4567C47.929 7.22837 50.171 6.58399 52.5084 6.58399C59.6572 6.58399 65.322 12.6686 64.8264 19.7774C72.1757 20.1896 78.0275 26.2983 78.0275 33.7488C78.0275 41.4658 71.7494 47.7432 64.0331 47.7432L64.0334 47.7432Z" fill="#186CCE" />
                            <path d="M18.478 46.4551C18.478 57.8963 27.7859 67.204 39.2269 67.204C50.6681 67.204 59.9758 57.8962 59.9758 46.4551C59.9758 35.014 50.6681 25.7063 39.2269 25.7063C27.7857 25.7063 18.478 35.0141 18.478 46.4551ZM20.4623 46.4551C20.4623 36.1086 28.8802 27.6905 39.2269 27.6905C49.5734 27.6905 57.9915 36.1085 57.9915 46.4551C57.9915 56.8016 49.5734 65.2198 39.2269 65.2198C28.8804 65.2198 20.4623 56.8018 20.4623 46.4551Z" fill="#186CCE" stroke="#F9FFF9" strokeWidth="0.3375" />
                            <path d="M38.8322 54.7396C38.8322 55.1656 39.1777 55.511 39.6037 55.511C40.0295 55.511 40.3751 55.1661 40.3751 54.7396V39.07C40.3751 38.644 40.0296 38.2985 39.6037 38.2985C39.1777 38.2985 38.8322 38.644 38.8322 39.07V54.7396Z" fill="#186CCE" stroke="#186CCE" strokeWidth="0.3375" />
                            <path d="M39.6033 40.1625L35.3276 44.4383L39.6033 40.1625ZM39.6033 40.1625L43.8792 44.4384C44.0296 44.5888 44.2277 44.6643 44.4247 44.6643L39.6033 40.1625ZM34.2365 44.4384C34.5378 44.7396 35.0264 44.7398 35.3275 44.4384L39.0577 38.5261L34.2365 43.3474C33.9351 43.6487 33.9351 44.137 34.2365 44.4384ZM40.1488 38.526C39.8475 38.2248 39.3589 38.2246 39.0578 38.526L44.4247 44.6643C44.6214 44.6643 44.8195 44.5895 44.9702 44.4384C45.2715 44.137 45.2715 43.6487 44.9702 43.3474L40.1488 38.526Z" fill="#186CCE" stroke="#186CCE" strokeWidth="0.3375" />
                        </svg>
                        <span className='text-primaryBlue text-base font-bold underline'>
                            Upload or Choose Media
                        </span>
                    </label>
                    <input
                        type="file"
                        id={`fileInput`}
                        name='file'
                        accept="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={handleFileChange}
                    />

                </div>
                <div className='pt-4'>
                    {/* <div>
                        <h4 className='text-xl font-bold py-2'>Selected Files:</h4>
                        <ul>
                            {selectedFiles.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div> */}
                    <div>
                        <h4 className='text-xl font-bold py-2'>Upload Progress:</h4>
                        <ul>
                            {uploads.map((obj, index) => (
                                <li key={index} className='mb-3'>
                                    {obj?.name}: {obj.progress}% uploaded
                                    <div className='flex items-center gap-14' style={{ width: '100%', marginTop: '20px' }}>
                                        <progress className="w-full" value={obj.progress} max="100"></progress>
                                        {
                                            obj?.url !== '' &&
                                            <span
                                                onClick={() => copyMessage(obj?.url)}

                                                className="cursor-pointer flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-lg bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"

                                            >
                                                Copy URL
                                                {
                                                    isCopied &&

                                                    <div className="success-message">
                                                        Copied to clipboard!
                                                    </div>
                                                }
                                            </span>
                                        }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default AddNewMedia