import React, { useEffect, useState } from 'react'
import img1 from '../../assets/david.png';
import img2 from '../../assets/yosef.png';
import img3 from '../../assets/chaim.png';
import img4 from '../../assets/michel.png';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { showTeamMember } from '../../service/service';
function Section3() {
    const [loading, setLoading] = useState(true);
    const [teamMember, setTeamMember] = useState([]);
    useEffect(() => {
        showTeamMember().then((result) => {
            if (result.status) {
                setTeamMember(result.data)
                setLoading(false)
            }
        })
    }, [])

    const team = [
        {
            id: 1,
            img: img1,
            name: 'David Shanske',
            title: 'Software Engineer',
            description: 'David chose and set up the Joomla! content management system for Inside Chassidus. He also taught the webmaster how to use it!',
        },
        {
            id: 2,
            img: img2,
            name: 'Yosef Vinisky',
            title: 'Advisor',
            description: `Yosef took the critical step of establishing rabbipaltiel.com and, made Rabbi Paltiel’s vision of a permanent audio class archive into a reality.`,
        },
        {
            id: 3,
            img: img3,
            name: 'Chaim Binyamin Arias',
            title: 'Software Engineer',
            description: `Chaim Binyamin undertook the massive task of loading all of the original content of rabbipaltiel.com onto the internet.`,
        },
        {
            id: 4,
            img: img4,
            name: 'Michael Snoyman',
            title: 'Database Developer',
            description: `Michael designed and created the rabbipaltiel.com database structure, website design, and a data entry interface.`,
        },
    ]
    return (
        <section id='about-section-3' className='px-2 xs:px-5 py-8 md:py-[60px]'>
            <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto'>
                <div className='flex flex-col items-center gap-8'>
                    <div className="">
                        <h2 className='text-primaryDark leading-[normal] font-extrabold text-2xl md:text-4xl mb-4'>
                            Inside Chassidus Team
                        </h2>
                        <div className='text-primaryDark leading-[normal] font-semibold text-base xs:text-lg space-y-3 text-justify'>
                            <p>
                                Over the past seven years, many people have given their time and energy to the project of bringing Rabbi Paltiel’s recorded classes to the public through the internet. Thank you all and may Hashem bless you and your families.
                            </p>

                        </div>

                    </div>

                </div>
                <div className='vertical-scrollbar pt-3 lg:pt-9 flex justify-between gap-4  2xl:gap-4 max-w-[1166px] mx-auto overflow-x-auto'>
                    {
                        loading ?
                            <>
                                {team.slice(0, 4).map((obj) => (
                                    <div key={obj.id} className='flex- flex-col w-[263px] xs:w-[274px] 2xl:max-w-[300px]  rounded-[10.68px]'>
                                        <div className=' flex justify-center items-center w-[263px] xs:w-[274px] 2xl:max-w-[300px]'>
                                            <div className=''>
                                                <Skeleton height={200} width={250} /> {/* Replace with img tag */}
                                            </div>
                                        </div>
                                        <div className='h-[220px] md:h-[232px] text-whiteGrey rounded-[10.68px] py-6 px-[10px] space-y-2 -mt-4'>
                                            <div className='text-lg md:text-xl font-extrabold truncate max-w-full'>
                                                <Skeleton width={150} /> {/* Replace with obj.name */}
                                            </div>
                                            <div className='text-base font-semibold'>
                                                <Skeleton width={100} /> {/* Replace with obj.title */}
                                            </div>
                                            <div className='text-sm md:text-base font-normal text-[#D2D6DC]'>
                                                <Skeleton count={2} /> {/* Replace with obj.description */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            :
                            <>
                                {teamMember.map((obj) => (
                                    <div key={obj.id} className='flex- flex-col w-[263px] xs:w-[274px] 2xl:max-w-[300px] bg-[#EAF4FF] rounded-3xl'>
                                        <div className=' flex justify-center items-center w-[263px] xs:w-[274px] 2xl:max-w-[300px]'>
                                            <div className='p-4 h-[224px]'>

                                                <img
                                                    src={obj.url}
                                                    alt="A user avatar"
                                                    className='rounded-t-lg h-full object-cover'
                                                />

                                            </div>
                                        </div>
                                        <div className='h-[220px] md:h-[232px] bg-primaryDark text-whiteGrey rounded-3xl py-6 px-4 space-y-2 -mt-4'>
                                            <div className='text-lg md:text-xl font-extrabold truncate max-w-full'>
                                                {obj.title}
                                            </div>
                                            <div className='text-base font-semibold'>
                                                {obj.designation}
                                            </div>
                                            <div className='text-sm md:text-base font-normal text-[#D2D6DC] line-clamp-5'>
                                                {obj.description}
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </>
                    }

                </div>
            </div>
        </section>
    )
}

export default Section3