import React, { useEffect, useState } from 'react'
import { showRecentlyPlayed } from '../../../service/service';
// import ReactPlayer from 'react-player'
import { useRef } from 'react';
// import CustomMediaPlayer from '../../mediaPlayer/CustomMediaPlayer';
import Skeleton from 'react-loading-skeleton';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { ForwardIcon, LoopIcon, LoopOffIcon, PauseIcon, PlayIcon, RewindIcon, VolumeIcon, VolumeMuteIcon } from '../../../assets/icon-file';
import { Bars } from 'react-loader-spinner';
function RecentlyPlayed() {
    const audioPlayer = useRef(null);
    const [music, setMusic] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false)
    const [showPlayer, setShowPlayer] = useState(false)
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true);
    const [currentTrack, setTrackIndex] = React.useState(0)
    const [playlist, setplaylist] = useState('')
    // useEffect(() => {
    //     if (audioPlayer.current && isPlaying) {
    //       audioPlayer.current.audio.current.play();
    //     }
    //   }, [isPlaying, currentTrack]);

    const handleClickNext = () => {
        // console.log('click next')
        setTrackIndex((currentTrack) =>
            currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        );
        // console.log(playlist[currentTrack])
    };
    const handleClickPrevious = () => {
        setTrackIndex((currentTrack) =>
            currentTrack > 0 ? currentTrack - 1 : playlist.length - 1
        );
    };

    const handleEnd = () => {

        handleClickNext()
    }
    const handlePlay = (index) => {

        setTrackIndex(index)
        if (audioPlayer.current) {
            audioPlayer.current.audio.current.play(); // Play the audio
            setIsPlaying(true);
        }
    };

    const handlePause = () => {
        if (audioPlayer.current) {
            audioPlayer.current.audio.current.pause(); // Pause the audio
            setIsPlaying(false);
        }
    };
    useEffect(() => {

        showRecentlyPlayed().then((result) => {
            if (result.status) {

                const updateData = result.data?.map((item) => {
                    return {
                        id: item.id,
                        cateory_id: item.c_id,
                        url: item?.url,
                        title: item?.title,
                        description: ''
                    }
                })
                // console.clear()
                // console.log(updateData)
                setMusic(updateData)
                setplaylist(updateData)
                setTimeout(() => {
                    handlePause()
                }, 100);
               
            }
            setLoading(false)
           
        })
        .catch((err)=>{
            setLoading(false)
            console.log(err.message)
        })

        setUserData(JSON.parse(localStorage.getItem('loginData')))
    }, [])


    return (
        <>
            <section id='recently-played' className='pt-4 sm:pt-[32px] px-2 xs:px-5'>
                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto '>
                    <div className='text-base sm:text-2xl leading-[normal] font-semibold'>
                        <strong className='mr-2'>
                            Welcome!
                        </strong>
                        {userData?.name}
                    </div>
                    <>
                        {
                            loading ?
                                <div className='mt-4 md:p-8 md:bg-[#F3F3F3] min-h-[300px] rounded-[32px]'>
                                    <h2 className='text-primaryDark text-[22px] lg:text-[40px] leading-[normal] font-extrabold'>
                                        Recently played
                                    </h2>

                                    <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>

                                        <div><Skeleton height={70} /></div>
                                        <div><Skeleton height={70} /></div>
                                        <div><Skeleton height={70} /></div>
                                    </div>
                                    <button
                                        type="button"

                                        className="flex gap-x-1 h-10 lg:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-8 py-1.5 text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    >
                                        Explore more
                                        <span aria-hidden="true" className='text-[13px]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z" fill="#F4FBF7" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                :
                                <>
                                    {
                                        music.length > 0 &&
                                        <>
                                            <div className='mt-4 md:p-8 md:bg-[#F3F3F3] min-h-[300px] rounded-[32px]'>
                                                <h2 className='text-primaryDark text-[22px] lg:text-[40px] leading-[normal] font-extrabold'>
                                                    Recently played
                                                </h2>

                                                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>

                                                    {
                                                        music?.slice(0, 3).map((obj, index) => (
                                                            <div key={obj.id}>
                                                                <div className='rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                                                    <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                                                        <div className='icon-parent h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] bg-whiteGrey flex items-center justify-center rounded-lg'>
                                                                            <span className='music-icon'>
                                                                                {
                                                                                    isPlaying && currentTrack === index ?
                                                                                        <Bars
                                                                                            height="30"
                                                                                            width="30"
                                                                                            color="#186CCE"
                                                                                            ariaLabel="bars-loading"
                                                                                            wrapperStyle={{}}
                                                                                            wrapperClass=""
                                                                                            visible={true}
                                                                                        /> :
                                                                                        <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                                                            <g clipPath="url(#clip0_326_587)">
                                                                                                <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_326_587">
                                                                                                    <rect width="32" height="32" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                }
                                                                            </span>
                                                                            <div className='play-pause-icon h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] bg-primaryBlue flex items-center justify-center rounded-lg'>
                                                                                <button>
                                                                                    {
                                                                                        isPlaying && currentTrack === index ?
                                                                                            <svg
                                                                                                onClick={handlePause}
                                                                                                xmlns="http://www.w3.org/2000/svg" width="16" height="23" viewBox="0 0 16 23" fill="none">
                                                                                                <path d="M12.7 0C11.9043 0 11.1412 0.347678 10.5786 0.966548C10.016 1.58542 9.69995 2.42479 9.69995 3.3V18.7C9.69995 19.5752 10.016 20.4146 10.5786 21.0335C11.1412 21.6523 11.9043 22 12.7 22C13.4956 22 14.2587 21.6523 14.8213 21.0335C15.3839 20.4146 15.7 19.5752 15.7 18.7V3.3C15.7 2.42479 15.3839 1.58542 14.8213 0.966548C14.2587 0.347678 13.4956 0 12.7 0ZM13.7 18.7C13.7 18.9917 13.5946 19.2715 13.4071 19.4778C13.2195 19.6841 12.9652 19.8 12.7 19.8C12.4347 19.8 12.1804 19.6841 11.9928 19.4778C11.8053 19.2715 11.7 18.9917 11.7 18.7V3.3C11.7 3.00826 11.8053 2.72847 11.9928 2.52218C12.1804 2.31589 12.4347 2.2 12.7 2.2C12.9652 2.2 13.2195 2.31589 13.4071 2.52218C13.5946 2.72847 13.7 3.00826 13.7 3.3V18.7ZM4.69995 0C3.9043 0 3.14124 0.347678 2.57863 0.966548C2.01602 1.58542 1.69995 2.42479 1.69995 3.3V18.7C1.69995 19.5752 2.01602 20.4146 2.57863 21.0335C3.14124 21.6523 3.9043 22 4.69995 22C5.4956 22 6.25866 21.6523 6.82127 21.0335C7.38388 20.4146 7.69995 19.5752 7.69995 18.7V3.3C7.69995 2.42479 7.38388 1.58542 6.82127 0.966548C6.25866 0.347678 5.4956 0 4.69995 0ZM5.69995 18.7C5.69995 18.9917 5.59459 19.2715 5.40706 19.4778C5.21952 19.6841 4.96517 19.8 4.69995 19.8C4.43473 19.8 4.18038 19.6841 3.99284 19.4778C3.80531 19.2715 3.69995 18.9917 3.69995 18.7V3.3C3.69995 3.00826 3.80531 2.72847 3.99284 2.52218C4.18038 2.31589 4.43473 2.2 4.69995 2.2C4.96517 2.2 5.21952 2.31589 5.40706 2.52218C5.59459 2.72847 5.69995 3.00826 5.69995 3.3V18.7Z" fill="#F9F9F9" />
                                                                                                <path d="M5.69995 18.7C5.69995 18.9917 5.59459 19.2715 5.40706 19.4778C5.21952 19.6841 4.96517 19.8 4.69995 19.8C4.43473 19.8 4.18038 19.6841 3.99284 19.4778C3.80531 19.2715 3.69995 18.9917 3.69995 18.7V3.3C3.69995 3.00826 3.80531 2.72847 3.99284 2.52218C4.18038 2.31589 4.43473 2.2 4.69995 2.2C4.96517 2.2 5.21952 2.31589 5.40706 2.52218C5.59459 2.72847 5.69995 3.00826 5.69995 3.3V18.7Z" fill="#F9F9F9" />
                                                                                                <path d="M13.7 18.7C13.7 18.9917 13.5946 19.2715 13.4071 19.4778C13.2195 19.6841 12.9652 19.8 12.7 19.8C12.4347 19.8 12.1804 19.6841 11.9928 19.4778C11.8053 19.2715 11.7 18.9917 11.7 18.7V3.3C11.7 3.00826 11.8053 2.72847 11.9928 2.52218C12.1804 2.31589 12.4347 2.2 12.7 2.2C12.9652 2.2 13.2195 2.31589 13.4071 2.52218C13.5946 2.72847 13.7 3.00826 13.7 3.3V18.7Z" fill="#F9F9F9" />
                                                                                            </svg>
                                                                                            :
                                                                                            <svg
                                                                                                onClick={() => handlePlay(index)}
                                                                                                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                                <path d="M1.72205 17.5199C1.40692 17.5199 1.0935 17.4338 0.816253 17.2633C0.566857 17.1086 0.361027 16.8928 0.218206 16.6364C0.0753836 16.38 0.000283881 16.0914 0 15.7979V2.02165C0 1.4241 0.309969 0.86961 0.816253 0.556201C1.0662 0.402528 1.35112 0.314948 1.64424 0.301693C1.93735 0.288439 2.22901 0.349946 2.49181 0.480432L16.2682 7.36856C16.5543 7.51183 16.7948 7.73187 16.9629 8.00407C17.131 8.27626 17.22 8.58986 17.22 8.90977C17.22 9.22968 17.131 9.54328 16.9629 9.81548C16.7948 10.0877 16.5543 10.3077 16.2682 10.451L2.49181 17.3391C2.25072 17.4597 1.9838 17.5199 1.72205 17.5199Z" fill="#F9F9F9" />
                                                                                            </svg>

                                                                                    }</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='text-primaryDark select-none'>
                                                                            <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                                                            <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8 select-none'>
                                                                        {/* <div className='text-[10px] xs:text-xs sm:text-base font-bold leading-[normal] text-primaryDark'>1:05:30</div> */}
                                                                        <div className='cursor-pointer'>
                                                                            {/* <svg className='w-[20px] h-[20px] sm:w-[24px] sm:h-[24px]' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 7C12.3956 7 12.7822 6.8827 13.1111 6.66294C13.44 6.44318 13.6964 6.13082 13.8478 5.76537C13.9991 5.39992 14.0387 4.99778 13.9616 4.60982C13.8844 4.22186 13.6939 3.86549 13.4142 3.58579C13.1345 3.30608 12.7781 3.1156 12.3902 3.03843C12.0022 2.96126 11.6001 3.00087 11.2346 3.15224C10.8692 3.30362 10.5568 3.55996 10.3371 3.88886C10.1173 4.21776 10 4.60444 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM12 17C11.6044 17 11.2178 17.1173 10.8889 17.3371C10.56 17.5568 10.3036 17.8692 10.1522 18.2346C10.0009 18.6001 9.96126 19.0022 10.0384 19.3902C10.1156 19.7781 10.3061 20.1345 10.5858 20.4142C10.8655 20.6939 11.2219 20.8844 11.6098 20.9616C11.9978 21.0387 12.3999 20.9991 12.7654 20.8478C13.1308 20.6964 13.4432 20.44 13.6629 20.1111C13.8827 19.7822 14 19.3956 14 19C14 18.4696 13.7893 17.9609 13.4142 17.5858C13.0391 17.2107 12.5304 17 12 17ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z" fill="#161E2E" />
                        </svg> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ))
                                                    }
                                                </div>
                                                <button
                                                    type="button"

                                                    className="flex gap-x-1 h-10 lg:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-8 py-1.5 text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                >
                                                    Explore more
                                                    <span aria-hidden="true" className='text-[13px]'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z" fill="#F4FBF7" />
                                                        </svg>
                                                    </span>
                                                </button>

                                            </div>
                                            <div className='text-[22px] lg:text-[40px] font-extrabold leading-[normal] text-primaryDark text-center pt-4 sm:pt-8'>
                                                Discover
                                            </div>
                                        </>
                                    }
                                </>

                        }
                    </>
                </div>
            </section>
            <div className={`${isPlaying ? 'block' : 'hidden'} w-full fixed bottom-0 left-0 z-[2] bg-[#1D283E] `}>
                <section className='py-2 sm:py-[20px] px-2 xs:px-5'>
                    <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto '>
                        <div className='md:h-[68px] sm:h-[95px] flex justify-between'>
                            <div className='flex flex-col md:flex-row items-center justify-center gap-x-2 sm:gap-x-4 w-full'>
                                <div className='flex gap-x-3 w-[100%] md:w-[30%] mb-2 md:mb-0'>
                                    <div className='icon-parent h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] bg-whiteGrey flex items-center justify-center rounded-lg'>
                                        <span className=''>
                                            {
                                                <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <g clipPath="url(#clip0_326_587)">
                                                        <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_326_587">
                                                            <rect width="32" height="32" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            }
                                        </span>

                                    </div>
                                    <div className='text-whiteGrey select-none flex-1'>
                                        <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{playlist[currentTrack]?.title}</div>
                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{playlist[currentTrack]?.description !== '' ? playlist[currentTrack]?.description : 'No description'}</div>
                                    </div>
                                </div>
                                <div className='w-[100%] md:w-[40%]'>

                                    <AudioPlayer
                                        ref={audioPlayer}
                                        volume="0.5"
                                        src={playlist[currentTrack]?.url}
                                        showSkipControls
                                        onClickNext={handleClickNext}
                                        onClickPrevious={handleClickPrevious}
                                        onEnded={handleEnd}
                                        onPlaying={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                        onPlay={() => setShowPlayer(true)}
                                        customIcons={{
                                            play: <PlayIcon className='h-[35px]' />,
                                            pause: <PauseIcon className='h-[35px]' />,
                                            volume: <VolumeIcon />,
                                            volumeMute: <VolumeMuteIcon />,
                                            forward: <ForwardIcon />,
                                            rewind: <RewindIcon />,
                                            loop: <LoopIcon />,
                                            loopOff: <LoopOffIcon />

                                        }}
                                    />
                                </div>
                                <div className='md:w-[30%]'>
                                    {/* <button onClick={handlePlay}>Play</button>
                                    <button onClick={handlePause}>Pause</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RecentlyPlayed


