import { Disclosure } from '@headlessui/react'
import CheckboxTree from '../NestedCheckbox/NestedCheckbox';
import { useDispatch } from 'react-redux';
// import { updateAdminUser } from '../../store/slices/AdminUserSlice';
import {   CategoriesMenuDropdown, SearchCategoriesMenuDropdown } from '../../service/service';
import { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { DropdownIcon } from '../../assets/icon-file';
function MenuSideNave({ 
    // formData,
     setCategories, categories, parentItem, setParentItem ,searchCategory, setSearchCategory,menuItemsIds}) {

    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selected, setSelected] = useState('')
    const [flatArray, setFlatArray] = useState([])

    const [query, setQuery] = useState('')

    const filteredPeople =
        query === ''
            ? parentItem
            : parentItem.filter((obj) =>
                obj.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )



    // const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0); // Track pagination

    const fetchMoreData = (searchReset) => {
    // setSelectedCategory([])
        if(searchReset){
            CategoriesMenuDropdown(0, 500).then((result) => {
                if (result.status) {
                            setFlatArray(result?.data.data);
                            setHasMore(true)
                           setPage(1)
                        
                }
            })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                });
        }
        else{

            CategoriesMenuDropdown(page, 500).then((result) => {
                if (result.status) {
                    if (result?.data.data?.length === 0) {
                        setHasMore(false); // No more data to load
                    }
                    else {
                        //   setFlatArray(result.data.data)
                            setFlatArray([...flatArray, ...result?.data.data]);
                            setPage(page + 1); // Increment page for the next API call
                        
                    }
    
                }
            })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                });
        }
    };
    useEffect(() => {
        // showDropdown()
        fetchMoreData()

        // removeObjects(removeIds)
    }, [])
  
    // const showDropdown = () => {


    //     CategoriesMenuDropdown(0, 1000).then((result) => {
    //         if (result.status) {


    //             setFlatArray(result.data.data)

    //         }
    //     }
    //     )
    // }

    // function flattenNestedArray(arr) {
    //     const result = [];

    //     function flatten(obj, depth) {
    //         const index = depth.join('.');
    //         result.push({ index, id: obj.id, name: obj.name, level: depth.length - 1 });

    //         if (obj.children && obj.children.length > 0) {
    //             obj.children.forEach((child, childIndex) =>
    //                 flatten(child, depth.concat(childIndex + 1))
    //             );
    //         }
    //     }

    //     arr.forEach((item, index) => flatten(item, [index + 1]));

    //     return result;
    // }

    const handleCheckboxChange = (checkedItems) => {

        setSelectedCategory(checkedItems)
        // console.log(checkedItems)
        // dispatch(updateAdminUser({
        //     editorFormData: {
        //         ...formData,
        //         categories: checkedItems
        //     }
        // }));
    };
    const [filteredCategory, setfilteredCategory] = useState([]);
    
    // const filterData = (items, query) => {
    //     return items?.filter((item) => {
    //         const isMatch = item.name.toLowerCase().includes(query.toLowerCase());
    //         const hasMatchingChildren = item.children && item.children.length > 0 && filterData(item.children, query).length > 0;

    //         return isMatch || hasMatchingChildren;
    //     });
    // };
    useEffect(() => {

    const newdata= flatArray.filter(item=>!menuItemsIds.includes(item.id));
    setfilteredCategory(newdata);
    //   console.log(newdata)
    }, [flatArray]);

    const performSearch = (searchTerm) => {
        SearchCategoriesMenuDropdown(0, 500,searchTerm).then((result) => {
            if (result.status) {
                setFlatArray(result?.data.data);
            }
        })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });

    };
    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchCategory !== '') {
                performSearch(searchCategory);
                
            }
            else{
               fetchMoreData(true)
            }
           
        }, 1000)

        return () => clearTimeout(getData)
    }, [searchCategory])

    const removeObjects = (idsToRemove) => {

        const updatedData = [...flatArray];
        const newData = updatedData.filter(item => ![...idsToRemove].includes(item.id));
        setFlatArray(newData);
        

    };

    const AddToMenu = () => {
        if (selected) {

            const ParentData = [...categories];

            const targetObject = ParentData.find(item => item.id == selected.id);

            if (targetObject) {

                const filteredItems = flatArray.filter((item) => selectedCategory.includes(item.id));
                const updatedData = filteredItems.map((item, index) => {
                    return {
                        id: item.id.toString(),
                        name: item.name,
                        type: 'category',
                        // order_id:index+1,
                        items: []

                    }
                })
                targetObject.items = [...targetObject.items, ...updatedData]

                setCategories(ParentData)
                setSelected('')
            }
        }
        else {
            const filteredItems = flatArray.filter((item) => selectedCategory.includes(item.id));
            const updatedData = filteredItems.map((item, index) => {
                return {
                    id: item.id.toString(),
                    name: item.name,
                    type: 'category',
                    // order_id:index+1,
                    items: []

                }
            })
            setCategories([...categories, ...updatedData])
            setParentItem([...parentItem, ...updatedData])
        }
        removeObjects(selectedCategory)
        
        setSelectedCategory([])
    }
    return (
        <>

            <div className='p-4'>
                <Disclosure defaultOpen>

                    <>
                        <div className='text-left text-base text-primaryDark font-bold'>Parent Category</div>

                        <div className='h-[85px] my-5 rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                            <div className='flex gap-x-1 items-center'>
                                <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                    Select Parent Category
                                </label>

                            </div>
                            <div className="">
                                <Combobox value={selected} onChange={setSelected}>
                                    <div className="relative">
                                        <div className="relative ">
                                            <Combobox.Input
                                                className="w-full border-none h-[43px] bg-transparent py-1.5 pr-10 text-left focus:outline-none sm:text-lg sm:leading-normal font-semibold"
                                                displayValue={(person) => person.name}
                                                onChange={(event) => setQuery(event.target.value)}
                                            />
                                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                <DropdownIcon
                                                    aria-hidden="true"
                                                />
                                            </Combobox.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            afterLeave={() => setQuery('')}
                                        >
                                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base  focus:outline-none sm:text-sm">
                                                {filteredPeople.length === 0 && query !== '' ? (
                                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                        Nothing found.
                                                    </div>
                                                ) : (
                                                    filteredPeople.map((person) => (
                                                        <Combobox.Option
                                                            key={person.id}
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primaryBlue text-white' : 'text-gray-900'
                                                                }`
                                                            }
                                                            value={person}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span
                                                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                            }`}
                                                                    >
                                                                        {person.name}
                                                                    </span>
                                                                    {selected ? (
                                                                        <span
                                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                }`}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))
                                                )}
                                            </Combobox.Options>
                                        </Transition>
                                    </div>
                                </Combobox>
                            </div>
                        </div>
                    </>

                </Disclosure>
                <Disclosure defaultOpen>

                    <>
                        <div className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                            <span>Category</span>

                        </div>
                        <Disclosure.Panel className="pt-4 pb-6 text-sm text-primaryDark">
                            <div className='hidden lg:flex justify-between items-center border-[1px] border-[#D2D6DC] rounded-3xl pl-5 pr-2 w-full h-[40px] mb-4'>
                                <input
                                    value={searchCategory}
                                    onChange={(e) => setSearchCategory(e.target.value)}
                                    className='bg-transparent outline-none' type="text" placeholder='Search' />
                                <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
                                    <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                            <CheckboxTree data={filteredCategory} onChange={handleCheckboxChange} value={[]} hasMore={hasMore} fetchMoreData={fetchMoreData} />
                        </Disclosure.Panel>
                    </>
                    {/* )} */}
                </Disclosure>

                <button
                    type="button"
                    onClick={AddToMenu}

                    className="fixed bottom-5 mx-auto flex w-[330px] z-10 h-8 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-sm font-bold leading-[28px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                >
                    Add to menu
                </button>
            </div>

        </>
    )
}

export default MenuSideNave