// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userDataSlice = createSlice({
  name: 'userdata',
  initialState: {
    userdata: null, // User data
  },

  reducers: {
    setUserdata: (state, action) => {
        return {
          ...state,
          userdata: action.payload.userdata,
        
        };
      },
    logoutUserData:(state)=>{
        return {
            userdata: null, // User data
           
          }
    },
    updateUserData: (state, action) => {
      const dynamicKey = Object.keys(action.payload)[0]; // Get the dynamic key
      return {
        ...state,
        [dynamicKey]: action.payload[dynamicKey] // Use computed property name to set the dynamic key
      };
    },
    
  },
});

export const { setUserdata,logoutUserData,updateUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
