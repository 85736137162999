import React, { Fragment, useState } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import DashboardSideNav from '../../adminComponents/DashboardComponents/DashboardSideNav'
import DashboardHeader from '../../adminComponents/DashboardComponents/DashboardHeader'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import MediaConatiner from '../../adminComponents/MediaLibrary/MediaConatiner'
import MediaDetailModal from '../../adminComponents/MediaLibrary/MediaDetailModal'
import { AdminHeaderSearch, AdminListMedia } from '../../service/service'
import { Listbox, Transition } from '@headlessui/react'
import Swal from 'sweetalert2'
import AddNewMedia from '../../adminComponents/MediaLibrary/AddNewMedia'
const media = [
    { name: 'All' },
    { name: 'Video' },
    { name: 'Audio' },
    { name: 'Image' },
    { name: 'Document' },
]
function MediaLibrary() {
    const [selected, setSelected] = useState(media[0])
    const [isLoading, setIsLoading] = useState(false);
    const userPermissions = useSelector((state) => state.adminUser.userPermissions);
    const [ResponseData, setResponseData] = useState([])
    const [DataArray, setDataArray] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [formData, setFormData] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [noData, setNoData] = useState(false)
    const [Uploader,setUploader]= useState(false)
    const openModal = (obj) => {
        setSelectedFile(null)
        if (obj !== '') {
            setIsEdit(true)
            setFormData({
                id: obj.id,
                name: obj.name,
                title: obj.title ?? obj.name,
                description: obj.description ?? '',
                type: obj.type,
                url: obj.url
            })
            //    console.log(obj)
            setIsOpen(true)
        }
        else {
            
            setIsEdit(false)
            setFormData({
                name: '',
                title: '',
                description: '',
                type: '',
                url: ''
            })
            setUploader(true)
        }
       

    }
    useEffect(() => {
        // console.log(selected)
        if (selected.name === 'All') {
            const updatedData = ResponseData?.map((item) => item)
            setDataArray(updatedData)
        }
        else if (selected.name === 'Video') {
            const updatedData = ResponseData?.filter((item) => item.type === 'video')
            setDataArray(updatedData)
        }
        else if (selected.name === 'Image') {
            const updatedData = ResponseData?.filter((item) => item.type === 'image')
            setDataArray(updatedData)
        }
        else if (selected.name === 'Audio') {
            const updatedData = ResponseData?.filter((item) => item.type === 'audio')
            setDataArray(updatedData)
        }
        else {
            const updatedData = ResponseData?.filter((item) => item.type !== 'video' && item.type !== 'image' && item.type !== 'audio')
            setDataArray(updatedData)
        }
    }, [selected])
    useEffect(() => {
        ListMediaItems()
    }, [])
    const ListMediaItems = (message) => {
        setIsLoading(true)
        AdminListMedia()
        .then((result) => {
            setIsLoading(false)
            if (result.status) {
                // console.log(result.data.data)
                setDataArray(result.data.data)
                setResponseData(result.data.data)
                if (result.data.data.length === 0) {
                    setNoData(true)
                }
                else {
                    setNoData(false)
                }
                setSelected(media[0])
                if (message) {
                    Swal.fire({
                        icon: 'success',
                        title: message,
                        showConfirmButton: false,
                        timer: 1500
                    }
                    )
                }
            }
        })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                    showConfirmButton: false,
                    timer: 1500
                }
                )
            })
    }

    const buttonElement =
        
        <div className='flex gap-x-4'>

            <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                    <Listbox.Button className="flex gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-primaryBlue hover:bg-opacity-[0.9] text-sm md:text-base font-normal leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                        <span className="block truncate">{selected.name}</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.25 15.6365C10.1163 15.6365 9.98251 15.5854 9.88045 15.4833L4.65319 10.2561C4.44894 10.0518 4.44894 9.72109 4.65319 9.51696C4.85745 9.31284 5.1882 9.31271 5.39232 9.51696L10.25 14.3746L15.1077 9.51696C15.312 9.31271 15.6427 9.31271 15.8468 9.51696C16.051 9.72122 16.0511 10.052 15.8468 10.2561L10.6196 15.4833C10.5175 15.5854 10.3837 15.6365 10.25 15.6365Z" fill="#186CCE" />
                        </svg></span>

                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[222px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {media.map((item, itemIdx) => (
                                <Listbox.Option
                                    key={itemIdx}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-primaryDark'
                                        }`
                                    }
                                    value={item}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${selected ? 'font-semibold' : 'font-semibold'
                                                    }`}
                                            >
                                                {item.name}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {
                userPermissions?.includes('add_gallery') &&
                <button
                    type="button"
                    onClick={() => openModal('')}
                    className="flex gap-x-2 px-6 h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                    <span aria-hidden="true" className='text-[13px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                            <g clipPath="url(#clip0_966_2238)">
                                <path d="M10.9453 6.44531H7.05469V2.55469C7.05469 1.9722 6.58249 1.5 6 1.5C5.41751 1.5 4.94531 1.9722 4.94531 2.55469V6.44531H1.05469C0.472195 6.44531 0 6.91751 0 7.5C0 8.08249 0.472195 8.55469 1.05469 8.55469H4.94531V12.4453C4.94531 13.0278 5.41751 13.5 6 13.5C6.58249 13.5 7.05469 13.0278 7.05469 12.4453V8.55469H10.9453C11.5278 8.55469 12 8.08249 12 7.5C12 6.91751 11.5278 6.44531 10.9453 6.44531Z" fill="#F9F9F9" />
                            </g>
                            <defs>
                                <clipPath id="clip0_966_2238">
                                    <rect width="12" height="13" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    Add New File
                </button>
            }

        </div>;

const [searchTerm, setSearchTerm] = useState('');
const performSearch = (searchTerm) => {

  AdminHeaderSearch('search-media', searchTerm,0, 100)
  .then((result) => {
    setIsLoading(false)
    if (result.status) {
        // console.log(result.data.data)
        setDataArray(result.data.data)
        setResponseData(result.data.data)
        if (result.data.data.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
        setSelected(media[0])
      
    }
})

};
useEffect(() => {
  const getData = setTimeout(() => {
    if (searchTerm !== '') {
      performSearch(searchTerm);
    }
    if (searchTerm === '') {

        ListMediaItems()
    }
  }, 1000)

  return () => clearTimeout(getData)
}, [searchTerm])
const inputElement = <div className='hidden lg:flex justify-between items-center border-[1px] border-[##D2D6DC] rounded-3xl pl-5 pr-2 w-[272px] h-[44px]'>
  <input value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    className='bg-transparent outline-none' type="text" placeholder='Search' />
  <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
    <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
    </svg>
  </div>
</div>
    return (
        <>
            {
                isLoading &&
                <div className="overlay">
                    <div className="loaderImage-container">
                        <ThreeCircles
                            height="100"
                            width="100"
                            color="#F9F9F9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    </div>
                </div>
            }
            <div className='bg-whiteGrey'>
                <DashboardSideNav />
                {
                    Uploader ?
                    <AddNewMedia 
                    setUploader={setUploader}
                    setIsLoading={setIsLoading}
                    ListMediaItems={ListMediaItems}
                    />
                    :
                <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                    <DashboardHeader title={'Content'}
                        headerButton={buttonElement}
                        subtitle={'Media Library'}
                        Headerinput={inputElement}
                        />
                    <MediaConatiner
                        DataArray={DataArray}
                        openModal={openModal}
                        ListMediaItems={ListMediaItems}
                        noData={noData}
                        setIsLoading={setIsLoading}
                    // setSliderArray={setSliderArray}
                    // PublishNewSlide={PublishNewSlide}
                    // DeleteSlide={DeleteSlide}
                    // UpdateSlide={UpdateSlide}
                    />

                </main>
                }
            </div>
            <MediaDetailModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // handleBlockChange={handleBlockChange}
                // index={index}
                // flag={flag}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                ListMediaItems={ListMediaItems}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                isEdit={isEdit}
                formData={formData}
                setFormData={setFormData}
            />
        </>
    )
}

export default MediaLibrary