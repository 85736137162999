import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition, Listbox } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Swal from 'sweetalert2'
import { AddAdminCategory, AdminListCategories, CategoryOrderList, CategorySetOrderList, LogoutUser, UpdateAdminCategory } from '../../service/service';
import { Tooltip } from "react-tooltip";
import { DropdownIcon } from '../../assets/icon-file'
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminUser } from '../../store/slices/AdminUserSlice';
import { useNavigate } from 'react-router-dom';
import DragableTable from '../../ReuseableComponents/DragableTable';
function CategoriesModal(
    {
        setIsOpen, setResponseData, formData, setFormData, isEdit,
        DropdownOptions,
        currentPage, itemsPerPage, setTotalPages,
        formErrors, setFormErrors
    }
) {
    const [loading, setloading] = useState(false);
    const [items, setItems] = useState([]);
    const [innerLoading, setInnerLoading] = useState(false);
    useEffect(() => {
        ListOrderTable()
    }, []);
    const ListOrderTable=()=>{
        if (isEdit) {
            setInnerLoading(true)
            CategoryOrderList({ id: formData.id }).then((result) => {
                const sortedData = result.list.sort((a, b) => parseInt(a.order_sequence) - parseInt(b.order_sequence));
                setItems(sortedData);
                setInnerLoading(false)
            }).catch((error) => {
                console.log(error)
                setInnerLoading(false)
            });
        }
    }
    const SubmitOrder = () => {
        setloading(true)
        const data = items.map((obj, index) => {
            return {
                parent_id: formData.id,
                child_id: obj.id,
                type: obj.type,
                order_sequence: index + 1
            }
        })
        CategorySetOrderList({ list: data }).then((result) => {
            setloading(false)
            if(result.status){
                Swal.fire({
                    icon: 'success',
                    title: result.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                ListOrderTable()
            }
            
        })
    }
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.adminUser.userData);
    const navigate = useNavigate();
    const handleChange = (event) => {

        const { name, value } = event.target;
        if (name === 'name') {
            setFormData((prevData) => ({
                ...prevData,
                slug: value.replace(/(?:\s*-\s*)|(\s+)/g, (match, group) => {
                    return group ? '-' : '';
                }).toLowerCase()
            }));
        }
        if (name === 'slug') {
            setFormData((prevData) => ({
                ...prevData,
                slug: value.replace(/\s/g, '-').toLowerCase()
            }));
        }
        else {

            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

    };
    const handleDropDown = (data) => {
        setFormData((prevData) => ({
            ...prevData,
            parent_category: data, // Update the corresponding field in your form data
        }));
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormErrors({
            name: '',
            slug: '',
            description: '',
            parent_category: '',
            permalink: ''
        });
        if (validateForm()) {

            try {
                setloading(true)
                let result = '';
                if (isEdit) {

                    const data = {
                        id: formData.id,
                        name: formData.name,
                        slug: formData.slug,
                        description: formData.description,
                        parent_catagory: formData.parent_category?.id === null ? [] : formData.parent_category?.id,
                        custom_permalink: formData.permalink
                    }
                    result = await UpdateAdminCategory(data);

                }
                else {
                    const data = {
                        name: formData.name,
                        slug: formData.slug,
                        description: formData.description,
                        parent_catagory: formData.parent_category?.id === null ? [] : formData.parent_category?.id,
                        custom_permalink: formData.permalink
                    }
                    result = await AddAdminCategory(data);

                }
                setIsOpen(false)
                if (result.status) {
                    setloading(false)
                    setFormData({
                        name: '',
                        slug: '',
                        description: '',
                        parent_category: {},
                        permalink: ''
                    });
                    setFormErrors({
                        name: '',
                        slug: '',
                        description: '',
                        parent_category: '',
                        permalink: ''
                    })
                    AdminListCategories(currentPage, itemsPerPage)
                        .then((result) => {
                            if (result.status) {
                                setResponseData(result.data.data)
                                const totalPages = Math.ceil(result.data.total / itemsPerPage);
                                setTotalPages(totalPages)
                            }
                            else {
                                Swal.fire({
                                    title: 'Error!',
                                    text: result.message,
                                    icon: 'error',
                                    timer: 3000,
                                    confirmButtonText: 'OK'
                                })
                            }

                        })

                    Swal.fire({
                        icon: 'success',
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1500
                    }
                    )
                }
                else {
                    setloading(false)
                    Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        timer: 3000,
                        confirmButtonText: 'OK'
                    })
                }
            }
            catch (error) {
                setIsOpen(false)
                console.log(error.message);
                setloading(false)
                Swal.fire({
                    title: 'Error!',
                    text: error.message,
                    icon: 'error',
                    timer: 3000,
                    confirmButtonText: 'OK'
                })
            }
        }
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formData.name) {
            isValid = false;
            errors.name = 'Name is required';
        }
        if (!formData.slug) {
            isValid = false;
            errors.slug = 'Slug is required';
        }
        if (Object.keys(formData.parent_category).length === 0) {
            isValid = false;
            errors.parent_category = 'Parent Category is required';
        }
        // if (!formData.permalink) {
        //     isValid = false;
        //     errors.permalink = 'Permalink is required';
        // }


        setFormErrors(errors);
        return isValid;
    };
    const handleSignOut = () => {
        const token = localStorage.getItem('adminToken');
        LogoutUser(token).then((result) => {

            localStorage.removeItem('adminToken');
            localStorage.removeItem('adminData');
            navigate("/admin");
            dispatch(logoutAdminUser())
        })
            .catch((error) => {
                console.log(error.message);

            });

    };
    function SignOutIcon(props) {
        return (

            <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>

        )
    }

    return (
        <>

            <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
                <div className='px-[22px] sm:px-8 bg-whiteGrey'>
                    <div className='flex justify-between items-center text-primaryDark sm:h-[72px] text-left'>
                        <div className='flex justify-center items-center gap-3 text-2xl leading-[21px] font-semibold'>
                            <div className='hidden sm:block'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M4 6.5H14.5M4 12.5H20M4 18.5H14.5" stroke="#161E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <span>
                                Classes
                            </span>

                        </div>
                        <div className='flex justify-between items-center gap-x-5'>

                            <div className="relative flex">
                                <Menu>
                                    {({ open }) => (
                                        <>
                                            <Menu.Button >
                                                <div className='flex gap-x-3 items-center justify-center'>
                                                    <div className='flex items-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        </svg>

                                                    </div>
                                                    <div className='text-base font-semibold leading-[21px] text-primaryDark'>
                                                        {userData?.name}
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M10.25 15.6365C10.1163 15.6365 9.98251 15.5854 9.88045 15.4833L4.65319 10.2561C4.44894 10.0518 4.44894 9.72109 4.65319 9.51696C4.85745 9.31284 5.1882 9.31271 5.39232 9.51696L10.25 14.3746L15.1077 9.51696C15.312 9.31271 15.6427 9.31271 15.8468 9.51696C16.051 9.72122 16.0511 10.052 15.8468 10.2561L10.6196 15.4833C10.5175 15.5854 10.3837 15.6365 10.25 15.6365Z" fill="#161E2E" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Menu.Button>

                                            {open && (
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="z-10 bg-white border w-56 border-gray-300 absolute right-0 top-8 mt-2 rounded-md shadow-lg focus:outline-none"
                                                    >
                                                        <div className="px-1 py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        className={`${active ? 'bg-primaryDark text-whiteGrey' : 'text-primaryDark'
                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-base font-semibold`}
                                                                        onClick={handleSignOut}
                                                                    >
                                                                        {active ? (
                                                                            <SignOutIcon
                                                                                className="mr-2 h-5 w-5 text-whiteGrey"
                                                                                aria-hidden="true"
                                                                            />
                                                                        ) : (
                                                                            <SignOutIcon
                                                                                className="mr-2 h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}
                                                                        Log Out
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            )}
                                        </>
                                    )}
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <hr className='bg-[#D2D6DC] h-[1px]' />
                    <div className='flex justify-between items-center text-primaryDark h-[76px] text-left sm:border-b-[1px]'>
                        <div
                            onClick={() => setIsOpen(false)}
                            className='flex justify-center items-center gap-2 cursor-pointer text-lg lg:text-xl leading-[21px] font-semibold'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z" fill="#161E2E" />
                                </svg>
                            </span>

                            <span>
                                Back to Categories
                            </span>
                        </div>
                        <div className='text-xl font-bold'>

                            {isEdit ? 'Edit' : 'Add New'} Category
                        </div>
                        <div>
                            {isEdit ?
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                >
                                    Update Category
                                </button>
                                :
                                <div className='flex gap-x-2'>

                                    <button
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={loading}
                                        className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                    >
                                        Publish Category
                                    </button>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <div className='pr-10 pl-[31px] py-8 flex min-h-screen gap-x-10'>
                    <div className='min-w-[385px] w-[35%] bg-whiteGrey rounded-lg px-5'>

                        <form className='py-6 space-y-4'>
                            <div className="flex w-full text-left text-base text-primaryDark font-bold ">
                                <span>Summary</span>


                            </div>
                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <div className='flex gap-x-1 items-center'>
                                    <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                        Name
                                    </label>
                                    <InformationCircleIcon
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="The name is how it appears on your site."
                                        className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="">
                                    <input
                                        id="full-name"
                                        name="name"
                                        type="text"
                                        autoComplete="name"
                                        required
                                        value={formData?.name}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formErrors?.name && <div className="text-red-500 px-2 !mt-1">{formErrors?.name}</div>}
                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <div className='flex gap-x-1 items-center'>
                                    <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                        Slug
                                    </label>
                                    <InformationCircleIcon
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens."
                                        className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="">
                                    <input
                                        id="slug"
                                        name="slug"
                                        type="text"
                                        autoComplete="off"
                                        required
                                        value={formData?.slug}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formErrors?.slug && <div className="text-red-500 px-2 !mt-1">{formErrors?.slug}</div>}
                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <div className='flex gap-x-1 items-center'>
                                    <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                        Parent Category
                                    </label>
                                    <InformationCircleIcon
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Categories, unlike tags, can have a hierarchy. You might have a Jazz category, and under that have children categories for Bebop and Big Band. Totally optional."
                                        className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="">
                                    <Listbox value={formData?.parent_category} onChange={handleDropDown} name='parent_category'>
                                        <div className="relative">
                                            <Listbox.Button className="relative w-full cursor-pointer h-[43px] bg-transparent py-2 pr-10 text-left focus:outline-none sm:text-lg sm:leading-normal font-semibold">
                                                <span className="block truncate">{formData?.parent_category?.name}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <DropdownIcon
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Listbox.Button>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {DropdownOptions?.map((roles, roleIdx) => (
                                                        <Listbox.Option
                                                            key={roleIdx}
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 px-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-gray-900'
                                                                }`
                                                            }
                                                            value={roles}
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    <span
                                                                        style={{ marginLeft: `${roles?.level * 20}px` }}
                                                                        className={`block whitespace-nowrap ${selected ? 'font-semibold' : 'font-semibold'
                                                                            }`}
                                                                    >
                                                                        {roles.name}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>
                            {formErrors?.parent_category && Object.keys(formData?.parent_category).length === 0 && <div className="text-red-500 px-2 !mt-1">{formErrors.parent_category}</div>}
                            <div className='h-[135px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <div className='flex gap-x-1 items-center'>
                                    <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                        Description
                                    </label>
                                    <InformationCircleIcon
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="The description is not prominent by default; however, some themes may show it."
                                        className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="">
                                    <textarea
                                        id="description"
                                        name="description"
                                        type="text"
                                        rows={3}
                                        autoComplete="off"
                                        // required
                                        value={formData?.description}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formErrors?.description && <div className="text-red-500 px-2 !mt-1">{formErrors?.description}</div>}
                            <div className='h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                <div className='flex gap-x-1 items-center'>
                                    <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                        Custom Permalink
                                    </label>
                                    <InformationCircleIcon
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="add an additional link to be used to see this category directly"
                                        className="h-[15px] w-[15px] text-primaryDark cursor-pointer"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="">
                                    <input
                                        id="permalink"
                                        name="permalink"
                                        type="text"
                                        autoComplete="off"
                                        // required
                                        value={formData?.permalink}
                                        onChange={handleChange}
                                        className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                    />
                                </div>
                            </div>
                            {formErrors?.permalink && <div className="text-red-500 px-2 !mt-1">{formErrors?.permalink}</div>}

                            <Tooltip
                                id="my-tooltip"
                                place="bottom-start"
                                style={{ backgroundColor: "#161E2E", color: "#F9F9F9", width: '231px' }}
                            />
                        </form>
                    </div>
                    <div className='flex-grow max-w-[65%] space-y-6'>
                    {
                       isEdit && items.length>0 &&
                       <button
                            type="button"
                            onClick={SubmitOrder}
                            disabled={loading}
                            className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                        >
                            Save Order
                        </button>
                        }
                        <DragableTable
                            items={items}
                            setItems={setItems}
                            loading={innerLoading}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}

export default CategoriesModal